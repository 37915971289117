import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import logo from "../../assets/cmatrix.png";
import logo1 from "../../assets/nav-matrix-logo.png";
import HomeIcon from "@mui/icons-material/Home";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";
import PersonIcon from "@mui/icons-material/Person";
import CampaignIcon from "@mui/icons-material/Campaign";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import navstart from "../../assets/nav-end-matrix.png";
import {Link,NavLink} from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import FeedIcon from '@mui/icons-material/Feed';
import LockIcon from '@mui/icons-material/Lock';
import HandshakeIcon from '@mui/icons-material/Handshake';
import PublishIcon from '@mui/icons-material/Publish';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import BadgeIcon from '@mui/icons-material/Badge';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSelector } from "react-redux";

function VerticalNav() {
  const user = useSelector((state) => state.clickmatrix.user)
  return (
    <div style={{marginTop:"5%", height:"95%"}}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",

          marginLeft: "15px",
          alignItems: "center",
          borderRadius: "13px",
          border:"0.5px solid #C5C5C5",
          backgroundColor:"white",
          height:"100%",
          paddingTop:"4%",
          
          
          width: "95%",
          // minWidth:"260px",
          maxWidth: "300px",
        }}
      >
        <div style={{ marginBottom: "18%" }}>
          <img
            src={logo1}
            alt="clickmatrix"
            style={{ width: "70%", marginLeft: "10%" }}
          />
        </div>
        <div style={{ display:"flex",flexDirection:"column",justifyContent:"space-between", width:"200px",height:"55%"}}>
          <div>
            
            <Button
              variant="text"
              component={NavLink}
              to="/"
              sx={{
                color: "black",
                width: "100%",
                borderRadius:"5px",
                
                textDecoration:"none",
                "&.active":{
                 
                  
                  backgroundColor:"#422FCD",
                  "& .dashboard": { color: "white" },
                  color:"white"
                 
                },
                "&:hover":{
                  backgroundColor:"#422FCD",
                  color:"white",
                  "& .dashboard": { color: "white" },
                },
               
                fontSize: "16px",
                
                textTransform: "none",
              }}
              size="large"
              style={{ justifyContent: "flex-start" }}
              startIcon={
                <DashboardIcon className="dashboard" sx={{ color: "#616161" }} />
              }
            >
              <Typography sx={{marginLeft:"8px",fontWeight:500,fontSize:"17px"}}>Dashboard</Typography>
            </Button>
            {/* {lineState==1 && 
            <div style={{borderBottom:"2px solid #0D0992",width:"150px",marginBottom: "8%",marginTop:"3px"}}>
              
            </div>
} */}
           
          </div>

          <div>
            
            <Button
              variant="text"
              component={NavLink}
              to="/campaigns"
              sx={{
                color: "#A6A5A5",
                width: "100%",
                "&.active":{
                  borderRadius:"5px",
                  backgroundColor:"#422FCD",
                  "& .campaign": { color: "white" },
                  color:"white"
                 
                },
                "&:hover":{
                  borderRadius:"5px",
                  backgroundColor:"#422FCD",
                  "& .campaign": { color: "white" },
                  color:"white"
                 
                },
               
                fontSize: "16px",
                
                textTransform: "none",
              }}
              size="large"
              style={{ justifyContent: "flex-start" }}
              startIcon={
                <CampaignIcon
                  className="campaign"
                  sx={{ color: "#616161" }}
                />
              }
            >
              <Typography sx={{marginLeft:"8px",fontWeight:500,fontSize:"17px"}}>

              Campaigns
              </Typography>
            </Button>
            {/* {lineState==2 && 
            <div style={{borderBottom:"2px solid #0D0992",width:"150px",marginBottom: "8%",marginTop:"3px"}}>
              
            </div>
} */}
           
          </div>

          <div>
            
            <Button
              variant="text"
              component={NavLink}
              to="/feeds"
              sx={{
                color: "#A6A5A5",
                width: "100%",
                "&.active":{
                  borderRadius:"5px",
                  backgroundColor:"#422FCD",
                  "& .feed": { color: "white" },
                  color:"white"
                 
                },
                "&:hover":{
                  borderRadius:"5px",
                  backgroundColor:"#422FCD",
                  "& .feed": { color: "white" },
                  color:"white"
                 
                },
               
                fontSize: "16px",
               
                textTransform: "none",
              }}
              size="large"
              style={{ justifyContent: "flex-start" }}
              startIcon={
                <FeedIcon className="feed" sx={{ color: "#616161" }} />
              }
            >
              <Typography sx={{marginLeft:"8px",fontWeight:500,fontSize:"17px"}}>

              Feeds
              </Typography>
            </Button>

          </div>

       

          <div>
           
            <Button
              variant="text"
              component={NavLink}
              to="/tagmap"
              sx={{
                color: "#A6A5A5",
                width: "100%",
                "&.active":{
                  borderRadius:"5px",
                  backgroundColor:"#422FCD",
                  "& .xml": { color: "white" },
                  color:"white"
                 
                },
                "&:hover":{
                  borderRadius:"5px",
                  backgroundColor:"#422FCD",
                  "& .xml": { color: "white" },
                  color:"white"
                 
                },
                
                fontSize: "16px",
                
                textTransform: "none",
              }}
              size="large"
              style={{ justifyContent: "flex-start" }}
              startIcon={
                <SettingsInputComponentIcon
                  className="xml"
                  sx={{ color: "#616161" }}
                />
              }
            >
              <Typography sx={{marginLeft:"8px",fontWeight:500,fontSize:"17px"}}>

              XML Config
              </Typography>
            </Button>

            
          </div>

          <div>
            
            <Button
              variant="text"
              component={NavLink}
              to="/credentials"
              sx={{
                color: "#A6A5A5",
                width: "100%",
                "&.active":{
                  borderRadius:"5px",
                  backgroundColor:"#422FCD",
                  "& .credential": { color: "white" },
                  color:"white"
                 
                },
                "&:hover":{
                  borderRadius:"5px",
                  backgroundColor:"#422FCD",
                  "& .credential": { color: "white" },
                  color:"white"
                 
                },
               
                fontSize: "16px",
               
                textTransform: "none",
              }}
              size="large"
              style={{ justifyContent: "flex-start" }}
              startIcon={
                <LockIcon
                  className="credential"
                  sx={{ color: "#616161" }}
                />
              }
            >
              <Typography sx={{marginLeft:"8px",fontWeight:500,fontSize:"17px"}}>

              Credentials
              </Typography>
            </Button>

          </div>

          <div>
            
            <Button
              variant="text"
              component={NavLink}
              to="/ftp_config"
              sx={{
                color: "#A6A5A5",
                width: "100%",
                "&.active":{
                  borderRadius:"5px",
                  backgroundColor:"#422FCD",
                  "& .ftp": { color: "white" },
                  color:"white"
                 
                },
                "&:hover":{
                  borderRadius:"5px",
                  backgroundColor:"#422FCD",
                  "& .ftp": { color: "white" },
                  color:"white"
                 
                },
                
                fontSize: "16px",
               
                textTransform: "none",
              }}
              size="large"
              style={{ justifyContent: "flex-start" }}
              startIcon={
                <LockIcon
                  className="credential"
                  sx={{ color: "#616161" }}
                />
              }
            >
              <Typography sx={{marginLeft:"8px",fontWeight:500,fontSize:"17px"}}>

              Ftp Config
              </Typography>
            </Button>

          </div>

          <div>
            
            <Button
              variant="text"
              component={NavLink}
              to="/partners"
              sx={{
                color: "#A6A5A5",
                width: "100%",
                "&.active":{
                  borderRadius:"5px",
                  backgroundColor:"#422FCD",
                  "& .partner": { color: "white" },
                  color:"white"
                 
                },
                "&:hover":{
                  borderRadius:"5px",
                  backgroundColor:"#422FCD",
                  "& .partner": { color: "white" },
                  color:"white"
                 
                },
                
                fontSize: "16px",
                
                textTransform: "none",
              }}
              size="large"
              style={{ justifyContent: "flex-start" }}
              startIcon={
                <HandshakeIcon
                  className="partner"
                  sx={{ color: "#616161" }}
                />
              }
            >
              <Typography sx={{marginLeft:"8px",fontWeight:500,fontSize:"17px"}}>

              Partners
              </Typography>
            </Button>
      
          </div>

          <div>
            
            <Button
             variant="text"
             component={NavLink}
             to="/publisher"
             sx={{
               color: "#A6A5A5",
               width: "100%",
               "&.active":{
                borderRadius:"5px",
                backgroundColor:"#422FCD",
                "& .publisher": { color: "white" },
                color:"white"
               
              },
              "&:hover":{
                borderRadius:"5px",
                backgroundColor:"#422FCD",
                "& .publisher": { color: "white" },
                color:"white"
               
              },
               
                fontSize: "16px",
                
                textTransform: "none",
              }}
              size="large"
              style={{ justifyContent: "flex-start" }}
              startIcon={
                <PublishIcon
                  className="publisher"
                  sx={{ color: "#616161" }}
                />
              }
            >
              <Typography sx={{marginLeft:"8px",fontWeight:500,fontSize:"17px"}}>

              Publisher
              </Typography>
            </Button>
 
          </div>
            {user[0].status == 'admin' ? 
          <div>
            
            <Button
              variant="text"
              component={NavLink}
              to="/users"
              sx={{
                color: "#A6A5A5",
                width: "100%",
                textDecoration:"none",
                
                "&.active":{
                  borderRadius:"5px",
                  backgroundColor:"#422FCD",
                  "& .users": { color: "white" },
                  color:"white"
                 
                },
                "&:hover":{
                  borderRadius:"5px",
                  backgroundColor:"#422FCD",
                  "& .users": { color: "white" },
                  color:"white"
                 
                },
                
                fontSize: "16px",
                
                textTransform: "none",
              }}
              size="large"
              style={{ justifyContent: "flex-start" }}
              startIcon={
                <BadgeIcon
                  className="users"
                  sx={{ color: "#616161" }}
                />
              }
            >
              <Typography sx={{marginLeft:"8px",fontWeight:500,fontSize:"17px"}}>Users</Typography>
            </Button>
    
           
          </div>
: <></>}

          <div>
            
            <Button
              variant="text"
              component={NavLink}
              to="/companies"
              sx={{
                color: "#A6A5A5",
                width: "100%",
                
                "&.active":{
                  borderRadius:"5px",
                  backgroundColor:"#422FCD",
                  "& .company": { color: "white" },
                  color:"white"
                 
                },
                "&:hover":{
                  borderRadius:"5px",
                  backgroundColor:"#422FCD",
                  "& .company": { color: "white" },
                  color:"white"
                 
                },
               
                fontSize: "16px",
                
                textTransform: "none",
              }}
              size="large"
              style={{ justifyContent: "flex-start" }}
              startIcon={
                <ApartmentIcon
                  className="company"
                  sx={{ color: "#616161" }}
                />
              }
            >
              <Typography sx={{marginLeft:"8px",fontWeight:500,fontSize:"17px"}}>Companies</Typography>
            </Button>
            
          
          </div>
        </div>

        <div style={{ marginTop: "auto" }}>
          <img
            src={navstart}
            alt="nav-icon"
            style={{ width: "100%", height: "100%",borderRadius: "13px", }}
          />
        </div>
      </Box>
    </div>
  );
}

export default VerticalNav;
