import backgroundhome from "../../assets/background-home.png";
import starsbackground from "../../assets/stars-background.png";
import astronautea from "../../assets/astronaut_tea.png";
import bluestars from "../../assets/blue-stars.jpg";
import Button from "@mui/material/Button";
import React from "react";
import { Link } from "react-router-dom";

function HomeRobot() {
  return (
    <div style={{
      backgroundImage: `url(${bluestars})`,
      height: "100vh",
      backgroundSize: "cover",
    }}>
      {/* <img
        src={bluestars}
        style={{ width: "100%", height: "100%", position: "absolute" }}
      /> */}

      <div
        style={{
          display: "flex",
          width: "100%",
          position: "relative",
          paddingTop: "15%",
        }}
      >
        <div style={{ width: "30%", marginLeft: "130px", marginTop: "5%" }}>
          <div style={{ width: "100%" }}>
            <img src={astronautea} className="animate__animated animate__pulse animate__infinite animate__slow" />
          </div>
          <div style={{ marginLeft: "35px", marginTop: "20px" }}>
            <Button
              component={Link}
              variant="contained"
              to="/login"
              style={{
                borderRadius: "5px",
                backgroundColor: "#0D0992",
                width: "210px",
                fontWeight: 600,
                fontSize: "24px",
                color:"white",
                borderTop: "1px solid white",
              }}
            >
              Join Us
            </Button>
          </div>
        </div>
        <div style={{ width: "70%", marginTop: "20px" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <p
              style={{
                margin: "0px",
                fontWeight: 700,
                fontSize: "80px",
                background:
                  "radial-gradient(50% 50% at 50% 50%, #FF83EB 22.09%, #5E59FD 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Track Your success
            </p>
            <p
              style={{
                color: "#FFFFFF",
                margin: "0px",
                paddingLeft: "30px",
                paddingTop: "10px",
                fontWeight: 400,
                fontSize: "40px",
                width: "50%",
              }}
            >
              Our Service Based model enables all sort of organizations to use
              our quality services.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeRobot;
