import React, { useState, useEffect, useRef } from "react";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import AddIcon from "@mui/icons-material/Add";
import countryadd from "../../assets/country-add.png";
import Button from "@mui/material/Button";
import VisibilityIcon from '@mui/icons-material/Visibility';
import TextField from "@mui/material/TextField";
import NewTextField from "../NewTextField";
import CustomTextField from "../CustomTextField";

import Modal from "@mui/material/Modal";

import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import TableSortLabel from "@mui/material/TableSortLabel";

import Box from "@mui/material/Box";
// import { deleteData } from "../helpers/helpers";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import { makeStyles } from "@mui/styles";
import { userAddThunk,employeeAddThunk, employeeDeleteThunk, employeeEditThunk, employeeSearchUpdate, employeeThunk } from "../../reducers/request_api";
import { useDispatch,useSelector} from "react-redux";
import { connect } from "react-redux";

import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

const useStyles = makeStyles({
  root: {
    "& label": {
      width: "100%",
      // paddingTop:"-50px",

      "&.Mui-focused": {
        transform: "translate(-6px, -12px) scale(0.8)",
        transformOrigin: "center",
        fontSize: "15px !important",
        color: "#0D0992",
      },
    },
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        borderColor: "none",
        borderRadius: "10px",
        boxShadow: "rgba(0, 0, 0, 0.06) 0px 5px 4px 0px",
        height: "40px",
      },
    },
    // '&.shrink': {
    //   'transform': 'translate(15px, 0px) scale(1.05)',
    // },
  },
});

function EmployeeIndex({}) {


  const employee =  useSelector((state) => state.clickrequests.employee)
  const employeeSearchValue = useSelector((state) => state.clickrequests.employeeSearch)
  const user = useSelector((state) => state.clickmatrix.user)

  let publisherRef = useRef();

  const [country, setCountry] = useState("");
  const [employeeContainer, setEmployeeContainer] = useState([]);
  const [show, setShow] = useState(false);
  const [cncode, setCn] = useState("");
  const [privilege, setPrivilege] = useState("");
  const [useremail, setUseremail] = useState({interact:false,value:"",error:""});
  const [userpassword, setUserpassword] = useState({interact:false,value:"",error:""});
  const [username, setUsername] = useState({interact:false,value:"",error:""});
  
  const [publisherId, setPublisherId] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [errorHelper, setErrorHelper] = useState("");
  const [passwordError,setPasswordError] = useState("");
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("user_name");
  const [employeeBackupContainer,setEmployeeBackupContainer] = useState([]);
  const [searched, setSearched] = useState("");

  const [reduxloaded,setReduxloaded] = useState(false);
  // const [loader,setLoader] = useState(true);

  //Error handling states
  const [publisherError, setPublisherError] = useState("");
  const [publisherEmailError, setPublisherEmailError] = useState("");
  const [publisherPasswordError, setPublisherPasswordError] = useState("");
  const [privilegeError, setPrivilegeError] = useState("");
  const [publisherRepasswordError, setPublisherRepasswordError] = useState("");
  const [compareError, setCompareError] = useState("");
  

  const classes = useStyles();
  const dispatch = useDispatch();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  var loader =  useSelector((state) => state.clickrequests.isLoading)
  
  useEffect(() => {
    
    if(reduxloaded)
      {
        requestSearch(employeeSearchValue);
        setSearched(employeeSearchValue);
      }
      else{
    // getDataTwo(setEmployeeContainer,setEmployeeBackupContainer, "publishers");
    if(employee.length == 0){

    
    dispatch(employeeThunk()).then((response) => {
      setEmployeeContainer((arr) => (arr = [...response.payload]));
      setEmployeeBackupContainer((arr) => (arr = [...response.payload]));
      // setLoader(false)
    })
  }
  else{
    setEmployeeContainer((arr) => (arr = [...employee]));
      setEmployeeBackupContainer((arr) => (arr = [...employee]));
      // setLoader(false);
      setReduxloaded(true);
  }
}

  
    

    // setEmployeeContainer((arr) => arr = [...abu]);
  }, [show, refresh,reduxloaded]);

  const requestSearch = (searchedVal) => {
    if (searchedVal == "") {
      setEmployeeContainer(employeeBackupContainer);
      dispatch(employeeSearchUpdate(""))
    } else {
      const filteredRows = employeeBackupContainer.filter((row) => {
        return row.user_name.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setEmployeeContainer(filteredRows);
      setPage(0);
      dispatch(employeeSearchUpdate(searchedVal))
    }
  };

  const cancelSearch = () => {
    setEmployeeContainer(employeeBackupContainer);
    setSearched("");
    dispatch(employeeSearchUpdate(""))
    // requestSearch(searched);
  };


  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");

    setOrderBy(property);
  };

  // function descendingComparator(obj1, obj2, orderBy) {
   
  //   return obj1[orderBy].localeCompare(obj2[orderBy]);
  // }

  // function getComparator(order, orderBy, obj1, obj2) {
  //   return order === "desc"
  //     ? descendingComparator(obj1, obj2, orderBy)
  //     : -descendingComparator(obj1, obj2, orderBy);
  // }

  // function stableSort(array, orderIs, orderBy) {
  //   const stabilizedThis = array.map((el, index) => {
      
  //     return [el, index];
  //   });
  //   stabilizedThis.sort((a, b) => {
      
  //     const order = getComparator(orderIs, orderBy, a[0], b[0]);
  //     if (order !== 0) {
  //       return order;
  //     }
  //     return a[1] - b[1];
  //   });
  //   return stabilizedThis.map((el) => el[0]);
  // }

  function stableSort(array, order, orderBy) {

    function isString(property){
      return typeof property === 'string';
    }

    function getOrderValue(obj,orderBy){
      if(orderBy == 'total_clicks' || orderBy == 'valid_clicks'){
        return(obj.CampaignStats?.[0]?.[orderBy] || 0)
      }
      if(isString(obj[orderBy])){
        return obj[orderBy].toLowerCase();
      }
      return obj[orderBy] || 0;

    }

    return array.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort((a,b) => {
      const descending = order === 'desc' ? -1 : 1;
      const value1 = getOrderValue(a,orderBy);
      const value2 = getOrderValue(b,orderBy);
      
      if(isString(a[orderBy]) && isString(b[orderBy])){
        return descending * value1.localeCompare(value2);
      }

      return descending * (value2 - value1)
    })
}

  return (
    <>
    {loader ? <>
      <Stack spacing={2} style={{marginTop:"70px",marginLeft:"20px"}}>
        {/* For variant="text", adjust the height via font-size */}
        <div
          style={{
            display: "flex",
            width: "62%",
            justifyContent: "space-between"
          }}
        >
          <Skeleton variant="rounded" width={130} height={40} />
          <Skeleton variant="rounded" width={200} height={40} />
        </div>
        <Skeleton variant="rounded" width={1150} height={400} />
      </Stack>
      
    </> :
    <>
      <Dialog
        open={deleteDialog}
        onClose={() => {
          setDeleteDialog(false);
          setDeleteItem({});
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to delete <strong>{deleteItem.filename}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            variant="outlined"
            sx={{
              border: "1px solid #0D0992",
              color: "#0D0992",
              borderRadius: "7px",
              fontSize: "13px",
            }}
            onClick={() => setDeleteDialog(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0D0992",
              borderRadius: "7px",
              fontSize: "13px",
            }}
            onClick={() => {
      //         deleteData("employees", deleteItem.id).then(() =>
              
      //         dispatch(employeeThunk()).then((response) => {
      //           setEmployeeContainer(response.payload);
      // setEmployeeBackupContainer(response.payload);
                
      //           setDeleteDialog(false);

      //           })

      //         );
              dispatch(employeeDeleteThunk(deleteItem.id)).then(() => {
                dispatch(employeeThunk()).then((response) => {
                  setEmployeeContainer(response.payload);
        setEmployeeBackupContainer(response.payload);
                  
                  setDeleteDialog(false);
  
                  })
              })
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            height: 400,
            bgcolor: "background.paper",
            border: "2px solid white",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: 600,
              fontSize: "18px",
              color: "#0D0992",
            }}
          >
            Employees
          </div>

          <div style={{ marginTop: "30px" }}>
            {/* <CustomTextField
              labelName={"Privilege"}
              setFunction={setPublisherName}
              setErrorFunction={setPublisherError}
              onChange={true}
              refs={publisherRef}
            /> */}
            <FormControl size="small">
              <InputLabel id="demo-simple-select">Select Privilege</InputLabel>
              <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      MenuProps={{ classes: { paper: classes.menuPaper } }}
                      
                      value={privilege}
                      sx={{
                        "& fieldset": {
                          borderColor: "#C5C5C5",
                          borderWidth: "0.5px",
                        },
                        width: "250px",
                        borderRadius: "10px",
                        backgroundColor: "white",
                      }}
                      label="FTP Upload Area"
                      
                      onChange={(e) => {
                        setPrivilege(e.target.value);
                        if (e.target.value == "") {
                          setPrivilegeError("Select Field");
                        } else {
                          setPrivilegeError("");
                        }
                      }}
                    >
                     
                        <MenuItem value={"admin"} key={"admin"}>
                          Admin
                        </MenuItem>
                        <MenuItem value={"view"} key={"view"}>
                          View Only
                        </MenuItem>
                      
                    </Select>
                    </FormControl>
            <FormHelperText sx={{ color: "red", margin: 0 }}>
              {privilegeError}
            </FormHelperText>
          </div>
          <div style={{ marginTop: "30px" }}>
          <NewTextField 
          name="Email"
          entity={useremail}
          setEntity={setUseremail}
          />
           
          </div>
          <div style={{ marginTop: "10px" }}>
          <NewTextField 
          name="Password"
          entity={userpassword}
          setEntity={setUserpassword}
          />
          </div>
          <div style={{ marginTop: "10px" }}>
          <NewTextField 
          name="Name"
          entity={username}
          setEntity={setUsername}
          />
          </div>
         
          

          <Button
            variant="contained"
            onClick={() => {
              let decision = true;
              if (useremail.value == "") {
                setUseremail((prevState) => ({
                  ...prevState,
                  error:"Empty Field"
              }))
                decision = false;
              }
              if (userpassword.value == "") {
                setUserpassword((prevState) => ({
                  ...prevState,
                  error:"Empty Field"
              }))
                decision = false;
              }
              
              if (privilege == "") {
                setPrivilegeError("Privilege not selected");
                decision = false;
              }
             

              if (decision) {
                // addData(
                //   {
                //     name: publisherName,
                //     password: publisherPassword,
                //     email: publisherEmail,
                //     status: "employee",
                //     is_active: 1,
                //   },
                //   "employees"
                // ).then(() => {
                //   dispatch(employeeThunk()).then((response) => {
                //     setEmployeeContainer(response.payload);
                //     setEmployeeBackupContainer(response.payload);
                    
                //     setShow(false);
                //   })
                // });

                // dispatch(employeeAddThunk({
                //   name: publisherName,
                //   password: publisherPassword,
                //   email: publisherEmail,
                //   status: "employee",
                //   is_active: 1,
                // })).then((res) => {
                //   dispatch(employeeThunk()).then((response) => {
                //     setEmployeeContainer(response.payload);
                //     setEmployeeBackupContainer(response.payload);
                    
                //     setShow(false);
                //   })
                // })
              }
            }}
            sx={{
              backgroundColor: "#0D0992",
              marginTop: "30px",
              width: "100px",
              borderRadius: "7px",
            }}
          >
            Save
          </Button>
        </Box>
      </Modal>

      {/* <Modal
        open={editModal}
        onClose={() => setEditModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            height: 350,
            bgcolor: "background.paper",
            border: "2px solid white",
            boxShadow: 24,

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontWeight: 600,
              fontSize: "18px",
              color: "#0D0992",
            }}
          >
            Edit Employee
          </div>

          <div style={{ marginTop: "50px" }}>
            <TextField
              onChange={(e) => {
                setPublisherName(e.target.value);
                if (e.target.value == "") {
                  setErrorHelper("Publisher Name Is Empty");
                } else {
                  setErrorHelper("");
                }
              }}
              id="outlined-basic"
              className={classes.root}
              value={publisherName}
              InputLabelProps={{
                style: {
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "100%",
                  fontSize: "13px",
                  top: "-4px",
                },
              }}
              label={"Employee Name"}
              variant="outlined"
              sx={{
                "& .MuiInputLabel-root": {
                  color: "rgba(0, 0, 0, 0.5);",
                }, //styles the label

                "& .MuiOutlinedInput-root.Mui-focused": {
                  "& > fieldset": {
                    borderColor: "#0D0992",
                  },
                },
                "& input": {
                  paddingLeft: "10px",
                },
                width: "250px",
              }}
            />
            <FormHelperText sx={{ color: "red", margin: 0 }}>
              {errorHelper}
            </FormHelperText>
          </div>

          <div style={{ marginTop: "10px" }}>
            <TextField
              onChange={(e) => {
                setPublisherPassword(e.target.value);
                if (e.target.value == "") {
                  setPasswordError("Publisher Password Is Empty");
                } else {
                  setPasswordError("");
                }
              }}
              id="outlined-basic"
              className={classes.root}
              value={publisherPassword}
              InputLabelProps={{
                style: {
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "100%",
                  fontSize: "13px",
                  top: "-4px",
                },
              }}
              label={"Password"}
              variant="outlined"
              sx={{
                "& .MuiInputLabel-root": {
                  color: "rgba(0, 0, 0, 0.5);",
                }, //styles the label

                "& .MuiOutlinedInput-root.Mui-focused": {
                  "& > fieldset": {
                    borderColor: "#0D0992",
                  },
                },
                "& input": {
                  paddingLeft: "10px",
                },
                width: "250px",
              }}
            />
            <FormHelperText sx={{ color: "red", margin: 0 }}>
              {passwordError}
            </FormHelperText>
          </div>

          <Button
            variant="contained"
            onClick={() => {
              let decision = true;
              if (publisherName == "") {
                setErrorHelper("Publisher Name Is Empty");
                decision = false;
              }
              if (decision) {
                // editData(
                //   { name: publisherName,password:publisherPassword },
                //   "employees",
                //   publisherId
                // ).then(() => {
                //   dispatch(employeeThunk()).then((response) => {
                //     setEmployeeContainer(response.payload);
                //     setEmployeeBackupContainer(response.payload);
                    
                //     setEditModal(false);
                //   })
                // });
                dispatch(employeeEditThunk([{name: publisherName,password:publisherPassword },publisherId])).then(() => {
                  dispatch(employeeThunk()).then((response) => {
                    setEmployeeContainer(response.payload);
                    setEmployeeBackupContainer(response.payload);
                    
                    setEditModal(false);
                  })
                })
              }
            }}
            sx={{
              backgroundColor: "#0D0992",
              marginTop: "30px",
              width: "100px",
              borderRadius: "7px",
            }}
          >
            Update
          </Button>
        </Box>
      </Modal> */}
<div style={{display:"flex",marginTop:"4%",justifyContent:"space-between"}}>
      <div
        style={{
          fontWeight: 600,
          fontSize: "20px",
          color: "#0D0992",
          marginLeft:"1.7%"
          
        }}
      >
        {(user[0].status == "employee") ? 
            <></>:
        <div>
          <Button
            variant="contained"
            onClick={() => {
              setShow(true);
              setErrorHelper("");
              const timeout = setTimeout(() => {
                publisherRef.current.focus();
              }, 100);
            }}
            sx={{
              backgroundColor: "#0D0992",
              borderRadius: "8px",
              fontWeight:600,
              width: "169px",
              minWidth:"160px",
              height: "37.5px",
            }}
          >
            Add Employee
          </Button>
          </div>
}
      </div>
      <div style={{ paddingRight:"30%",display:"flex"}}>
      <div>
                <TextField
              id="outlined-basic"
              size="small"
              value={searched}
              inputProps={{
                color: "rgba(0, 0, 0, 0.55)",
              }}
              label="Search"
              sx={{
                width: "230px",
               
                ".MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  "&.Mui-focused fieldset": {
                    borderColor: "#C5C5C5",
                    borderWidth: "0.5px",
                    color: "rgba(0, 0, 0, 0.55)",
                  },
                },
                "& label.Mui-focused": {
                  color: "rgba(0, 0, 0, 0.55)",
                },
                backgroundColor: "white",
                color: "rgba(0, 0, 0, 0.55)",
              }}
              variant="outlined"
              onChange={(e) => {
                requestSearch(e.target.value);
                setSearched(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searched != "" && (
                      <IconButton onClick={() => cancelSearch()}>
                        <CloseIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
                </div>
                
        </div>
        </div>
      <div style={{}}>
        
        <div style={{}}>
          <div
            style={{
              width: "70%",

              marginLeft: "10px",
              borderRadius: "15px",
              marginBottom: "100px",

              
            }}
          >
           

            <TableContainer
              component={Paper}
              sx={{
                width: "100%",
                marginLeft: "10px",
                backgroundColor: "#FFFFFF",
                borderRadius: "13px",
                backgroundColor: "#FFFFFF",
                    border:"1px solid rgba(0, 0, 0, 0.15)",
                marginTop: "20px",
              }}
            >
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{ color: "grey", paddingLeft: "60px" }}
                    >
                      <TableSortLabel
                        active={orderBy === "user_name"}
                        direction={orderBy === "user_name" ? order : "asc"}
                        onClick={() => handleRequestSort("user_name")}
                      ><strong>Name</strong></TableSortLabel>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ color: "grey", paddingLeft: "60px" }}
                    >
                      <TableSortLabel
                        active={orderBy === "email"}
                        direction={orderBy === "email" ? order : "asc"}
                        onClick={() => handleRequestSort("email")}
                      ><strong>Email</strong></TableSortLabel>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ color: "grey", paddingLeft: "60px" }}
                    >
                      <TableSortLabel
                        active={orderBy === "password"}
                        direction={orderBy === "password" ? order : "asc"}
                        onClick={() => handleRequestSort("password")}
                      ><strong>Password</strong></TableSortLabel>
                    </TableCell>

                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stableSort(employeeContainer, order, orderBy).map((value, index) => (
                    <React.Fragment key={index}>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          align="left"
                          sx={{ width: "20%", paddingLeft: "60px",paddingTop:"20px",paddingBottom:"20px" }}
                        >
                          {value.user_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ width: "20%", paddingLeft: "60px" }}
                        >
                          {value.email}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ width: "40%", paddingLeft: "60px" }}
                        >
                          {value.password}
                        </TableCell>
                        {/* <TableCell
                      align="left"
                      sx={{ width: "10%", paddingLeft: "60px" }}
                    >
                      0.00
                    </TableCell> */}
                        <TableCell align="left" sx={{ width: "5%" }}>
                        {(user[0].status == "employee") ? 
            <></>:
                          <Box sx={{display:"flex",justifyContent:"space-between"}}>

                          {/* <IconButton
                            onClick={() => {
                              setPublisherId(value.id);
                              setPublisherName(value.user_name);
                              setPublisherPassword(value.password);
                              setErrorHelper("");
                              setEditModal(true);
                            }}
                          >
                            <EditIcon />
                          </IconButton> */}

                          <IconButton
                            aria-label="expand-row"
                            size="small"
                            sx={{}}
                            onClick={() => {
                              
                              setDeleteItem({
                                id: value.id,
                                filename: value.user_name,
                              });
                              setDeleteDialog(true);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                          </Box>
}
                          
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
                <TableFooter>
                      <TableRow>
                        {employeeBackupContainer.length > 10 && 
                      <TablePagination
      
      count={employeeContainer.length}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[5,10,20,30]}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  }
    </TableRow>
    </TableFooter>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      </>
}
  </>
  
  );
}



export default EmployeeIndex;
