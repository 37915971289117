

import TableCell from "@mui/material/TableCell";

import TableRow from "@mui/material/TableRow";

import BorderColorIcon from "@mui/icons-material/BorderColor";
import React, { useState } from "react";
import { IconButton } from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import LinearProgress from "@mui/material/LinearProgress";

function TableRowData({name,spend}) {
    const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow
        key="2"
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell
          component="th"
          scope="row"
          align="left"
          sx={{
            
            paddingTop: "30px",
            paddingBottom: "30px",
            paddingLeft: "40px",
          }}
        >
          <strong>{name}</strong>
        </TableCell>
        <TableCell align="left" sx={{paddingLeft: "60px" }}>
          <strong>${spend}</strong> of 1000$
          <LinearProgress
            variant="determinate"
            value={spend/10}
            sx={{
              marginTop:"3px",
                      width: "60%",
                      borderRadius:"15px",
                      height:"5px",
                     
                      '& .MuiLinearProgress-bar1Determinate': {
                        backgroundColor: '#0D0992',
                    }
            }}
          />
        </TableCell>
        <TableCell align="left" sx={{ paddingLeft: "60px" }}>
          850
        </TableCell>
        <TableCell align="left" sx={{ paddingLeft: "60px" }}>
          0.00
        </TableCell>
        <TableCell align="left" sx={{ paddingLeft: "60px" }}>
          0.00
        </TableCell>
        <TableCell align="left" sx={{  }}>
          {/* <Box sx={{display:"flex",justifyContent:"space-between"}}> */}

          <IconButton aria-label="expand-row" size="small">
            <BorderColorIcon size="small" />
          </IconButton>
          <IconButton
            aria-label="expand-row"
            size="small"
            sx={{ marginLeft: "15%" }}
          >
            <AssessmentIcon size="small" />
          </IconButton>
          <IconButton
            aria-label="expand-row"
            size="small"
            sx={{ marginLeft: "15%" }}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
          {/* </Box> */}
        </TableCell>
      </TableRow>

    
    </>
  );
}

export default TableRowData;
