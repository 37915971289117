   
  export const handleRequestSort = (property,order,setOrder,orderBy,setOrderBy) => {
    
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");

    setOrderBy(property);
  };

  export const rowSort = (array, order, orderBy,rowsPerPage,page) => {

    function isString(property){
      return typeof property === 'string';
    }

    function getOrderValue(obj,orderBy){
      if(orderBy == 'total_clicks' || orderBy == 'valid_clicks'){
        return(obj.CampaignStats?.[0]?.[orderBy] || 0)
      }
      if(isString(obj[orderBy])){
        return obj[orderBy].toLowerCase();
      }
      return obj[orderBy] || 0;

    }

    return array.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort((a,b) => {
      const descending = order === 'desc' ? -1 : 1;
      const value1 = getOrderValue(a,orderBy);
      const value2 = getOrderValue(b,orderBy);
      
      if(isString(a[orderBy]) && isString(b[orderBy])){
        return descending * value1.localeCompare(value2);
      }

      return descending * (value2 - value1)
    })
}