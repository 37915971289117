import { configureStore,getDefaultMiddleware } from '@reduxjs/toolkit';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import tokenReducer from './reducers/auth';
import requestReducer from "./reducers/request_api";

// const initialState = {
// }

// const middleware = [thunk];

const store = configureStore({
    reducer:{
        clickmatrix:tokenReducer,
        clickrequests:requestReducer,
    },
    devTools:true,

    //Workaround to solve non serializeable data error
    middleware:getDefaultMiddleware({
        serializableCheck:false
    })

})

export default store