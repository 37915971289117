import React, { useState, useEffect } from "react";
import Input from "@mui/material/Input";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import AddIcon from "@mui/icons-material/Add";
// import {  deleteData } from "../helpers/helpers";
import TimePicker from "react-time-picker";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { FormHelperText } from "@mui/material";
import "./filter.css";
import CustomTextField from "../CustomTextField";
import AutoComplete from "@mui/material/Autocomplete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./campaignAddIndex.css";
import {
  campaignAddThunk,
  campaignThunk,
  credentialAddThunk,
  feedsThunk,
  ftpConfigThunk,
  mastercampaignAddThunk,
  publisherAddThunk,
  publisherThunk,
} from "../../reducers/request_api";
import Menu from "@mui/material/Menu";
import { currencies } from "currencies.json";
import {
   
    Stack,
    Chip
  } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: 300,
    maxWidth: 200,
  },
}));

function CampaignMasterAdd() {
  const classes = useStyles();

  const [campaignName, setCampaignName] = useState([]);
  const [publisherContainer, setPublisherContainer] = useState([]);
  const [publisherId, setPublisherId] = useState("");
  const [refresh, setRefresh] = useState(0);

  const [credContainer, setCredContainer] = useState([]);

 
  const [trafficErrorState, setTrafficError] = useState("");
  const [campaignErrorState, setCampaignError] = useState("");
  const [selectedCampaigns,setSelectedCampaigns] = useState([]);
  const [campaignContainer,setCampaignContainer] = useState([]);
  const [campaignselectedError,setCampaignSelectedError] = useState("")
 



  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(publisherThunk()).then((response) => {
        
      setPublisherContainer(response.payload);
    });
    dispatch(campaignThunk()).then((response) => {
        setCampaignContainer(response.payload)
    }) 
    
   
   
  }, [refresh]);

  return (
    <>
     
      {/* Div for layout horizontal  */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",

          width: "100%",
        }}
      >
        {/* Div For Input Fields  */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",

            marginLeft: "2%",
          }}
        >
          <div>
            <IconButton
              sx={{
                backgroundColor: "white",
                border: "1px solid #C5C5C5",
                borderRadius: "8px",
              }}
              onClick={() => navigate("/campaigns")}
            >
              <ArrowBackIcon />
            </IconButton>
          </div>
          <div
            style={{
              fontSize: "20px",
              color: "#0D0992",
              fontWeight: 600,
              marginTop: "10px",
            }}
          >
            Master Campaign Add
          </div>
          <div style={{ display: "flex", marginTop: "30px" }}>
            <div
              style={{
                display: "flex",
              }}
            ></div>
          </div>
          {/* The Action Input */}
          <div style={{ display: "flex",flexDirection:"column" }}>
          <div
            style={{
              display: "flex",

              marginTop: "20px",
            }}
          >
            <div
              style={{
                marginLeft: "100px",
                width: "160px",
                fontWeight: 600,
                fontSize: "16px",
                paddingTop: "5px",

                color: "#0D0992",
              }}
            >
              Campaign Filename:
            </div>
            <div style={{ marginLeft: "5px" }}>
              <Input
                placeholder="Enter Filename"
                disableUnderline={true}
                onChange={(e) => {
                  setCampaignName(e.target.value);
                  if (e.target.value == "") {
                    setCampaignError("Field Is Empty");
                  } else {
                    setCampaignError("");
                  }
                }}
                sx={{
                  border: "0.5px solid #C5C5C5",
                  backgroundColor: "white",
                  width: "350px",
                  borderRadius: "10px",

                  paddingLeft: "15px",
                  paddingBottom: "5px",
                  input: {
                    color: "black",
                    fontWeight: "400",
                    "&::placeholder": {
                      color: "#212121",
                      opacity: 0.8,
                    },
                  },
                }}
              />
              <FormHelperText sx={{ color: "red" }}>
                {campaignErrorState}
              </FormHelperText>
            </div>
          </div>
          
          <div
            style={{
              display: "flex",

              marginTop: "20px",
            }}
          >
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                paddingTop: "12px",
                color: "#0D0992",
                marginLeft: "100px",
                width: "160px",
              }}
            >
              Publisher:
            </div>

            <div style={{ marginLeft: "5px" }}>
              <div style={{ display: "flex" }}>
                <FormControl size="small">
                  <InputLabel id="partner-label">Select Publisher</InputLabel>
                  <Select
                    size="small"
                    labelId="partner-label"
                    id="demo-simple-select"
                    value={publisherId}
                    onChange={(e) => {
                      setPublisherId(e.target.value);
                    }}
                    sx={{
                        width: "350px",
                      borderRadius: "7px",
                      "& fieldset": {
                        borderColor: "#C5C5C5",
                        borderWidth: "0.5px",
                      },
                      backgroundColor: "white",
                    }}
                    label="Select publisher"
                  >
                    {publisherContainer.map((value) => (
                      <MenuItem value={value.id} key={value.user_id}>
                        {value.user_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText sx={{ color: "red" }}>
                    {trafficErrorState}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",

              marginTop: "20px",
            }}
          >
            <div
              style={{
                fontWeight: 600,
                fontSize: "16px",
                paddingTop: "12px",
                color: "#0D0992",
                marginLeft: "100px",
                width: "160px",
              }}
            >
              Select Campaigns:
            </div>

            <div style={{ marginLeft: "5px" }}>
              <div style={{ display: "flex" }}>
                <FormControl size="small">
                  <InputLabel id="partner-label">Campaigns</InputLabel>
                  <Select
                    
                    multiple
                    value={selectedCampaigns}
                    labelId="partner-label"
                    id="demo-simple-select"
                    renderValue={(selected) => (
                        <Stack gap={1} direction="row" flexWrap="wrap">
                          {selected.map((value) => (
                            <Chip key={value.id} label={value.filename} />
                          ))}
                        </Stack>
                      )}
                    onChange={(e) => {
                      setSelectedCampaigns(e.target.value);
                      console.log(selectedCampaigns)
                    }}
                    sx={{
                      width: "350px",
                      
                      borderRadius: "7px",
                      "& fieldset": {
                        borderColor: "#C5C5C5",
                        borderWidth: "0.5px",
                      },
                      backgroundColor: "white",
                    }}
                    label="Select Campaigns"
                  >
                    {campaignContainer.map((value) => (
                      <MenuItem value={value} key={value.id}>
                        {value.filename}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText sx={{ color: "red" }}>
                    {campaignselectedError}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>
          </div>
          
          </div>
</div>
        
        <div style={{ marginLeft: "300px", marginTop: "10px" }}>
          <Button
            variant="contained"
            onClick={async () => {
              let decision = true;

              if (publisherId == "" || publisherId == null) {
                setTrafficError("This Field Should Be Selected");
                decision = false;
              }
              
              if (selectedCampaigns.length == 0) {
                
                setCampaignSelectedError("Campaign should be selected");
                decision = false;
              }
              if(campaignName == ""){
                setCampaignError("Filename should not be empty");
                decision = false;
              }
           
            
              const data = {
               campaigns:selectedCampaigns,
               publisher:publisherId,
               filename:campaignName
              };
           

              if (decision) {
                console.log("The data is ",data)
                dispatch(mastercampaignAddThunk(data)).then((res) => {
                  navigate("/mastercampaigns")
                    
                  // dispatch(campaignThunk()).then((res) => navigate("/campaigns"))
                });
              }
            }}
            sx={{
              borderRadius: "10px",
              backgroundColor: "#0D0992",
              width: "100px",
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
}

export default CampaignMasterAdd;
