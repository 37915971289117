import React, { useState, useEffect, useRef } from "react";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import AddIcon from "@mui/icons-material/Add";
import countryadd from "../../assets/country-add.png";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TextField from "@mui/material/TextField";
import NewTextField from "../NewTextField";
import CustomTextField from "../CustomTextField";

import Modal from "@mui/material/Modal";

import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import TableSortLabel from "@mui/material/TableSortLabel";

import Box from "@mui/material/Box";
// import { deleteData } from "../helpers/helpers";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import { makeStyles } from "@mui/styles";
import GenericTableBox from "../GenericTableBox";
import {
  usersThunk,
  userAddThunk,
  userDeleteThunk,
  employeeAddThunk,
  employeeDeleteThunk,
  employeeEditThunk,
  userEditThunk,
  employeeSearchUpdate,
  employeeThunk,
} from "../../reducers/request_api";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";

import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

const useStyles = makeStyles({
  root: {
    "& label": {
      width: "100%",
      // paddingTop:"-50px",

      "&.Mui-focused": {
        transform: "translate(-6px, -12px) scale(0.8)",
        transformOrigin: "center",
        fontSize: "15px !important",
        color: "#0D0992",
      },
    },
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        borderColor: "none",
        borderRadius: "10px",
        boxShadow: "rgba(0, 0, 0, 0.06) 0px 5px 4px 0px",
        height: "40px",
      },
    },
    // '&.shrink': {
    //   'transform': 'translate(15px, 0px) scale(1.05)',
    // },
  },
});

function UserIndex({}) {
  const user = useSelector((state) => state.clickmatrix.user);
  console.log("The user is ",user)

  let publisherRef = useRef();

  const [userContainer, setuserContainer] = useState([
   
  ]);
  const [userSearchContainer, setUserSearchContainer] = useState([]);
  const [show, setShow] = useState(false);
  const [update, setUpdate] = useState(false);

  const [privilege, setPrivilege] = useState("");
  const [useremail, setUseremail] = useState({
    interact: false,
    value: "",
    error: "",
  });
  const [userpassword, setUserpassword] = useState({
    interact: false,
    value: "",
    error: "",
  });
  const [username, setUsername] = useState({
    interact: false,
    value: "",
    error: "",
  });
  const [userstatus, setUserstatus] = useState({
    interact: false,
    value: "",
    error: "",
  });

  const [useraddemail, setUseraddemail] = useState({
    interact: false,
    value: "",
    error: "",
  });
  const [useraddpassword, setUseraddpassword] = useState({
    interact: false,
    value: "",
    error: "",
  });
  const [useraddname, setUseraddname] = useState({
    interact: false,
    value: "",
    error: "",
  });
  const [useraddstatus, setUseraddstatus] = useState({
    interact: false,
    value: "",
    error: "",
  });

  const [userupdateid, setUserupdateid] = useState(0);
  const [userupdateemail, setUserupdateemail] = useState({
    interact: false,
    value: "",
    error: "",
  });
  const [userupdatepassword, setUserupdatepassword] = useState({
    interact: false,
    value: "",
    error: "",
  });
  const [userupdatename, setUserupdatename] = useState({
    interact: false,
    value: "",
    error: "",
  });
  const [userupdatestatus, setUserupdatestatus] = useState({
    interact: false,
    value: "",
    error: "",
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("filename");

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteItem, setDeleteItem] = useState({ filename: "", id: "" });

  const classes = useStyles();
  const dispatch = useDispatch();

  var loader = useSelector((state) => state.clickrequests.isLoading);

  const handleModal = (data) => {
    console.log("The data in handleModal is ",data)
    setUserupdateemail((prevState) => ({
      ...prevState,
      value:data.email}))
    setUserupdatename((prevState) => ({
      ...prevState,
      value:data.name}))
    setUserupdatepassword((prevState) => ({
      ...prevState,
      value:''}))
    setUserupdatestatus((prevState) => ({
      ...prevState,
      value:data.status}))
    setUserupdateid(data.id)
    setUpdate(true)
  }

  useEffect(() => {
    dispatch(usersThunk()).then((response) => {
      console.log("The response is :",response)
      setuserContainer(response.payload)
    })
    setUseremail((prevState) => ({
      ...prevState,
      value: user[0].email,
    }));

    setUsername((prevState) => ({
      ...prevState,
      value: user[0].name,
    }));

    setUserstatus((prevState) => ({
      ...prevState,
      value: user[0].status != "admin" ? "view" : "admin",
    }));
  }, []);

  return (
    <>
      {loader ? (
        <>
          <Stack spacing={2} style={{ marginTop: "70px", marginLeft: "20px" }}>
            {/* For variant="text", adjust the height via font-size */}
            <div
              style={{
                display: "flex",
                width: "62%",
                justifyContent: "space-between",
              }}
            >
              <Skeleton variant="rounded" width={130} height={40} />
              <Skeleton variant="rounded" width={200} height={40} />
            </div>
            <Skeleton variant="rounded" width={1150} height={400} />
          </Stack>
        </>
      ) : (
        <>
          <Dialog
            open={deleteDialog}
            onClose={() => {
              setDeleteDialog(false);
              setDeleteItem({});
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm deletion"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure to delete <strong>{deleteItem.filename}</strong>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                variant="outlined"
                sx={{
                  border: "1px solid #0D0992",
                  color: "#0D0992",
                  borderRadius: "7px",
                  fontSize: "13px",
                }}
                onClick={() => setDeleteDialog(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#0D0992",
                  borderRadius: "7px",
                  fontSize: "13px",
                }}
                onClick={() => {
                 
                  dispatch(userDeleteThunk(deleteItem.id)).then(() => {
                    setDeleteDialog(false);
                  });
                }}
                autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          <Modal
            open={show}
            onClose={() => setShow(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                height: 400,
                bgcolor: "background.paper",
                border: "2px solid white",
                boxShadow: 24,
                p: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 600,
                  fontSize: "18px",
                  color: "#0D0992",
                }}
              >
                User Add
              </div>

              <div style={{ marginTop: "30px" }}>
                <FormControl size="small">
                  <InputLabel id="demo-simple-select">Select Role</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size="small"
                    error={
                      useraddstatus.error != "" ||
                      (useraddstatus.interact == true &&
                        useraddstatus.value == "")
                    }
                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                    value={useraddstatus.value}
                    sx={{
                      "& fieldset": {
                        borderColor: "#C5C5C5",
                        borderWidth: "0.5px",
                      },
                      width: "250px",
                      borderRadius: "10px",
                      backgroundColor: "white",
                    }}
                    label="FTP Upload Area"
                    onChange={(e) => {
                      let error = "";
                      if (e.target.value == "") {
                        error = "Not Selected!";
                      } else {
                        error = "";
                      }
                      setUseraddstatus((prevState) => ({
                        ...prevState,
                        interact: true,
                        value: e.target.value,
                        error: error,
                      }));
                    }}
                  >
                    <MenuItem value={"admin"} key={"admin"}>
                      Admin
                    </MenuItem>
                    <MenuItem value={"view"} key={"view"}>
                      View Only
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormHelperText sx={{ color: "#D32F2F", marginLeft: "15px" }}>
                  {useraddstatus.error}
                </FormHelperText>
              </div>
              <div style={{ marginTop: "30px" }}>
                <NewTextField
                  name="Email"
                  entity={useraddemail}
                  setEntity={setUseraddemail}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <NewTextField
                  name="Password"
                  entity={useraddpassword}
                  setEntity={setUseraddpassword}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <NewTextField
                  name="Name"
                  entity={useraddname}
                  setEntity={setUseraddname}
                />
              </div>

              <Button
                variant="contained"
                onClick={() => {
                  let decision = true;
                  if (useraddemail.value == "") {
                    setUseraddemail((prevState) => ({
                      ...prevState,
                      error: "Empty Field",
                    }));
                    decision = false;
                  }
                  if (useraddpassword.value == "") {
                    setUseraddpassword((prevState) => ({
                      ...prevState,
                      error: "Empty Field",
                    }));
                    decision = false;
                  }

                  if (useraddstatus.value == "") {
                    setUseraddstatus((prevState) => ({
                      ...prevState,
                      error: "Not Selected!",
                    }));
                    decision = false;
                  }
                  if (useraddname.value == "") {
                    setUseraddname((prevState) => ({
                      ...prevState,
                      error: "Empty field!",
                    }));
                    decision = false;
                  }

                  if (decision) {
                    dispatch(
                      userAddThunk({
                        name: useraddname.value,
                        password: useraddpassword.value,
                        email: useraddemail.value,
                        status: useraddstatus.value,
                        is_active: true,
                      })
                    ).then(() => {
                      window.location.reload();
                    });
                  }
                }}
                sx={{
                  backgroundColor: "#0D0992",
                  marginTop: "30px",
                  width: "100px",
                  borderRadius: "7px",
                }}
              >
                Save
              </Button>
            </Box>
          </Modal>
          <Modal
            open={update}
            onClose={() => setUpdate(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                height: 400,
                bgcolor: "background.paper",
                border: "2px solid white",
                boxShadow: 24,
                p: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 600,
                  fontSize: "18px",
                  color: "#0D0992",
                }}
              >
                Edit User
              </div>

              <div style={{ marginTop: "30px" }}>
                <FormControl size="small">
                  <InputLabel id="demo-simple-select">Select Role</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size="small"
                    error={
                      userupdatestatus.error != "" ||
                      (userupdatestatus.interact == true &&
                        userupdatestatus.value == "")
                    }
                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                    value={userupdatestatus.value}
                    sx={{
                      "& fieldset": {
                        borderColor: "#C5C5C5",
                        borderWidth: "0.5px",
                      },
                      width: "250px",
                      borderRadius: "10px",
                      backgroundColor: "white",
                    }}
                    label="FTP Upload Area"
                    onChange={(e) => {
                      let error = "";
                      if (e.target.value == "") {
                        error = "Not Selected!";
                      } else {
                        error = "";
                      }
                      setUserupdatestatus((prevState) => ({
                        ...prevState,
                        interact: true,
                        value: e.target.value,
                        error: error,
                      }));
                    }}
                  >
                    <MenuItem value={"admin"} key={"admin"}>
                      Admin
                    </MenuItem>
                    <MenuItem value={"view"} key={"view"}>
                      View Only
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormHelperText sx={{ color: "#D32F2F", marginLeft: "15px" }}>
                  {userupdatestatus.error}
                </FormHelperText>
              </div>
              <div style={{ marginTop: "30px" }}>
                <NewTextField
                  name="Email"
                  entity={userupdateemail}
                  setEntity={setUserupdateemail}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <NewTextField
                  name="Password"
                  entity={userupdatepassword}
                  setEntity={setUserupdatepassword}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                <NewTextField
                  name="Name"
                  entity={userupdatename}
                  setEntity={setUserupdatename}
                />
              </div>

              <Button
                variant="contained"
                onClick={() => {
                  let decision = true;
                  if (userupdateemail.value == "") {
                    setUserupdateemail((prevState) => ({
                      ...prevState,
                      error: "Empty Field",
                    }));
                    decision = false;
                  }
                  if (userupdatepassword.value == "") {
                    setUserupdatepassword((prevState) => ({
                      ...prevState,
                      error: "Empty Field",
                    }));
                    decision = false;
                  }

                  if (userupdatestatus.value == "") {
                    setUserupdatestatus((prevState) => ({
                      ...prevState,
                      error: "Not Selected!",
                    }));
                    decision = false;
                  }
                  if (userupdatename.value == "") {
                    setUserupdatename((prevState) => ({
                      ...prevState,
                      error: "Empty field!",
                    }));
                    decision = false;
                  }

                  if (decision) {
                    dispatch(
                      userEditThunk([{
                        name: userupdatename.value,
                        password: userupdatepassword.value ,
                        email: userupdateemail.value,
                        status: userupdatestatus.value,
                        is_active: true,
                      },userupdateid])
                    ).then(() => {
                      window.location.reload();
                    });
                  }
                }}
                sx={{
                  backgroundColor: "#0D0992",
                  marginTop: "30px",
                  width: "100px",
                  borderRadius: "7px",
                }}
              >
                Update
              </Button>
            </Box>
          </Modal>
          {user[0].status != "admin" ? (
                  <></>
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setShow(true);
                      // setErrorHelper("");
                      const timeout = setTimeout(() => {
                        publisherRef.current.focus();
                      }, 100);
                    }}
                    sx={{
                      backgroundColor: "#0D0992",
                      borderRadius: "8px",
                      fontWeight: 600,
                      width: "169px",
                      minWidth: "160px",
                      height: "37.5px",
                    }}
                  >
                    Add User
                  </Button>
                )}
                {user[0].status == 'admin' ?
          
          <GenericTableBox
            tableHeaders={["id", "name","email","status"]}
            // editLink={"/user/edit"}
            editLink={handleModal}
            editStyle={"modal"}
            deleteThunk={userDeleteThunk}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            Container={userContainer}
            SearchContainer={userSearchContainer}
          />
          : <></>}
        </>
      )}
    </>
  );
}

export default UserIndex;
