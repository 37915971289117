import React,{useState,useRef,useEffect} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import PublisherVerticalNav from "../../Navbar/PublisherVerticalNav";


import PublisherInfo from "../../Publisher App/PublisherInfo";
import PublisherQuery from "../../Publisher App/PublisherQuery";
import PublisherTableBox from "../../Publisher App/PublisherTable";
import PublisherProfile from "../../Publisher App/PublisherProfile";
import LoadingBar from 'react-top-loading-bar';

function PublisherPage({isAuthenticated}) {
  const [totalBudget,setTotalBudget] = useState("");
  const [totalRevenue,setTotalRevenue] = useState("");
  const [totalClicks,setTotalClicks] = useState("");
  const [validClicks,setValidClicks] = useState("");
  const [invalidClicks,setInvalidClicks] = useState("");

  const ref = useRef(null)

  useEffect(() => {
    ref.current.continuousStart()
    ref.current.complete()
  })

  return (
     <>
        <Box sx={{ position: "absolute", width: "100%" }}>
        <Grid container sx={{ height: "100vh" }}>
        <LoadingBar color="#0D0992" ref={ref} />
          <Grid item md={2} lg={2} sx={{ marginTop: "30px" }}>
            <PublisherVerticalNav lineState={1}/>
          </Grid>
          <Grid item md={10} lg={10} sx={{ paddingLeft: 2 }}>
            <PublisherProfile />
          
            <PublisherInfo totalBudget={totalBudget} totalRevenue={totalRevenue} totalClicks={totalClicks} totalValid={validClicks} totalInvalid={invalidClicks} />
            {/* <PublisherQuery /> */}
            
            <PublisherTableBox setTotalBudget={setTotalBudget} setTotalRevenue={setTotalRevenue} setTotalClicks={setTotalClicks} setTotalValid={setValidClicks} setTotalInvalid={setInvalidClicks} />
        
          </Grid>
        </Grid>
      </Box>

     </>
  )
}


export default PublisherPage