import React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TextField from "@mui/material/TextField";

function NewTextField({ name, entity, setEntity,readValue }) {
  return (
    <>
      <TextField
        id="outlined-basic"
        label={name}
        value={entity.value}
        onChange={(e) => {
          let error = "";
          if (e.target.value == "") {
            error = "Empty Field!";
          }
          setEntity((prevState) => ({
            ...prevState,
            interact: true,
            value: e.target.value,
            error: error,
          }));
        }}
        variant="outlined"
        InputProps={{
          readOnly:readValue
        }}
        error={
          entity.error != "" || (entity.interact == true && entity.value == "")
        }
        helperText={
          entity.error != "" || (entity.interact == true && entity.value == "")
            ? "Empty field!"
            : " "
        }
        InputLabelProps={{ shrink: true }}
        size="small"
        sx={{
          "& .MuiInputLabel-root": {
            color: "#0D0992",
            fontSize: "17px",
            padding: 0,
          }, //styles the label
          "& .MuiOutlinedInput-root": {
            "& > fieldset": {
              borderColor: "none",
              borderRadius: "5px",
              boxShadow: "rgba(0, 0, 0, 0.06) 0px 5px 4px 0px",
            },
          },
          "& .MuiOutlinedInput-root.Mui-focused": {
            "& > fieldset": {
              borderColor: "#0D0992",
            },
          },
          width: "250px",
          // flexBasis:"220px",
        }}
      />
    </>
  );
}

export default NewTextField;
