import React,{useState,useEffect,useRef} from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import VerticalNav from "../../Navbar/VerticalNav";
import ProfileIndex from "../../Profile/ProfileIndex";
import axios from 'axios';
import ProfileMenu from "../../Profile/ProfileMenu";
import LoadingBar from 'react-top-loading-bar';

function Profile() {
  const ref = useRef(null);
  useEffect(() => {
    ref.current.continuousStart()
    ref.current.complete()
  })

  return (
     <>
        
        <Grid container sx={{ height: "100vh",backgroundColor:"#F7F8FA" }}>
        <LoadingBar color="#0D0992" ref={ref} />
          <Grid item md={2} lg={2} sx={{ height:"100%" }}>
            <VerticalNav />
          </Grid>
          <Grid item md={10} lg={10} sx={{ paddingLeft: 2, overflowY: "auto", height: "100%" }}>
            <ProfileMenu />
            <ProfileIndex />
            {/* <FeedMenu /> */}
            {/* <TableBox />  */}
          </Grid>
        </Grid>
     

     </>
  )
}

export default Profile