import Main from "./Components/pages/Feed Pages/Main";
import { Routes, Route } from "react-router-dom";
import FeedCreate from "./Components/pages/Feed Pages/FeedCreate";
import FeedEdit from "./Components/pages/Feed Pages/FeedEdit";
import Campaigns from "./Components/pages/Campaign Pages/Campaigns";
import Partners from "./Components/pages/Partner Pages/Partners";
import Home from "./Components/pages/Home";
import Home2 from "./Components/pages/Home2";
import Login from "./Components/pages/Login";
import Tagmap from "./Components/pages/Tagmap Pages/Tagmap";
import Dashboard from "./Components/pages/Dashboard Pages/Dashboard";
import Profile from "./Components/pages/Profile Page/Profile";
import Publisher from "./Components/pages/Publisher Pages/Publisher";
import Credentials from "./Components/pages/Credential Pages/Credentials";
import CampaignAdd from "./Components/pages/Campaign Pages/CampaignAdd";
import Companies from "./Components/pages/Company Pages/Companies";
import CampaignAnalytics from "./Components/pages/Campaign Pages/CampaignAnalytics";
import CampaignEdit from "./Components/pages/Campaign Pages/CampaignEdit";
import PublisherAnalytics from "./Components/pages/Publisher App/PublisherAnalytics";
import Layout from "./Components/Layout";
import { connect } from "react-redux";
import NotFound from "./NotFound";
import { useState } from "react";
import PublisherPage from "./Components/pages/Publisher App/PublisherPage";
import PublisherMasterPage from "./Components/pages/Publisher App/PublisherMasterPage";
import FtpConfig from "./Components/pages/Ftp Config Pages/FtpConfig";
import Notification from "./Components/pages/Notification Pages/Notification";
import FeedReport from "./Components/pages/Feed Pages/FeedReport";
import React, { useEffect } from "react";
import { checkAuthenticated, load_user } from "./reducers/auth";
import { useDispatch, useSelector } from "react-redux";
import "./app.css";
import Employee from "./Components/pages/Employee Pages/Employee";
import HomeRobot from "./Components/pages/HomeRobot";
import HomeServices from "./Components/pages/HomeServices";
import HomeA from "./Components/pages/HomeA";
import HomeVision from "./Components/pages/HomeVision";
import HomeSolution from "./Components/pages/HomeSolution";
import UserIndex from "./Components/Employee/UserIndex";
import Users from "./Components/pages/User Pages/Users";
import LastThreeMonths from "./Components/LastThreeMonths";
import MasterCampaignAddPage from "./Components/pages/Campaign Pages/MasterCampaignAddPage";
import MasterCampaigns from "./Components/pages/Campaign Pages/MasterCampaigns";


function App({ isAuthenticated, user }) {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(checkAuthenticated()).then((response) => {
      // console.log("The response from app  is :",response)
      dispatch(load_user()).then(() => {});
    });
  }, []);
  
  
  // console.log("is auth res ",isAuthenticated)
  var status = null;
  if (user) {
    status = user[0].status;
    // console.log("The status is :",status)
  }

  return (
    <>
      {/* { isAuthenticated == null? (
       <div className="loader">
       <span className="bar"></span>
       <span className="bar"></span>
       <span className="bar"></span>
   </div>
      ) : ( */}
        <div className="App">
         
          <Routes>
            {(isAuthenticated === true && (status === 'admin' || status === 'view')) && (
              <>
                <Route path="" element={<Dashboard />} />
                <Route path="/feeds" element={<Main />} />
              
                <Route path="/login" element={<Login />} />
                <Route path="/feeds/new" element={<FeedCreate />} />
                <Route path="/feeds/reports" element={<FeedReport />} />
                <Route path="/feed/edit/:id" element={<FeedEdit />} />
                <Route path="/campaign/edit/:id" element={<CampaignEdit />} />
                <Route path="/campaigns" element={<Campaigns />} />
                <Route path="/mastercampaigns" element={<MasterCampaigns />} />
                {/* <Route path="/employee" element={<Employee />} /> */}
                <Route path="/users" element={<Users />} />
               
                <Route path="/campaign/new" element={<CampaignAdd />} />
                <Route path="/master-campaign/new" element={<MasterCampaignAddPage />} />
                <Route
                  path="/campaign-analytics/:id"
                  element={<CampaignAnalytics />}
                />
                <Route path="/partners" element={<Partners />} />
                <Route path="/tagmap" element={<Tagmap />} />
                {/* <Route path="/dashboard" element={<Dashboard />} /> */}
                <Route path="/profile" element={<Profile />} />
                <Route path="/publisher" element={<Publisher />} />
                <Route path="/credentials" element={<Credentials />} />
                <Route path="/companies" element={<Companies />} />
                <Route path="/publisher_app" element={<PublisherPage />} />
                <Route path="/ftp_config" element={<FtpConfig />} />
                <Route path="/notifications" element={<Notification />} />
                <Route path="*" element={<NotFound />} />
              </>
            )}
            {isAuthenticated === true && status === 'publisher' && (
              <>
                <Route path="/publisher_table" element={<PublisherPage />} />
                <Route path="/masterfeeds" element={<PublisherMasterPage />} />
                <Route
                  path="/publisher_analytics/:id"
                  element={<PublisherAnalytics />}
                />
                <Route path="/login" element={<Login />} />
                <Route path="" element={<PublisherPage />} />
                <Route path="*" element={<NotFound />} />
                <Route path="/notifications" element={<Notification />} />
              </>
            )}

            {(isAuthenticated == null && (localStorage.getItem('access') == "" || localStorage.getItem('access') == null)) && (
              <>
                <Route path="" element={<HomeA />} />
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<Login />} />
              </>
            )}
          </Routes>
        
        </div>
      {/* )} */}
    </>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.clickmatrix.isAuthenticated,
  user: state.clickmatrix.user,
});

export default connect(mapStateToProps)(App);
