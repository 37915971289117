import axios from "axios";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { clearCacheData } from "./request_api";

const initialState = {
  access: localStorage.getItem("access"),
  refresh: localStorage.getItem("refresh"),
  isAuthenticated: null,
  user: null,
};

export const checkAuthenticated = createAsyncThunk(
  "user/authenticate",
  async (_,{rejectWithValue}) => {
    if (localStorage.getItem("access")) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      const body = JSON.stringify({ token: localStorage.getItem("access") });

      const res = await axios.post(
        `${process.env.REACT_APP_API_URL_AUTH}/auth/jwt/verify/`,
        body,
        config
      );
      return res;
    }
    else{
      return  rejectWithValue("No User Found")
        
      }
    
  }
);

export const load_user = createAsyncThunk("users/load", async (thunkAPI) => {
  if (localStorage.getItem("access")) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${localStorage.getItem("access")}`,
        Accept: "application/json",
      },
    };

    const res = await axios.get(
      `${process.env.REACT_APP_API_URL_AUTH}/auth/users/`,
      config
    )
    
    return res.data;
  }
});

export const login = createAsyncThunk("users/login",async(data) => {
   
    let email = data[0];
    let password = data[1];
    const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
    
      const body = JSON.stringify({ email, password });
     
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL_AUTH}/auth/jwt/create/`,
          body,
          config
        );
        
        // return res.data;
        return res.data
     
});



export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    logout(state){
     
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      
      return {
          ...state,
          access:null,
          refresh:null,
          isAuthenticated:null,
          user:null
      }
    },
    authenticationFail(state){
     
      return {
        ...state,
        isAuthenticated:null,
      }
    }
  },
  extraReducers: {
    [checkAuthenticated.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        isAuthenticated: true,
      };
    },
    
    [checkAuthenticated.rejected]: (state, { payload }) => {
      
      return {
        ...state,
        isAuthenticated: null,
      };
    },
    [load_user.fulfilled]: (state, { payload }) => {
      
      return {
        ...state,
        user: payload,
      };
    },
    [load_user.rejected]: (state, { payload }) => {
      return {
        ...state,
        user: null,
      };
    },
   
    [login.fulfilled]:(state,{payload}) => {
        localStorage.setItem('access',payload.access);
       

            return {
                ...state,
                access : payload.access,
                refresh:payload.refresh,
                isAuthenticated:true

            }
            
    },
    [login.rejected]:(state,{payload}) => {
        localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            return {
                ...state,
                access:null,
                refresh:null,
                isAuthenticated:null,
                user:null

            }
    }
  },
});

export const ClickActions = userSlice.actions;

export default userSlice.reducer;