import React from "react";
import servicebg from "../../assets/bg-service.png";
import robot from "../../assets/robot-service.png";
import Button from "@mui/material/Button";
import reportservice from "../../assets/report-service.png";
import integrateservice from "../../assets/integrate-service.png";
import campaignservice from "../../assets/campaign-service.png";
import botservice from "../../assets/bot-service.png";
import { Link } from "react-router-dom";

function HomeServices() {
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${servicebg})`,
          height: "100vh",
          backgroundSize: "cover",
        }}
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ display: "flex", flexDirection: "column"}}>
            <div
              style={{
                marginTop: "100px",
                marginLeft: "300px",
                fontWeight: 700,
                fontSize: "60px",
                lineHeight: "73px",
                letterSpacing: "0.03em",
                background:
                  "radial-gradient(50% 50% at 50% 50%, #2727F0 22.09%, #0D0D8B 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Our Matchless Services
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  marginLeft: "300px",
                  justifyContent: "space-between",
                  marginTop:"100px"
                }}
              >
                <div style={{display:"flex"}}><div><img src={reportservice} style={{marginLeft:"20px",marginRight:"25px"}}/></div><div style={{fontWeight:600,fontSize:"32px",lineHeight:"39px",width:"100px",color:"#0D0992"}}>Explicit Reporting</div></div>
                <div style={{display:"flex"}}><div><img src={integrateservice} style={{marginLeft:"20px",marginRight:"25px"}}/></div><div style={{fontWeight:600,fontSize:"32px",lineHeight:"39px",width:"170px",color:"#0D0992"}}>Effortless Integration</div></div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginLeft: "300px",
                  justifyContent: "space-between",
                  marginTop:"50px"
                }}
              >
                <div style={{display:"flex"}}><div><img src={campaignservice} style={{marginRight:"20px"}}/></div><div style={{fontWeight:600,fontSize:"32px",lineHeight:"39px",width:"100px",color:"#0D0992"}}>Campaign Optimization</div></div>
                <div style={{display:"flex"}}><div><img src={botservice} style={{marginLeft:"20px",marginRight:"25px"}} /></div><div style={{fontWeight:600,fontSize:"32px",lineHeight:"39px",width:"170px",color:"#0D0992"}}>Bot Detection</div></div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              left: "2px",
              top:"400px",
             
            }}
          >
            <img src={robot} />

            <Button component={Link} to="/login" variant="contained" sx={{ color:"#FFFFFF",width: "200px" ,marginTop:"30px",marginLeft:"80px",backgroundColor:"#0D0992",borderRadius:"5px",fontSize:"24px",fontWeight:600}}>
              Contact Us
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeServices;
