import React, { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import CustomTextField from "../CustomTextField";
import Select from "@mui/material/Select";
import countryadd from "../../assets/country-add.png";

import {
  getData,
  addData,

 
  setPass,
  getDataTwo
} from "../helpers/helpers";
import ListSubheader from "@mui/material/ListSubheader";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import TableSortLabel from "@mui/material/TableSortLabel";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FormHelperText from "@mui/material/FormHelperText";
import { companyAddThunk, companyDeleteThunk, companyEditThunk, companySearchUpdate, companyThunk, credentialThunk } from "../../reducers/request_api";
import { useDispatch,useSelector} from "react-redux";
import { connect } from "react-redux";

import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

function CompanyIndex({}) {

  const companies =  useSelector((state) => state.clickrequests.companies)
  const companySearchValue = useSelector((state) => state.clickrequests.companySearch)
  const user = useSelector((state) => state.clickmatrix.user)


  let companyRef = useRef();

  // const [loader, setLoader] = useState(true);
  const [show, setShow] = useState(false);
  const [newName, setNewName] = useState("");
  const [newCreds, setNewCreds] = useState("");
  const [newUrl, setNewUrl] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [credDetails, setCredDetails] = useState("");
  const [ftpUploadArea, setFtpUploadArea] = useState(null);
  const [credContainer, setCredContainer] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");

  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [details, setDetails] = useState("");
  const [credModal, setCredModal] = useState(false);
  const [container, setContainer] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [ftpUploadUrl, setFtpUploadUrl] = useState("");

  //Error Handling States
  const [usernameErrorState, setUsernameError] = useState("");
  const [passwordErrorState, setPasswordError] = useState("");
  const [detailErrorState, setDetailError] = useState("");
  const [companyErrorState, setCompanyError] = useState("");
  const [uploadErrorState, setUploadError] = useState("");
  const [urlErrorState, setUrlError] = useState("");
  const [editCompanyErrorState, setEditCompanyError] = useState("");
  const [editUploadErrorState, setEditUploadError] = useState("");
  const [editUrlErrorState, setEditUrlError] = useState("");
  const [editCompanyId, setEditCompanyId] = useState("");
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});

  const [reduxloaded,setReduxloaded] = useState(false);

  const [companyBackupContainer,setCompanyBackupContainer] = useState([]);
  const [searched, setSearched] = useState("");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  var loader =  useSelector((state) => state.clickrequests.isLoading)

  useEffect(() => {
   
    if(reduxloaded)
      {
        requestSearch(companySearchValue);
    setSearched(companySearchValue)
      }
      else{
    const displayLoader = async () => {
      // await getDataTwo(setContainer,setCompanyBackupContainer, "childcompanies");
      
      if(companies.length == 0){

      
      dispatch(companyThunk()).then((response) => {
        setContainer((arr) => (arr = [...response.payload]));
        setCompanyBackupContainer((arr) => (arr = [...response.payload]));
        
      })
    }
    else{
      
      setContainer((arr) => (arr = [...companies]));
      setCompanyBackupContainer((arr) => (arr = [...companies]));
      setReduxloaded(true);
    }
    // setLoader(false)
      // dispatch(credentialThunk()).then((response) => {
      //   setCredContainer((arr) => (arr = [...response.payload]))
      // })
    };
    const check = async () => {
      await displayLoader();
      // await new Promise(r => setTimeout(r, 500));
      // setLoader(false);
    };
    check();
  }
    

    // setContainer((arr) => arr = [...abu]);
  }, [refresh, credModal,reduxloaded]);

  const requestSearch = (searchedVal) => {
    if (searchedVal == "") {
      setContainer(companyBackupContainer);
      dispatch(companySearchUpdate(""))
    } else {
      const filteredRows = companyBackupContainer.filter((row) => {
        return row.name.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setContainer(filteredRows);
      setPage(0);
      dispatch(companySearchUpdate(searchedVal))
    }
  };

  const cancelSearch = () => {
    setContainer(companyBackupContainer);
    setSearched("");
    dispatch(companySearchUpdate(""))
    // requestSearch(searched);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");

    setOrderBy(property);
  };

  function descendingComparator(obj1, obj2, orderBy) {
   
    return obj1[orderBy].localeCompare(obj2[orderBy]);
  }

  function getComparator(order, orderBy, obj1, obj2) {
    return order === "desc"
      ? descendingComparator(obj1, obj2, orderBy)
      : -descendingComparator(obj1, obj2, orderBy);
  }

  function stableSort(array, orderIs, orderBy) {
    const stabilizedThis = array.map((el, index) => {
      
      return [el, index];
    });
    stabilizedThis.sort((a, b) => {
      
      const order = getComparator(orderIs, orderBy, a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }


  return (
    <>
      {loader && (
        <Stack spacing={2} style={{marginTop:"70px",marginLeft:"20px"}}>
        {/* For variant="text", adjust the height via font-size */}
        <div
          style={{
            display: "flex",
            width: "67%",
            justifyContent: "space-between"
          }}
        >
          <Skeleton variant="rounded" width={130} height={40} />
          <Skeleton variant="rounded" width={200} height={40} />
        </div>
        <Skeleton variant="rounded" width={1100} height={400} />
      </Stack>
      )}
      {!loader && (
        <>
          <Dialog
            open={deleteDialog}
            onClose={() => {
              setDeleteDialog(false);
              setDeleteItem({});
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm deletion"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure to delete <strong>{deleteItem.filename}</strong>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                variant="outlined"
                sx={{
                  border: "1px solid #0D0992",
                  color: "#0D0992",
                  borderRadius: "7px",
                  fontSize: "13px",
                }}
                onClick={() => setDeleteDialog(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#0D0992",
                  borderRadius: "7px",
                  fontSize: "13px",
                }}
                onClick={() => {
                  // setDeleteDialog(false);
                  // deleteData("childcompanies", deleteItem.id).then(() => {
                  //   setRefresh(refresh + 1);
                  // });
                  dispatch(companyDeleteThunk(deleteItem.id)).then(() => {
                    dispatch(companyThunk()).then(() => {

                      setRefresh(refresh + 1);
                      setDeleteDialog(false);
                    })
                  })
                }}
                autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          <Modal
            open={show}
            onClose={() => setShow(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 450,
                height: 370,
                bgcolor: "background.paper",
                border: "2px solid white",
                boxShadow: 24,
                p: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  color: "#0D0992",
                  fontWeight: 600,
                  fontSize: "20px",
                  marginBottom: "35px",
                  marginRight: "45px",
                }}
              >
                Enter Company Details
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "40%",
                  height: "200px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ marginTop: "30px" }}>
                  {/* For Input Fields  */}
                  <div>
                    <CustomTextField
                      setFunction={setNewName}
                      labelName={"Company Name"}
                      refs={companyRef}
                      onChange={true}
                      setErrorFunction={setCompanyError}
                    />
                    <FormHelperText sx={{ color: "red", margin: 0 }}>
                      {companyErrorState}
                    </FormHelperText>
                  </div>

                  <div style={{ marginTop: "35px" }}>
                    <CustomTextField
                      labelName={"Redirect Url"}
                      setFunction={setNewUrl}
                      onChange={true}
                      setErrorFunction={setUrlError}
                    />
                    <FormHelperText sx={{ color: "red", margin: 0 }}>
                      {urlErrorState}
                    </FormHelperText>
                  </div>
                </div>
              </div>
              <Button
                variant="contained"
                onClick={() => {
                  let decision = true;
                  if (newName == "") {
                    setCompanyError("Company Field Is Empty!");
                    decision = false;
                  }

                  if (newUrl == "") {
                    setUrlError("Url Field Is Empty!");
                    decision = false;
                  }
                  var data = {
                    name: newName,

                    redirect_url: newUrl,
                  };
                  if (decision) {
                    // addData(data, "childcompanies").then(() => {
                    //   dispatch(companyThunk()).then(() => {

                    //     setRefresh(refresh + 1);
                    //     setShow(false);
                    //   })
                    // });

                    dispatch(companyAddThunk(data)).then((res) => {
                      dispatch(companyThunk()).then(() => {

                        setRefresh(refresh + 1);
                        setShow(false);
                      })
                    })
                  }
                }}
                sx={{
                  backgroundColor: "#0D0992",
                  marginTop: "60px",
                  width: "100px",
                  borderRadius: "7px",
                }}
              >
                Save
              </Button>
            </Box>
          </Modal>

          {/* The modal for edit comapny  */}
          <Modal
            open={editModal}
            onClose={() => setEditModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 450,
                height: 370,
                bgcolor: "background.paper",
                border: "2px solid white",
                boxShadow: 24,
                p: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  color: "#0D0992",
                  fontWeight: 600,
                  fontSize: "20px",
                  marginBottom: "35px",
                  marginRight: "45px",
                }}
              >
                Enter Company Details
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "50%",
                  height: "200px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ marginTop: "30px" }}>
                  {/* For Input Fields  */}
                  <div>
                    <CustomTextField
                      setFunction={setCompanyName}
                      labelName={"Company Name"}
                      onChange={true}
                      setErrorFunction={setCompanyError}
                      width={"220px"}
                      value={companyName}
                    />
                    <FormHelperText sx={{ color: "red", margin: 0 }}>
                      {editCompanyErrorState}
                    </FormHelperText>
                  </div>

                  <div style={{ marginTop: "35px" }}>
                    <CustomTextField
                      labelName={"Redirect Url"}
                      setFunction={setFtpUploadUrl}
                      onChange={true}
                      setErrorFunction={setUrlError}
                      width={"220px"}
                      value={ftpUploadUrl}
                    />
                    <FormHelperText sx={{ color: "red", margin: 0 }}>
                      {editUrlErrorState}
                    </FormHelperText>
                  </div>
                </div>
              </div>
              <Button
                variant="contained"
                onClick={() => {
                  let decision = true;
                  if (companyName == "") {
                    setEditCompanyError("Company Name Field Is Empty!");
                    decision = false;
                  }
                  if (ftpUploadUrl == "") {
                    setEditUrlError("Url Field Is Empty!");
                    decision = false;
                  }

                  var data = {
                    name: companyName,
                    redirect_url: ftpUploadUrl,
                  };

                  if (decision) {
                    // editData(data, "childcompanies", editCompanyId).then(() => {
                    //   dispatch(companyThunk()).then(() => {
                    //   setRefresh(refresh + 1);
                    //   setEditModal(false);
                    //   })
                    // });
                    dispatch(companyEditThunk([data,editCompanyId])).then(() => {
                      dispatch(companyThunk()).then(() => {
                        setRefresh(refresh + 1);
                        setEditModal(false);
                        })
                    })
                  }
                }}
                sx={{
                  backgroundColor: "#0D0992",
                  marginTop: "60px",
                  width: "100px",
                  borderRadius: "7px",
                }}
              >
                Update
              </Button>
            </Box>
          </Modal>

          <div>
            {/* Div for vertical alignment of comapnies tag and the box - contains input and button */}
                <div style={{display:"flex",marginTop:"4%",justifyContent:"space-between"}}>
                <div
                style={{
                 
                  fontSize: "20px",
                  fontWeight: 600,
                  color: "#0D0992",
                  marginLeft:"1.7%"
                }}
              >
                {(user[0].status != "admin") ? 
            <></>:
                 <div>
                <Button
                  variant="contained"
                  onClick={() => {
                    setShow(true);
                    setCompanyError("");
                    setUploadError("");
                    setUrlError("");
                    setNewName("");
                    setNewCreds("");
                    setNewUrl("");
                    const timeout = setTimeout(() => {
                      companyRef.current.focus();
                    }, 100);
                  }}
                  sx={{
                    backgroundColor: "#0D0992",
                    borderRadius: "8px",
                    fontWeight:600,
                    width: "149px",
                    height: "37.5px",
                  }}
                >
                  Add Company
                </Button>
                </div>
}
              </div>

              {/* Div for Add Company button  */}
              <div
                style={{paddingRight:"30%",display:"flex" }}
              >
                <div>
                <TextField
              id="outlined-basic"
              size="small"
              value={searched}
              inputProps={{
                color: "rgba(0, 0, 0, 0.55)",
              }}
              label="Search"
              sx={{
                width: "230px",
                
                ".MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  "&.Mui-focused fieldset": {
                    borderColor: "#C5C5C5",
                    borderWidth: "0.5px",
                    color: "rgba(0, 0, 0, 0.55)",
                  },
                },
                "& label.Mui-focused": {
                  color: "rgba(0, 0, 0, 0.55)",
                },
                backgroundColor: "white",
                color: "rgba(0, 0, 0, 0.55)",
              }}
              variant="outlined"
              onChange={(e) => {
                requestSearch(e.target.value);
                setSearched(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searched != "" && (
                      <IconButton onClick={() => cancelSearch()}>
                        <CloseIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
                </div>
               
              </div>
                </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {/* DIV for Title */}
              

              {/* Div for Table  */}
              <div
                style={{
                  borderRadius: "15px",

                  width: "70%",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  
                  paddingBottom: "40px",
                }}
              >
                {/* {container.map((value,index) => (
                <div style={{ display: "flex" }} key={index}>
                  <div
                    style={{
                      borderBottom: "2px solid #0D0992",
                      marginTop: "30px",
                      paddingBottom: "15px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div>
                        <CustomTextField
                          labelName={"Child Company Name"}
                          shrink={true}
                          value={value.name}
                        />
                      </div>
                      <div style={{ marginLeft: "15px" }}>
                        <CustomTextField
                          labelName={"Redirect Url"}
                          shrink={true}
                          value={value.redirect_url}
                        />
                      </div>
                    </div>
                    
                  </div>
                  <div style={{ display: "flex" ,marginTop:"27px"}}>
                    <div>
                      <IconButton
                        onClick={() => {
                          setFtpUploadArea(value.ftpUploadCredentials);
                          setCompanyName(value.name);
                          setFtpUploadUrl(value.redirect_url);
                          setEditCompanyError("");
                          
                          setEditUrlError("");
                          setEditCompanyId(value.id)
                          setEditModal(true);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </div>
                    <div>
                      <IconButton onClick={() => {
                        setDeleteDialog(true);
                        setDeleteItem({"id":value.id,"filename":value.name})
                        
                       
                    }}>
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              ))} */}

                <TableContainer
                  component={Paper}
                  sx={{
                    width: "100%",
                    marginLeft: "10px",
                    borderRadius: "13px",
                    backgroundColor: "#FFFFFF",
                    border:"1px solid rgba(0, 0, 0, 0.15)",
                    marginTop: "20px",
                  }}
                >
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{ color: "grey", paddingLeft: "60px" }}
                        >
                          <TableSortLabel
                        active={orderBy === "name"}
                        direction={orderBy === "name" ? order : "asc"}
                        onClick={() => handleRequestSort("name")}
                      > <strong>Company Name</strong> </TableSortLabel>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ color: "grey", paddingLeft: "60px" }}
                        >
                          <TableSortLabel
                        active={orderBy === "redirect_url"}
                        direction={orderBy === "redirect_url" ? order : "asc"}
                        onClick={() => handleRequestSort("redirect_url")}
                      > <strong>Redirect Url</strong></TableSortLabel>
                        </TableCell>

                        <TableCell align="left"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stableSort(container, order, orderBy).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((value, index) => (
                        <React.Fragment key={index}>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              align="left"
                              sx={{ width: "20%", paddingLeft: "60px" }}
                            >
                              {value.name}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{ width: "30%", paddingLeft: "60px" }}
                            >
                              {value.redirect_url}
                            </TableCell>
                            {/* <TableCell
                      align="left"
                      sx={{ width: "10%", paddingLeft: "60px" }}
                    >
                      0.00
                    </TableCell> */}
                            <TableCell align="left" sx={{ width: "15%" }}>
                              {/* <Box sx={{display:"flex",justifyContent:"space-between"}}> */}
                              {(user[0].status != "admin") ? <></> :
                              <>
                              <IconButton
                                onClick={() => {
                                  setFtpUploadArea(value.ftpUploadCredentials);
                                  setCompanyName(value.name);
                                  setFtpUploadUrl(value.redirect_url);
                                  setEditCompanyError("");

                                  setEditUrlError("");
                                  setEditCompanyId(value.id);
                                  setEditModal(true);
                                }}
                              >
                                <EditIcon sx={{fontSize: "21px"}}/>
                              </IconButton>

                              <IconButton
                                aria-label="expand-row"
                                size="small"
                                sx={{marginLeft:"5%"}}
                                onClick={() => {
                                  // deleteData("campaigns", value.id).then(() => {
                                  //   setRefresh(refresh + 1);
                                  // });
                                  setDeleteItem({
                                    id: value.id,
                                    filename: value.name,
                                  });
                                  setDeleteDialog(true);
                                }}
                              >
                                <DeleteIcon sx={{fontSize: "21px"}} />
                              </IconButton>
</>}
                              {/* </Box> */}
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        {companyBackupContainer.length > 10 && 
                      <TablePagination
      
      count={container.length}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[5,10,20,30]}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  }
    </TableRow>
    </TableFooter>
                  </Table>
                </TableContainer>
              </div>
              {/* <div
          style={{
            display: "flex",
            
            
            justifyContent:"end",
            border:"1px solid red",
            alignItems:"end",
            
            marginLeft: "auto",
            height: "80vh",
          }}
        >
         
          <div>
            <img src={countryadd} alt="end-of-country" />
          </div>
        </div> */}
            </div>
          </div>
        </>
      )}
    </>
  );
}



export default CompanyIndex;
