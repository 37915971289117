import React, { useState, useEffect, useRef } from "react";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import AddIcon from "@mui/icons-material/Add";
import countryadd from "../../assets/country-add.png";
import Button from "@mui/material/Button";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import TableSortLabel from "@mui/material/TableSortLabel";

import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";

import "./popup.css";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { getData,getDataTwo, addData } from "../helpers/helpers";
import CustomTextField from "../CustomTextField";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import { makeStyles } from "@mui/styles";
import { useDispatch,useSelector} from "react-redux";
import { connect } from "react-redux";
import { partnerAddThunk, partnerDeleteThunk, partnerEditThunk, partnerSearchUpdate, partnerThunk } from "../../reducers/request_api";

import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

const useStyles = makeStyles({
  root: {
    "& label": {
      width: "100%",
      // paddingTop:"-50px",

      "&.Mui-focused": {
        transform: "translate(-6px, -12px) scale(0.8)",
        transformOrigin: "center",
        fontSize: "15px !important",
        color: "#0D0992",
      },
    },
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        borderColor: "none",
        borderRadius: "10px",
        boxShadow: "rgba(0, 0, 0, 0.06) 0px 5px 4px 0px",
        height: "40px",
      },
    },
    // '&.shrink': {
    //   'transform': 'translate(15px, 0px) scale(1.05)',
    // },
  },
});
function PartnerAdd({}) {
  const partners = useSelector((state) =>  state.clickrequests.partners)
  const partnerSearchValue =  useSelector((state) => state.clickrequests.partnerSearch)
  const user =  useSelector((state) => state.clickmatrix.user)
  
  let partnerRef = useRef();

  const [country, setCountry] = useState("");
  const [container, setContainer] = useState([]);
  const [show, setShow] = useState(false);
  const [cncode, setCn] = useState("");
  const [partnerName, setPartnerName] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [editId, setEditId] = useState();
  const [editModal, setEditModal] = useState(false);
  const [errorHelper, setErrorHelper] = useState("");
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [partnerBackupContainer,setPartnerBackupContainer] = useState([]);
  const [searched, setSearched] = useState("");

  const [reduxloaded,setReduxloaded] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // const [loader,setLoader] = useState(true);

  const classes = useStyles();
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  var loader =  useSelector((state) => state.clickrequests.isLoading)

  useEffect(() => {
   
    // getDataTwo(setContainer,setPartnerBackupContainer, "partners");
    if(reduxloaded)
      {
        requestSearch(partnerSearchValue);
  setSearched(partnerSearchValue);
      }
      else{
    if(partners.length == 0){

    
    dispatch(partnerThunk()).then((response) => {
      setContainer((arr) => (arr = [...response.payload]));
      setPartnerBackupContainer((arr) => (arr = [...response.payload]))
      // setLoader(false)
    })
  }
  else{
    setContainer((arr) => (arr = [...partners]));
      setPartnerBackupContainer((arr) => (arr = [...partners]));
      // setLoader(false);
      setReduxloaded(true);
  }
}
  
  

    // setContainer((arr) => arr = [...abu]);
  }, [show, refresh,reduxloaded]);

  const requestSearch = (searchedVal) => {
    if (searchedVal == "") {
      setContainer(partnerBackupContainer);
      dispatch(partnerSearchUpdate(""))
    } else {
      const filteredRows = partnerBackupContainer.filter((row) => {
        return row.name.toLowerCase().includes(searchedVal.toLowerCase());
      });
      setContainer(filteredRows);
      setPage(0);
      dispatch(partnerSearchUpdate(searchedVal))
    }
  };

  const cancelSearch = () => {
    setContainer(partnerBackupContainer);
    setSearched("");
    dispatch(partnerSearchUpdate(""))
    // requestSearch(searched);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");

    setOrderBy(property);
  };

  function descendingComparator(obj1, obj2, orderBy) {
   
    return obj1[orderBy].localeCompare(obj2[orderBy]);
  }

  function getComparator(order, orderBy, obj1, obj2) {
    return order === "desc"
      ? descendingComparator(obj1, obj2, orderBy)
      : -descendingComparator(obj1, obj2, orderBy);
  }

  function stableSort(array, orderIs, orderBy) {
    const stabilizedThis = array.map((el, index) => {
      
      return [el, index];
    });
    stabilizedThis.sort((a, b) => {
      
      const order = getComparator(orderIs, orderBy, a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  return (
    
    <>
    {loader ? <>
      <Stack spacing={2} style={{marginTop:"70px",marginLeft:"20px"}}>
      {/* For variant="text", adjust the height via font-size */}
      <div
        style={{
          display: "flex",
          width: "640px",
          justifyContent: "space-between"
        }}
      >
        <Skeleton variant="rounded" width={130} height={40} />
        <Skeleton variant="rounded" width={200} height={40} />
      </div>
      <Skeleton variant="rounded" width={660} height={500} />
    </Stack>
    </> :
<>
      <Dialog
        open={deleteDialog}
        onClose={() => {
          setDeleteDialog(false);
          setDeleteItem({});
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure to delete <strong>{deleteItem.filename}</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            variant="outlined"
            sx={{
              border: "1px solid #0D0992",
              color: "#0D0992",
              borderRadius: "7px",
              fontSize: "13px",
            }}
            onClick={() => setDeleteDialog(false)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#0D0992",
              borderRadius: "7px",
              fontSize: "13px",
            }}
            onClick={() => {
              // setDeleteDialog(false);
              // deleteData("partners", deleteItem.id).then(() =>
              //   setRefresh(refresh + 1)
              // );
              dispatch(partnerDeleteThunk(deleteItem.id)).then(() => {
                dispatch(partnerThunk()).then(() => {

                  setRefresh(refresh + 1);
                  setDeleteDialog(false);
                })
              })
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        open={show}
        onClose={() => setShow(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            height: 350,
            bgcolor: "background.paper",
            border: "2px solid white",
            boxShadow: 24,

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
          }}
        >
          <CustomTextField
            setFunction={setPartnerName}
            labelName={"Partner Name"}
            onChange={true}
            refs={partnerRef}
            setErrorFunction={setErrorHelper}
          />

          <FormHelperText sx={{ color: "red", margin: 0 }}>
            {errorHelper}
          </FormHelperText>

          <Button
            variant="contained"
            onClick={() => {
              if (partnerName != "") {
                // addData({ name: partnerName }, "partners").then(() => {
                //   dispatch(partnerThunk()).then(() => {

                //     setErrorHelper("");
                //     setRefresh(refresh + 1);
                //     setShow(false);
                //   })
                // });
                dispatch(partnerAddThunk({name:partnerName})).then((res) => {
                  dispatch(partnerThunk()).then(() => {

                    setErrorHelper("");
                    setRefresh(refresh + 1);
                    setShow(false);
                  })
                })
              } else {
                setErrorHelper("Partner Field Can't Be Empty");
              }
            }}
            sx={{
              backgroundColor: "#0D0992",
              marginTop: "30px",
              width: "100px",
              borderRadius: "7px",
            }}
          >
            Save
          </Button>
        </Box>
      </Modal>

      <Modal
        open={editModal}
        onClose={() => setEditModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            height: 350,
            bgcolor: "background.paper",
            border: "2px solid white",
            boxShadow: 24,

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
          }}
        >
          <CustomTextField
            setFunction={setPartnerName}
            labelName={"Partner Name"}
            onChange={true}
            setErrorFunction={setErrorHelper}
            value={partnerName}
            width={"200px"}
          />
          <FormHelperText sx={{ color: "red" }}>{errorHelper}</FormHelperText>

          <Button
            variant="contained"
            onClick={() => {
              let decision = true;
              if (partnerName == "") {
                setErrorHelper("Partner Name Is Empty");
                decision = false;
              }
              if (decision) {
                // editData({ name: partnerName }, "partners", editId).then(() => {
                //   dispatch(partnerThunk()).then(() => {

                //     setRefresh(refresh + 1);
                //     setEditModal(false);
                //   })
                // });
                dispatch(partnerEditThunk([{name:partnerName},editId])).then(() => {
                  dispatch(partnerThunk()).then(() => {

                    setRefresh(refresh + 1);
                    setEditModal(false);
                  })
                })
              }
            }}
            sx={{
              backgroundColor: "#0D0992",
              marginTop: "30px",
              width: "100px",
              borderRadius: "7px",
            }}
          >
            Update
          </Button>
        </Box>
      </Modal>
<div style={{display:"flex",marginTop:"4%",justifyContent:'space-between'}}>
      <div
        style={{
          fontWeight: 600,
          fontSize: "20px",
          color: "#0D0992",
          marginLeft:"1.7%"
          
        }}
      >
        {(user[0].status != "admin") ? 
            <></>:
        <div>
          <Button
            variant="contained"
            onClick={() => {
              setErrorHelper("");
              setPartnerName("");
              setShow(true);
              const timeout = setTimeout(() => {
                partnerRef.current.focus();
              }, 100);
            }}
            sx={{
              backgroundColor: "#0D0992",
              fontWeight:600,
              borderRadius: "8px",
              width: "169px",
              minWidth:"160px",
              height: "37.5px",
            }}
          >
            Add Partner
          </Button>
          </div>
}
      </div>
      <div style={{ paddingRight:"56%" ,display:'flex'}}>
      <div>
                <TextField
              id="outlined-basic"
              size="small"
              value={searched}
              inputProps={{
                color: "rgba(0, 0, 0, 0.55)",
              }}
              label="Search"
              sx={{
                width: "230px",
                
                ".MuiOutlinedInput-root": {
                  borderRadius: "8px",
                  "&.Mui-focused fieldset": {
                    borderColor: "#C5C5C5",
                    borderWidth: "0.5px",
                    color: "rgba(0, 0, 0, 0.55)",
                  },
                },
                "& label.Mui-focused": {
                  color: "rgba(0, 0, 0, 0.55)",
                },
                backgroundColor: "white",
                color: "rgba(0, 0, 0, 0.55)",
              }}
              variant="outlined"
              onChange={(e) => {
                requestSearch(e.target.value);
                setSearched(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {searched != "" && (
                      <IconButton onClick={() => cancelSearch()}>
                        <CloseIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
                </div>
                
        </div>
        </div>
      <div style={{}}>
        {/* The Partner Add Button  */}
        
        <div style={{ display: "flex", }}>
          <div
            style={{
              width: "100%",

              
              borderRadius: "15px",
              
            }}
          >
            {/* Div for Table  */}
            <div
              style={{
                borderRadius: "15px",

                width: "700px",
                paddingLeft: "10px",
                paddingRight: "10px",
                
                paddingBottom: "40px",
              }}
            >
              <TableContainer
                component={Paper}
                sx={{
                  width: "100%",
                  marginLeft: "10px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "13px",
                  boxShadow: 2,
                  marginTop: "20px",
                }}
              >
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        sx={{ color: "grey", paddingLeft: "60px" }}
                      >
                        <TableSortLabel
                        active={orderBy === "name"}
                        direction={orderBy === "name" ? order : "asc"}
                        onClick={() => handleRequestSort("name")}
                      ><strong>Partner Name</strong></TableSortLabel>
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ color: "grey", paddingLeft: "60px" }}
                      ></TableCell>

                      <TableCell align="left"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stableSort(container, order, orderBy).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((value, index) => (
                      <React.Fragment key={index}>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            align="left"
                            sx={{ width: "80%", paddingLeft: "60px" }}
                          >
                            {value.name}
                          </TableCell>

                          <TableCell align="left" sx={{ width: "20%" }}>
                            {/* <Box sx={{display:"flex",justifyContent:"space-between"}}> */}
                            {(user[0].status != "admin") ? <></> :
                            <>
                            <IconButton
                              onClick={() => {
                                setEditId(value.id);
                                setPartnerName(value.name);

                                setEditModal(true);
                              }}
                            >
                              <EditIcon
                                sx={{ fontSize: "21px" }}
                              />
                            </IconButton>

                            <IconButton
                              onClick={() => {
                                setDeleteDialog(true);
                                setDeleteItem({
                                  id: value.id,
                                  filename: value.name,
                                });
                              }}
                            >
                              <DeleteIcon
                                sx={{ fontSize: "21px" ,marginLeft:"5%"}}
                              />
                            </IconButton>
                            </>
}
                            {/* </Box> */}
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                  <TableFooter>
                      <TableRow>
                        {partnerBackupContainer.length > 10 && 
                      <TablePagination
      
      count={container.length}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={[5,10,20,30]}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  }
    </TableRow>
    </TableFooter>
                </Table>
              </TableContainer>
            </div>
          </div>
          {/* <div
          style={{
            display: "flex",
            
            
            justifyContent:"end",
            border:"1px solid red",
            alignItems:"end",
            
            marginLeft: "auto",
            height: "80vh",
          }}
        >
         
          <div>
            <img src={countryadd} alt="end-of-country" />
          </div>
        </div> */}
        </div>
      </div>
      </>
  }
    </>
  );
}



export default PartnerAdd;
