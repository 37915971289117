import React,{useState,useEffect,useRef} from 'react'
import DashboardIndex from '../../Dashboard/DashboardIndex'
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import VerticalNav from "../../Navbar/VerticalNav";
import DashboardInfoBoxes from '../../Dashboard/DashboardInfoBoxes';

import ProfileMenu from '../../Profile/ProfileMenu';
import axios from 'axios';
import { addDays, startOfDay } from "date-fns";
import { useDispatch} from "react-redux";
import { connect } from "react-redux";
import { dashboardThunk } from '../../../reducers/request_api';
import LoadingBar from 'react-top-loading-bar';

const authToken = `JWT ${localStorage.getItem("access")}`;
// const authToken =
//   `JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjg0MTI0NTQ3LCJqdGkiOiJiMzU0NDA1NTIwNTM0NjI2YjJiOTJlNjNhZWQ1NDAyZiIsInVzZXJfaWQiOjF9.8UaqpXdPZYhCGIVCj2prvZ8ZmRp7d9x1LuS2OqVda6E`

const headers = {
  "content-type": "application/json",
  AUTHORIZATION: authToken,
};
const apiLink = process.env.REACT_APP_API_URL_DATA;


function Dashboard({stats}) {
    const [forselect,setSelect] = useState("Hello")
    const [dashboardContainer,setDashboardContainer] = useState([]);
    const [totalCampaign,setTotalCampaign] = useState("");
    const [budgetSpent,setBudgetSpent] = useState("");
    const [totalBudget,setTotalBudget] = useState("");
    const [liveCampaign,setLiveCampaign] = useState("");
    const [dashboardStats,setDashboardStats] = useState(null);
    const [latest_campaign,setLatestCampaign] = useState([]);

    const ref = useRef(null);
    
    

    const [state, setState] = useState([
      {
        startDate: new Date(),
        endDate: addDays(new Date(), 7),
        key: "selection",
      },
    ]);

    const dispatch = useDispatch();

    useEffect(() => {
      ref.current.continuousStart()

      function getPreviousDay(date = new Date()) {
        const previous = new Date(date.getTime());
        previous.setDate(date.getDate() - 7);
  
        return previous;
      }
      const doit = async () => {
      // let past7 = getPreviousDay(new Date());
      let date = new Date()
      let past7 = new Date(date.getFullYear(),date.getMonth(),1);
      let current = new Date();
  
      setState([
        {
          startDate: past7,
          endDate: current,
          key: "selection",
        },
      ]);
  
      let ddata = {
        startDate: past7.getFullYear() + "-" + ("0"+(past7.getMonth()+1)).slice(-2) + "-" + ("0" + past7.getDate()).slice(-2),
        endDate: current.getFullYear() + "-" + ("0"+(current.getMonth()+1)).slice(-2) + "-" + ("0" + current.getDate()).slice(-2),
      };
      
        // console.log("The value of data is :",data)
        await axios({
          method: "post",
          url: `${apiLink}dashboard_stats/`,
          headers: {
            "content-type": "application/json",
            AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
          },
          data: ddata,
        })
          .then((response) => {
            
            setTotalCampaign(response.data.total_campaigns);
            setLiveCampaign(response.data.live_campaigns)
            setBudgetSpent(response.data.budget_spent);
            setTotalBudget(response.data.total_budget);
            setLatestCampaign(response.data.latest_campaign)
            setDashboardStats(response.data)
          })
         
 
      

        // await axios
        //   .get(`${apiLink}dashboard_stats`, {
        //     // .get("http://localhost:8000/api/v1/partners", {
        //     headers: {
        //       "content-type": "application/json",
        //       AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        //     },
        //   })
        //   .then((response) => {
            
            
        //     setTotalCampaign(response.data.total_campaigns);
        //     setLiveCampaign(response.data.live_campaigns)
        //     setBudgetSpent(response.data.budget_spent);
        //     setTotalBudget(response.data.total_budget);
        //   })
        //   if (process.env.ENVIRONMENT === 'production') {

  


        
        // if(stats.length == 0){

        

        //   dispatch(dashboardThunk()).then((response) => {
            
        //     setTotalCampaign(response.payload.total_campaigns);
        //     setLiveCampaign(response.payload.live_campaigns)
        //     setBudgetSpent(response.payload.budget_spent);
        //     setTotalBudget(response.payload.total_budget);
        //   })
        // }
        // else{
        //   setTotalCampaign(stats.total_campaigns);
        //     setLiveCampaign(stats.live_campaigns)
        //     setBudgetSpent(stats.budget_spent);
        //     setTotalBudget(stats.total_budget);
        // }
      };
  
      doit();
      ref.current.complete()
      
    },[])

  return (
    <>
    
    <Grid container sx={{ height: "100vh" ,backgroundColor:"#F7F8FA"}}>
    <LoadingBar color="#0D0992" ref={ref} />
      <Grid item md={2} lg={2} >
      
        <VerticalNav />
      </Grid>
      <Grid item md={10} lg={10} sx={{ paddingLeft: 2, overflowY: "auto", height: "100%" }}>
      
      
        <ProfileMenu />
        {/* <div style={{display:"flex",justifyContent:"flex-end",width:"97%"}}>
       
      
                <div style={{marginTop:"10px"}}>
                <FormControl fullWidth>
                <InputLabel id="comapny-label">Select Country </InputLabel>
                <Select
                    size="medium"
                  labelId="comapny-label"
                  id="demo-simple-select"
                  
                  sx={{width: "300px",
                  borderRadius: "10px",
                  }}
                  label="Select Country"
                  
                  
                  
                  

                  >
                    
                  <MenuItem value={"Country 1"}>USA</MenuItem>
                  <MenuItem value={"Country 2"}>UK</MenuItem>
                  <MenuItem value={"Country 3"}>CANADA</MenuItem>
                </Select>
                </FormControl>
                </div>
        </div> */}
        
        <DashboardInfoBoxes totalCampaign={totalCampaign} totalBudget={totalBudget} liveCampaign={liveCampaign} />
        {(dashboardStats != null) &&<DashboardIndex state={state} setState={setState} dashboardStats={dashboardStats.click_chart} setDashboardStats={setDashboardStats} latest_campaign={latest_campaign} />}
        {/* <FeedMenu /> */}
        {/* <TableBox />  */}
      </Grid>
    </Grid>
 

 </>
  )
}

const mapStateToProps = state => ({
  stats: state.clickrequests.dashboard
})


export default connect(mapStateToProps)(Dashboard);