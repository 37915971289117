import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";

import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import React, { useState, useEffect } from "react";
import { IconButton, Input } from "@mui/material";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { rowSort, handleRequestSort } from "./rowSort";
import Dialog from "./Dialogs";

function TableBox({
  editStyle,
  tableHeaders,
  Container,
  SearchContainer,
  order,
  orderBy,
  setOrder,
  setOrderBy,
  editLink,
  deleteThunk,
}) {
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteItem, setDeleteItem] = useState({ filename: "", id: "" });
  const user = useSelector((state) => state.clickmatrix.user);
  const widthByColumns = `${100 / (tableHeaders.length + 1)}%`;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Dialog
        deleteDialog={deleteDialog}
        setDeleteDialog={setDeleteDialog}
        deleteItem={deleteItem}
        setDeleteItem={setDeleteItem}
        deleteThunk={deleteThunk}
      />

      <Paper
        sx={{
          width: "97%",
          marginLeft: "10px",
          backgroundColor: "#FFFFFF",
          borderRadius: "13px",
          border: "0.5px solid #C5C5C5",
          marginTop: "20px",
        }}
      >
        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "13px",
            border: "0.5px solid #C5C5C5",
          }}
        >
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                {tableHeaders.map((value, index) => {
                  // Making the headers values look good
                  let styledValue =
                    value
                      .split(/[-_\.]/)[0]
                      .charAt(0)
                      .toUpperCase() + value.split(/[-_\.]/)[0].slice(1);
                  return (
                    <TableCell
                      align="center"
                      key={index}
                      sx={{
                        paddingTop: "20px",
                        paddingBottom: "20px",
                        color: "rgba(0, 0, 0, 0.7)",
                        width: `${widthByColumns}`,
                        fontSize: "16px",
                      }}
                    >
                      <TableSortLabel
                        active={orderBy === value}
                        direction={orderBy === value ? order : "asc"}
                        onClick={() =>
                          handleRequestSort(
                            value,
                            order,
                            setOrder,
                            orderBy,
                            setOrderBy
                          )
                        }
                      >
                        <strong>{styledValue}</strong>
                      </TableSortLabel>
                    </TableCell>
                  );
                })}

                <TableCell
                  align="center"
                  sx={{
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    color: "rgba(0, 0, 0, 0.7)",
                  }}
                >
                  {" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowSort(Container, order, orderBy, rowsPerPage, page).map(
                (value, index) => (
                  <React.Fragment>
                    <TableRow key={index}>
                      {tableHeaders.map((header) => (
                        <>
                          <TableCell
                            align="center"
                            sx={{
                              paddingTop: "30px",
                              paddingBottom: "30px",
                              paddingRight: "30px",
                              color: "rgba(0, 0, 0, 0.55)",
                              
                              fontSize: "16px",
                            }}
                          >
                            <strong>
                              {typeof value[header] === "object" ? (
                                <Typography sx={{maxHeight:"100px",overflowY:"auto"}}>
                                  {value[header].map((item, itemIndex) => (
                                    <span
                                      key={itemIndex}
                                      style={{
                                        color: "rgba(0, 0, 0, 0.55)",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {item}
                                      {itemIndex < value[header].length - 1 &&
                                        ", "}
                                    </span>
                                  ))}
                                </Typography>
                              ) : (
                                value[header]
                              )}
                            </strong>
                          </TableCell>
                        </>
                      ))}
                      <TableCell
                        align="center"
                        sx={{
                          color: "rgba(0, 0, 0, 0.55)",
                        }}
                      >
                        {user[0].status !== "admin" ? (
                          <></>
                        ) : (
                          editStyle == 'link' ?
                        
                          <Link to={`${editLink}/${value.id}`}>
                          <IconButton
                            aria-label="expand-row"
                            size="small"
                            
                          >
                            <BorderColorIcon size="small" />
                          </IconButton>
                          </Link>
                          :
                          <IconButton
                          aria-label="expand-row"
                          size="small"
                          onClick={() => editLink(value)}
                        >
                          <BorderColorIcon size="small" />
                        </IconButton>
                        )
                      }
                         

                        {user[0].status != "admin" ? (
                          <></>
                        ) : (
                          <IconButton
                            aria-label="expand-row"
                            size="small"
                            sx={{ marginLeft: "5%" }}
                            onClick={() => {
                              setDeleteItem({
                                id: value.id,
                                // filename: value.filename,
                              });
                              setDeleteDialog(true);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                )
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                {SearchContainer.length > 10 && (
                  <TablePagination
                    count={Container.length}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 20, 30]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                )}
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

export default TableBox;
