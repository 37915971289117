import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   BarElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';
import { Bar } from "react-chartjs-2";

const authToken = `JWT ${localStorage.getItem("access")}`;
// const authToken =
//   `JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjg0MTI0NTQ3LCJqdGkiOiJiMzU0NDA1NTIwNTM0NjI2YjJiOTJlNjNhZWQ1NDAyZiIsInVzZXJfaWQiOjF9.8UaqpXdPZYhCGIVCj2prvZ8ZmRp7d9x1LuS2OqVda6E`

const headers = {
  "content-type": "application/json",
  AUTHORIZATION: authToken,
};

const apiLink = process.env.REACT_APP_API_URL_DATA;

function BarChart({ globalCpc,clickstats,labelContainer,expiredContainer,foreignContainer,totalContainer,botContainer,duplicateContainer,partnerContainer,validContainer,setlabelContainer,setexpiredContainer,setforeignContainer,settotalContainer,setbotContainer,setduplicateContainer,setpartnerContainer,setvalidContainer,dashboardstats=false }) {
  
  const [display, setDisplay] = useState(false);
  const barRef = useRef(true);

  useEffect(() => {
    const getData = async () => {
      
      if (clickstats.length !== 0) {
        
       
      clickstats.map((value) => {
        var valid_clicks = 0;
        var rev = 0;
        if(dashboardstats){
          valid_clicks = value.valid_clicks;
          rev = value.partner_revenue
          setpartnerContainer((arr) => [...arr, rev]);
          setvalidContainer((arr) => [...arr, valid_clicks]);
        }
        else{

          valid_clicks = value.total_clicks - (value.foreign_clicks+value.duplicate_clicks+value.bot_clicks+value.expired_clicks);
          rev = globalCpc * valid_clicks;
          setpartnerContainer((arr) => [...arr, rev]);
          setvalidContainer((arr) => [...arr, valid_clicks]);
          
        }
          
          if(!labelContainer.includes(value.date) ){
           
            setlabelContainer((arr) => [...arr, value.date]);
          }
          setforeignContainer((arr) => [...arr, value.foreign_clicks]);
          setexpiredContainer((arr) => [...arr, value.expired_clicks]);
          setduplicateContainer((arr) => [...arr, value.duplicate_clicks]);
          setbotContainer((arr) => [...arr, value.bot_clicks]);
          settotalContainer((arr) => [...arr, value.total_clicks]);
          // setpartnerContainer((arr) => [...arr, rev]);
          // setvalidContainer((arr) => [...arr, valid_clicks]);
          
        });
        
      };
    }
    getData();
    setDisplay(true)
  }, [clickstats]);

  var dataconfig = {
    labels: labelContainer,

    // labels: ["0",10],

    datasets: [
      {
        type: "line",
        label: "Revenue",
        data: partnerContainer,
        fill: false,
        borderColor: "#fbb13c",
        backgroundColor: "#fbb13c",
      },
      {
        label: "Bot Clicks",
        data: botContainer,
        fill: true,
        backgroundColor: "#03045e",
      },
      {
        label: "Foreign Clicks",
        data: foreignContainer,
        fill: true,
        backgroundColor: "#0077b6",
      },
      {
        label: "Expire Clicks",
        data: expiredContainer,
        fill: true,
        backgroundColor: "#00b4d8",
      },
      {
        label: "Duplicate Clicks",
        data: duplicateContainer,
        fill: true,
        backgroundColor: "#90e0ef",
      },
      {
        label: "Total Clicks",
        data: totalContainer,
        fill: true,
        backgroundColor: "#caf0f8",
      },
      {
        label: "Valid Clicks",
        data: validContainer,
        fill: true,
        backgroundColor: "#73d2de",
      },

    
    ],
  };

  var options = {
    plugins: {
      title: {
        display: true,
        text: "Campaign Bar",
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        
        title: function (tooltipItems, data) {
          return data.labels[tooltipItems[0].index];
        },
        label: function (tooltipItem, data) {
          // return data.datasets[0]
          // return 'id: ' + tooltipItems.yLabel+' | '+' age: '+tooltipItems.xLabel
          let plex = [];
          var value =
            data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          var label = data.datasets[tooltipItem.datasetIndex].label;
          // data.datasets.map((value)=> {

          //     let v = value.data[tooltipItem.index];
          //     let l = value.label;
          //     plex.push(l + "------" + v);
          // })
          return label + " ------ " + value;
        },
      },
    },

    legend: {
      display: false, // <- the important part
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          barPercentage: 0.4,
          ticks: {
            beginAtZero: true,
            min: 0,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            min: 0,
          },
        },
      ],
    },
  };

  return (
    <div style={{ width: "95%", height: "330px" }}>
      {(display) ? <Bar  data={dataconfig} options={options} /> :<></>}
    </div>
  );
}

export default BarChart;
