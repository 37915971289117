import React from 'react'
import homesolutionbg from "../../assets/home-solution-bg-3.png"
import campaignhome from "../../assets/campaign-home-new.png";

function HomeSolution() {
  return (
     <>
     <div style={{
          backgroundImage: `url(${homesolutionbg})`,
          height:"100vh",
          backgroundSize: "100% 100%",
        }}>

        <div style={{display:'flex'}}>
            <div style={{display:"flex",flexDirection:"column",marginTop:"140px",marginLeft:"166px"}}>
                <div style={{fontWeight:700,fontSize:"64px",lineHeight:"77px",letterSpacing:"0.03em"}}><span style={{color:"#A5F7A8"}}>Absolute Job</span><br /> <span style={{color:"#A5F7A8"}}>Advertising</span><br /> <span style={{color:"white"}}>Solution</span></div>
                <div style={{color:"white",fontWeight:400,fontSize:"32px",lineHeight:"39px",marginTop:"50px"}}>
                Our All in One job advertising solution<br />
                cover all your needs at one place.<br />
                Recrutiment optimized Bot/Spam<br />
                Protection makes sure your ads are in<br />
                front of real candidates and help you<br />
                identify the faulty traffic source.
                </div>
            </div>
            <div style={{marginLeft:"270px",marginTop:"270px"}}>
                <img src={campaignhome} />
            </div>

        </div>

     </div>
     </>
  )
}

export default HomeSolution