import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import Paper from "@mui/material/Paper";
import { getData } from "../helpers/helpers";
import { useDispatch } from "react-redux";
import { notificationThunk } from "../../reducers/request_api";

function NotificationIndex() {
  const [container, setContainer] = useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);

  const dispatch = useDispatch();

  useEffect(() => {
    const displayLoader = async () => {
      // getData(setContainer,"notifications");
      dispatch(notificationThunk()).then((response) => {
       

        setContainer([...response.payload]);
      });

    };

    displayLoader();

    
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div style={{display:"flex",width:"90%",justifyContent:"space-between"}}>
    <TableContainer
      component={Paper}
      sx={{
        width: "40%",
        marginLeft: "10px",
        backgroundColor: "#f7fff7",
        borderRadius: "13px",
        
        border: "1px solid rgba(0, 0, 0, 0.15)",
        marginTop: "20px",
      }}
    >
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left" sx={{ color: "grey", paddingLeft: "10px" }}>
              <strong>Date Time</strong>
            </TableCell>
            <TableCell align="left" sx={{ color: "grey", paddingLeft: "60px" }}>
              <strong>Message</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {container
            
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((value, index) => (
              <React.Fragment key={index}>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{value.date_time}</TableCell>
                  <TableCell>{value.message}</TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={container.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[20]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
    {/* <TableContainer
      component={Paper}
      sx={{
        width: "40%",
        marginLeft: "10px",
        backgroundColor: "#fff5f5",
        borderRadius: "13px",
        
        border: "1px solid rgba(0, 0, 0, 0.15)",
        marginTop: "20px",
      }}
    >
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <TableCell align="left" sx={{ color: "grey", paddingLeft: "10px" }}>
              <strong>Date Time</strong>
            </TableCell>
            <TableCell align="left" sx={{ color: "grey", paddingLeft: "60px" }}>
              <strong>Message</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {container
            .reverse()
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((value, index) => (
              <React.Fragment key={index}>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{value.date_time}</TableCell>
                  <TableCell>{value.message}</TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={container.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[20]}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer> */}
    </div>
  );
}

export default NotificationIndex;
