import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import axios from "axios";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";

import { useDispatch, useSelector } from "react-redux";

import Paper from "@mui/material/Paper";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import React, { useState, useEffect } from "react";
import { IconButton, Input } from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CircularProgress from "@mui/material/CircularProgress";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { Box } from "@mui/system";
import TableRowData from "./CampaignTableRowData";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import SearchBar from "material-ui-search-bar";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import FormHelperText from "@mui/material/FormHelperText";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GenericTableBox from "../GenericTableBox";
import { connect } from "react-redux";
import { userDeleteThunk,campaignDeleteThunk, mastercampaignThunk,publisherThunk ,campaignThunk,mastercampaignEditThunk,masterCampaignDeleteThunk} from "../../reducers/request_api";
import {
   
  
  Chip
} from "@mui/material";
import {
  getData,
  getData2,
  addData,
 
 
  CampaignStatusRequest,
} from "../helpers/helpers";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import "./campaignToggle.css";
import "../Loader/Loader.css";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { campaignSearchUpdate } from "../../reducers/request_api";

import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import LastThreeMonths from "../LastThreeMonths";
import Modal from "@mui/material/Modal";
import NewTextField from "../NewTextField";

const authToken = `JWT ${localStorage.getItem("access")}`;

// const authToken =
//   `JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjg0MTI0NTQ3LCJqdGkiOiJiMzU0NDA1NTIwNTM0NjI2YjJiOTJlNjNhZWQ1NDAyZiIsInVzZXJfaWQiOjF9.8UaqpXdPZYhCGIVCj2prvZ8ZmRp7d9x1LuS2OqVda6E`

const headers = {
  "content-type": "application/json",
  AUTHORIZATION: authToken,
};
const apiLink = process.env.REACT_APP_API_URL_DATA;

function TableBox({
 
  setLiveCount,
  setBudget,
  setSpentBudget,
  setTotalCount,
  
}) {

  const campaigns = useSelector((state) => state.clickrequests.campaigns)
  const campaignSearchValue =  useSelector((state) => state.clickrequests.campaignSearch)
  const user =  useSelector((state) => state.clickmatrix.user)


  const dispatch = useDispatch();

  const navigate = useNavigate();
  // const [loader, setLoader] = useState(true);
  const [statsContainer, setStatsContainer] = useState("");
  const [open, setOpen] = useState(-1);
  const [masterCampaignContainer, setMasterCampaignContainer] = useState([]);
  const [masterCampaignSearchContainer, setMasterCampaignSearchContainer] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [budgetSpent, setBudgetSpent] = useState("");
  const [campaignUrl, setCampaignUrl] = useState("Campaign.com");

  const [publisherContainer, setPublisherContainer] = useState([]);
  const [publisherId, setPublisherId] = useState("");
  const [trafficErrorState, setTrafficError] = useState("");

  const [selectedCampaigns,setSelectedCampaigns] = useState([]);
  const [campaignContainer,setCampaignContainer] = useState([]);
  const [campaignselectedError,setCampaignSelectedError] = useState("")

  const [errorDialog, setErrorDialog] = useState({
    status: false,
    campaign_id: "",
  });

  const [editedId,setEditedId] = useState("");
  

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteItem, setDeleteItem] = useState({ filename: "", id: "" });
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("filename");
  const [loadStatus, setLoadStatus] = useState(false);
  const [reduxloaded, setReduxloaded] = useState(false);
  const [show,setShow] = useState(false);
  const [campaignName, setCampaignName] = useState([]);
  const [campaignErrorState, setCampaignError] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [searched, setSearched] = useState("");
  const [currency,setCurrency] = useState("");
  const [masterFilename, setMasterFilename] = useState({
    interact: false,
    value: "",
    error: "",
  });
  
  const handleModal = (data) => {
    setPublisherId(data.publisher)
    setCampaignName(data.filename)
    setEditedId(data.id)
    setShow(true)
    console.log("The data in handleModal is ",data)
    
  }

  

    useEffect(() => {
        dispatch(mastercampaignThunk()).then((response) => {
            setMasterCampaignContainer(response.payload)
        })
        dispatch(publisherThunk()).then((response) => {
        
          setPublisherContainer(response.payload);
        });
        dispatch(campaignThunk()).then((response) => {
          setCampaignContainer(response.payload)
      }) 
    },[])



  return (
    <>
      
      <Modal
            open={show}
            onClose={() => setShow(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 500,
                height: 400,
                bgcolor: "white",
                border: "2px solid white",
                boxShadow: 24,
                p: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontWeight: 600,
                  fontSize: "18px",
                  color: "#0D0992",
                }}
              >
                Master Campaign Edit
              </div>
              <div style={{ marginLeft: "5px",marginTop:"20px" }}>
              <Input
                placeholder="Enter Filename"
                disableUnderline={true}
                value={campaignName}
                onChange={(e) => {
                  setCampaignName(e.target.value);
                  if (e.target.value == "") {
                    setCampaignError("Field Is Empty");
                  } else {
                    setCampaignError("");
                  }
                }}
                sx={{
                  border: "0.5px solid #C5C5C5",
                  backgroundColor: "white",
                  width: "300px",
                  borderRadius: "10px",

                  paddingLeft: "15px",
                  paddingBottom: "5px",
                  input: {
                    color: "black",
                    fontWeight: "400",
                    "&::placeholder": {
                      color: "#212121",
                      opacity: 0.8,
                    },
                  },
                }}
              />
              <FormHelperText sx={{ color: "red" }}>
                {/* {campaignErrorState} */}
              </FormHelperText>
            </div>
              
            <div style={{ marginLeft: "5px",marginTop:"20px" }}>
              <div style={{ display: "flex" }}>
                <FormControl size="small">
                  <InputLabel id="partner-label">Select Publisher</InputLabel>
                  <Select
                    size="small"
                    labelId="partner-label"
                    id="demo-simple-select"
                    value={publisherId}
                    onChange={(e) => {
                      setPublisherId(e.target.value);
                    }}
                    sx={{
                        width: "300px",
                      borderRadius: "7px",
                      "& fieldset": {
                        borderColor: "#C5C5C5",
                        borderWidth: "0.5px",
                      },
                      backgroundColor: "white",
                    }}
                    label="Select publisher"
                  >
                    {publisherContainer.map((value) => (
                      <MenuItem value={value.id} key={value.user_id}>
                        {value.user_name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText sx={{ color: "red" }}>
                    {trafficErrorState}
                  </FormHelperText>
                </FormControl>
              </div>
            </div>

            <div style={{ display: "flex",marginTop:"20px" }}>
                <FormControl size="small">
                  <InputLabel id="partner-label">Campaigns</InputLabel>
                  <Select
                    
                    multiple
                    value={selectedCampaigns}
                    labelId="partner-label"
                    id="demo-simple-select"
                    renderValue={(selected) => (
                        <Stack gap={1} direction="row" flexWrap="wrap">
                          {selected.map((value) => (
                            <Chip key={value.id} label={value.filename} />
                          ))}
                        </Stack>
                      )}
                    onChange={(e) => {
                      setSelectedCampaigns(e.target.value);
                    }}
                    sx={{
                      width: "300px",
                      
                      borderRadius: "7px",
                      "& fieldset": {
                        borderColor: "#C5C5C5",
                        borderWidth: "0.5px",
                      },
                      backgroundColor: "white",
                    }}
                    label="Select Campaigns"
                  >
                    {campaignContainer.map((value) => (
                      <MenuItem value={value} key={value.id}>
                        {value.filename}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText sx={{ color: "red" }}>
                    {campaignselectedError}
                  </FormHelperText>
                </FormControl>
              </div>
              
              <Button
            variant="contained"
            onClick={async () => {
              let decision = true;

              if (publisherId == "" || publisherId == null) {
                setTrafficError("This Field Should Be Selected");
                decision = false;
              }
              
              if (selectedCampaigns.length == 0) {
                
                setCampaignSelectedError("Campaign should be selected");
                decision = false;
              }
              if(campaignName == ""){
                setCampaignError("Filename should not be empty");
                decision = false;
              }
           
            
              const data = {
               campaigns:selectedCampaigns,
               publisher:publisherId,
               filename:campaignName
              };
           

              if (decision) {
                console.log("The data is ",data)
                dispatch(mastercampaignEditThunk([data,editedId])).then((res) => {
                  navigate("/campaigns")
                    
                  // dispatch(campaignThunk()).then((res) => navigate("/campaigns"))
                });
              }
            }}
            sx={{
              borderRadius: "10px",
              backgroundColor: "#0D0992",
              width: "100px",
            }}
          >
            Update
          </Button>
            </Box>
          </Modal>
     
      <div style={{marginLeft:"10px",marginTop:"60px"}}>
                <Link to="/master-campaign/new">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#0D0992",
                      borderRadius: "8px",
                      width: "222px",
                      height: "36px",
                    }}
                  >
                    <strong>New Master Campaign</strong>
                  </Button>
                </Link>
              </div>
        <>
         <GenericTableBox
            tableHeaders={["id", "filename","total_clicks","foreign_clicks","duplicate_clicks","bot_clicks",'campaign_names']}
            // editLink={"/user/edit"}
            editStyle={"Modal"}
            editLink={handleModal}
            deleteThunk={masterCampaignDeleteThunk}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            Container={masterCampaignContainer}
            SearchContainer={masterCampaignSearchContainer}
          />
        </>
    
    </>
  );
}
 



export default TableBox;
