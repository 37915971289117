import React,{useState,useEffect} from "react";
import Chart from "./Chart";
import DoughnutHalf from "./DoughnutHalf";
import dashboardBanner from "../../assets/dashboard-banner.png";
import BarChart from "../Charts/Barchart";
import CloseIcon from "@mui/icons-material/Close";
import axios from 'axios';
import { DateRangePicker } from "react-date-range";
import { addDays, startOfDay } from "date-fns";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import CircularProgress from '@mui/material/CircularProgress';
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton, Input } from "@mui/material";
import { Collapse } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";

const authToken = `JWT ${localStorage.getItem("access")}`;
const headers = {
  "content-type": "application/json",
  AUTHORIZATION: authToken,
};
const apiLink = process.env.REACT_APP_API_URL_DATA;

function DashboardIndex({dashboardStats,state,setState,setDashboardStats,latest_campaign}) {
  
  const [labelContainer, setLabelContainer] = useState([]);
  const [foreignContainer, setForeignContainer] = useState([]);
  const [validContainer, setValidContainer] = useState([]);
  const [duplicateContainer, setDuplicateContainer] = useState([]);
  const [expiredContainer, setExpiredContainer] = useState([]);
  const [botContainer, setBotContainer] = useState([]);
  const [totalContainer, setTotalContainer] = useState([]);
  const [partnerContainer, setPartnerContainer] = useState([]);

  const [open, setOpen] = useState(-1);

  const [dashboardContainer,setDashboardContainer] = useState([]);
  const [dashboardSearchContainer,setDashboardSearchContainer] = useState([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  


  
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const handleFilter = async (a) => {
    let startDate = a[0].startDate;
    let endDate = a[0].endDate;

    await axios({
      method: "post",
      url: `${apiLink}dashboard_stats/`,
      headers: {
        "content-type": "application/json",
        AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
      },
      data: {
        startDate: startDate.getFullYear() + "-" + ("0"+(startDate.getMonth()+1)).slice(-2) + "-" + ("0" + startDate.getDate()).slice(-2),
        endDate: endDate.getFullYear() + "-" + ("0"+(endDate.getMonth()+1)).slice(-2) + "-" + ("0" + endDate.getDate()).slice(-2),
      },
    })
      .then((response) => {
        
        setState([
          {
            startDate: startDate,
            endDate: endDate,
            key: "selection",
          },
        ]);
            setLabelContainer( []);
            setForeignContainer([]);
            setExpiredContainer([]);
            setDuplicateContainer([]);
            setBotContainer([]);
            setTotalContainer([]);
            setPartnerContainer([]);
            setValidContainer([]);
            
            setDashboardStats(response.data)
            
            
      })
      if (process.env.ENVIRONMENT === 'production') {
console.log()
}
 

  };

  return (
    <>
     
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex",marginTop:"2%"}}>
          <div style={{color:"#0D0992",fontWeight:600,marginLeft:"1%"}}>Total Clicks</div>
          <div style={{color:"#0D0992",fontWeight:600,marginLeft:"45%"}}>Date Selection</div>
        </div>
        <div style={{ display: "flex",marginTop:"2%" }}>
          <div style={{ width: "49%",marginLeft:"1%",paddingTop:"20px",paddingLeft:"20px",backgroundColor: "#FFFFFF",
                    border:"0.5px solid #C5C5C5",borderRadius:"13px"}}>
            
              {/* <Chart /> */}
              {(dashboardStats.length  != 0) && <BarChart clickstats={dashboardStats} dashboardstats={true} labelContainer={labelContainer} setlabelContainer={setLabelContainer} foreignContainer={foreignContainer} setforeignContainer={setForeignContainer} expiredContainer={expiredContainer} setexpiredContainer={setExpiredContainer} duplicateContainer={duplicateContainer} setduplicateContainer={setDuplicateContainer} totalContainer={totalContainer} settotalContainer={setTotalContainer} partnerContainer={partnerContainer} setpartnerContainer={setPartnerContainer} validContainer={validContainer} setvalidContainer={setValidContainer} botContainer={botContainer} setbotContainer={setBotContainer} /> }
           
          </div>
          <div
              style={{
                marginLeft: "4%",
                paddingLeft: "10px",
                paddingBottom: "10px",
                backgroundColor: "white",
                borderRadius: "10px",
                border: "0.5px solid #C5C5C5 ",
              }}
            >
              {/* <div
                style={{
                  marginLeft: "1%",
                  position: "relative",
                  top: "90%",
                  left: "10%",
                  width: "110px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{ width: "100px", height: "30px" }}
                  onClick={() => handleFilter()}
                >
                  Filter
                </Button>
              </div> */}
              <DateRangePicker
                onChange={(item) => {
                  setState([item.selection]);
                  handleFilter([item.selection])
                }}
               
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                
                ranges={state}
                direction="horizontal"
                customCloseIcon={<CloseIcon />}
              />
            </div>
        </div>
      </div>

      <div style={{color:"#0D0992",fontWeight:600,marginLeft:"1%",marginTop:"2%"}}>Latest Clicks</div>

      {/* The div for Table  */}
      <div>
      <Paper
            sx={{
              width: "97%",
              marginLeft: "10px",
              backgroundColor: "#FFFFFF",
              borderRadius: "13px",
              border: "0.5px solid #C5C5C5",
              marginTop: "20px",
            }}
          >
           
            <TableContainer
              component={Paper}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "13px",
                border: "0.5px solid #C5C5C5",
              }}
            >
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{
                        paddingTop: "10px",
                        paddingBottom: "10px",
                        color: "grey",
                        
                        paddingLeft: "45px",
                      }}
                    >
                      
                      <strong>Filename</strong>
                      
                      </TableCell>
                    <TableCell align="center" sx={{ color: "grey" }}>
                      
                        <strong>Total Clicks</strong>
                      
                    </TableCell>
                    <TableCell align="center" sx={{ color: "grey" }}>
                    
                      <strong>Foreign Clicks</strong>
                      
                    </TableCell>
                    <TableCell align="center" sx={{ color: "grey" }}>
                      
                        <strong>Duplicate Clicks</strong>
                     
                    </TableCell>
                    <TableCell align="center" sx={{ color: "grey" }}>
                     
                        <strong>Expired Clicks</strong>
                      
                    </TableCell>
                    <TableCell align="center" sx={{ color: "grey" }}>
                     
                        <strong>Bot Clicks</strong>
                      
                    </TableCell>
                    <TableCell align="center" sx={{ color: "grey" }}>
                      
                        <strong>Valid Clicks</strong>
                     
                    </TableCell>

                    <TableCell align="center"> </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                 
                  {latest_campaign
                    .map((value, index) => (
                      
                      <React.Fragment key={index}>
                        
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            align="left"
                            sx={{
                              width: "10%",
                              paddingTop: "40px",
                              paddingBottom: "30px",
                              paddingLeft: "40px",
                            }}
                          >
                          
                            
                            {value.filename}

                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              width: "15%",
                              paddingTop: "30px",
                              paddingBottom: "30px",
                              color: "rgba(0, 0, 0, 0.55)",
                            }}
                          >
                           {value.total_clicks}
                          </TableCell>
                          <TableCell align="center" sx={{ width: "120px" }}>
                            {value.foreign_clicks}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "rgba(0, 0, 0, 0.55)" }}
                          >
                           {value.duplicate_clicks}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "rgba(0, 0, 0, 0.55)" }}
                          >
                           {value.expired_clicks}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "rgba(0, 0, 0, 0.55)" }}
                          >
                           {value.bot_clicks}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{ color: "rgba(0, 0, 0, 0.55)" }}
                          >
                           {value.valid_clicks}
                          </TableCell>
                          {/* <TableCell
                      align="center"
                      sx={{ width: "10%",  }}
                    >
                      0.00
                    </TableCell> */}
                          
                        </TableRow>

                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0,backgroundColor:"#F7F8FA" }}
                            colSpan={8}
                          >
                           
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    { dashboardSearchContainer.length > 10 &&
                    <TablePagination
                      count={dashboardContainer.length}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[5, 10, 20, 30]}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
}
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Paper>
      </div>
      {/* <div
        style={{
          
          
          
          height: "200px",
          
          width:"97%",
          marginTop:"8%",
          borderRadius:"10px"
        }}
      >
        <img src={dashboardBanner} style={{width:"100%",height:"100%"}}/>
      </div> */}
    </>
  );
}

export default DashboardIndex;
