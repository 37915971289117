import React from "react";
import Box from "@mui/material/Box";
import PieChartIcon from '@mui/icons-material/PieChart';
import PersonIcon from '@mui/icons-material/Person';
import CampaignIcon from '@mui/icons-material/Campaign';

function CampaignInfoBoxes({totalCampaign,totalBudget,liveCampaign}) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          marginTop: "3%",
          borderRadius: "13px",
          width:"97%",
          height: "15%",
          maxHeight: "100px",
          alignItems:"center",
          backgroundColor: "#FFFFFF",
          border:"0.5px solid #C5C5C5",
          marginBottom:"3%",
          marginLeft:"0.7%",
          
          justifyContent:"space-around"
        }}
      >

        <div style={{display:"flex",order:1}}>
            <div><div style={{backgroundColor:"#EDE8FF",width:"50px",height:"50px",borderRadius:"10%"}}><CampaignIcon sx={{marginLeft:"12px",marginTop:"12px",color:"#551FFF"}} /></div></div>
            <div style={{display:"flex",flexDirection:"column"}}>
              <div style={{paddingLeft:"15px",color:"rgba(0, 0, 0, 0.5)"}}>Total Campaigns</div>
              <div style={{paddingLeft:"55px",color:"rgba(0, 0, 0, 0.5)",paddingTop:"5px"}}><strong>{totalCampaign}</strong></div>
            </div>
        </div>
        <div style={{display:"flex",order:2}}>
            <div><div style={{backgroundColor:"#EAF9FF",width:"50px",height:"50px",borderRadius:"10%"}}><PersonIcon sx={{marginLeft:"12px",marginTop:"12px",color:"#00B7FE"}} /></div></div>
            <div style={{display:"flex",flexDirection:"column"}}>
              <div style={{paddingLeft:"15px",color:"rgba(0, 0, 0, 0.5)"}}>Live Campaigns</div>
              <div style={{paddingLeft:"20px",color:"rgba(0, 0, 0, 0.5)",paddingTop:"5px"}}><strong>{liveCampaign}</strong></div>
            </div>
        </div>
        <div style={{display:"flex",order:3}}>
            <div><div style={{backgroundColor:"#FFEBEF",width:"50px",height:"50px",borderRadius:"10%"}}><PieChartIcon sx={{marginLeft:"12px",marginTop:"12px",color:"#FD2254"}} /></div></div>
            <div style={{display:"flex",flexDirection:"column"}}>
              <div style={{paddingLeft:"15px",color:"rgba(0, 0, 0, 0.5)"}}>Total Budget</div>
              <div style={{paddingLeft:"55px",color:"rgba(0, 0, 0, 0.5)",paddingTop:"5px"}}><strong>{totalBudget}</strong></div>
            </div>
        </div>
       

      </Box>
    </>
  );
}

export default CampaignInfoBoxes;
