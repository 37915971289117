import React from 'react'
import homesolutionbg from "../../assets/home-solution-bg-3.png"
import robottalk from "../../assets/robottalking.png"
function HomeVision() {
  return (
     <> 
        <div style={{
          backgroundImage: `url(${homesolutionbg})`,
          height:"100vh",
          backgroundSize: "100% 100%",
        }}>
            <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                <div style={{marginTop:"150px",fontWeight:700,fontSize:"80px",lineHeight:"97px",background:"radial-gradient(50% 50% at 50% 50%, #A5F7A8 22.09%, #5E59FD 100%)",WebkitTextFillColor:"transparent",WebkitBackgroundClip:"text"}}>Our Vision</div>
                <div style={{color:"white",marginTop:"50px",fontWeight:400,fontSize:"32px",lineHeight:"39px",letterSpacing:"0.03em"}}>Our vision is to revolutionize the job recruitment industry by <br /> 
                providing exceptional services like <span style={{color:"#A5F7A8"}}>Top-notch</span> website monitoring<br />
                and <span style={{color:"#A5F7A8"}}>bot detection</span> tools,<span style={{color:"#A5F7A8"}}>Real-time alerts</span> and <span style={{color:"#A5F7A8"}}>customizable reports</span><br />
                for effortless integration.Our goal is to become a trusted partner<br />
                for companies looking for innovative and effective solutions that<br />
                drive sustainable growth.</div>
                <div style={{position:"relative",left:"500px"}}>
                <img src={robottalk}/>
            </div>
            </div>
            
        </div>
     </>
  )
}

export default HomeVision