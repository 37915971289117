import React,{useState} from 'react'
import clickprofile from "../../assets/cmatrix-profile-default.jpg";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import {ClickActions} from '../../reducers/auth';
import {Link} from "react-router-dom";
import { useDispatch } from 'react-redux';

function PublisherProfile({logout}) {
   const [anchorEl, setAnchorEl] = React.useState(null);
   const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
     <>
        <div style={{display:"flex",justifyContent:"flex-end",paddingTop:"10px",paddingRight:"50px"}}>
        

        <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <div><img src={clickprofile} alt="user-is" style={{width:"60px",height:"60px",borderRadius:"50%"}} /></div>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        transformOrigin={{
         vertical: 'top',
         horizontal: 'left',
       }}
      >
        
        <Link to="/login"><MenuItem onClick={() => dispatch(ClickActions.logout())}>Logout</MenuItem></Link>
        
      </Menu>
      </div>
     </>
  )
}

const mapStateToProps = state => ({
  isAuthenticated: state.clickmatrix.isAuthenticated
})



export default connect(mapStateToProps)(PublisherProfile)