import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";

import IconButton from "@mui/material/IconButton";

import ControlPointIcon from "@mui/icons-material/ControlPoint";

import Button from "@mui/material/Button";
import CustomTextField from "../CustomTextField";
import { getData, addData } from "../helpers/helpers";
import axios from "axios";
import InputAdornment from "@mui/material/InputAdornment";
import RemoveIcon from "@mui/icons-material/Remove";
import { useDispatch,useSelector} from "react-redux";
import { connect } from "react-redux";
import { tagMapThunk, tagmapAddThunk } from "../../reducers/request_api";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";

const authToken =
  "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjgxODgxNDE4LCJqdGkiOiJkYTBjZTlkMmE2NjM0ZWE1OTcxNWIzZjg3OTNkMDdiZCIsInVzZXJfaWQiOjN9.m_EAPPaxp3g18XL4MH-9RKB8I06yvoM-jZkKCynPOnE";

const headers = {
  "content-type": "application/json",
  AUTHORIZATION: authToken,
};

function TagmapIndex({}) {

  const tagMap =  useSelector((state) => state.clickrequests.tagMap)
  const user = useSelector((state) => state.clickmatrix.user)

  // const [loader, setLoader] = useState(true);
  const [tagsCounter, setCounter] = useState(0);
  const [custom, setCustom] = useState("");
  const [tagContainer, setTagContainer] = useState({
    
  });
  const [refresh, setRefresh] = useState(0);

  const dispatch = useDispatch();

  var loader =  useSelector((state) => state.clickrequests.isLoading)

  useEffect(() => {
    const fetchData = async () => {
      
        if(Object.keys(tagMap).length == 0){

        
        dispatch(tagMapThunk()).then((response) => {
         
          setTagContainer((arr) => (arr = {...response.payload}));
        })
      }
      else{
        setTagContainer((arr) => (arr = {...tagMap}));
      }
      // setLoader(false);
    };

    fetchData();
  }, [refresh]);
  const height = "20px";

  const handler = (key, index, status, changed_value) => {
    
    if (status == "update") {
      let new_obj = {};

      const make_change = async () =>
        (new_obj = {
          ...tagContainer,
          [key]: [...tagContainer[key], ""],
        });

      make_change();

      setTagContainer(new_obj);
    } else if (status == "change") {
      let new_obj = {};

      let new_arr = null;
      const set_array = async () => {
        new_arr = tagContainer[key];
        new_arr[index] = changed_value;
      };

      set_array();

      const make_change = async () => {
        new_obj = { ...tagContainer, [key]: new_arr };
      };

      make_change();
      setTagContainer(new_obj);
    } else if (status == "delete") {
      let new_obj = {};

      let new_arr = null;
      const set_array = async () => {
        new_arr = tagContainer[key];
        new_arr = new_arr.filter((item) => item !== changed_value);
       
      };

      set_array();

      const make_change = async () => {
        new_obj = { ...tagContainer, [key]: new_arr };
      };

      make_change();
      setTagContainer(new_obj);
    }
  };

  return (
    <>
      {loader && (
        <Stack spacing={2} style={{marginTop:"70px",marginLeft:"20px"}}>
        {/* For variant="text", adjust the height via font-size */}
        <div
          style={{
            display: "flex",
            width: "96%",
            justifyContent: "space-between"
          }}
        >
          <Skeleton variant="rounded" width={130} height={40} />
          
        </div>
        <Skeleton variant="rounded" width={1530} height={800} />
      </Stack>
      )}
      {!loader && (
        <>
          <div style={{ color: "#0D0992", fontWeight: 600, fontSize: "18px",marginTop:"4%",marginLeft:"1%" }}>
            Feed XML Configuration
          </div>
          <div
            style={{
              
              borderRadius: "5px",
              backgroundColor: "#FFFFFF",
              border:"1px solid rgba(0, 0, 0, 0.15)",
              marginTop: "1%",
              minHeight:"100px",
              maxWidth: "97%",
              marginLeft: "10px",
              
            }}
          >
            {tagContainer &&
              Object.keys(tagContainer).map((key,index) => (
                <div
                key={index}
                  style={{
                    display: "flex",
                    width: "100%",
                    
                    marginTop: "3%",
                    paddingLeft: "10px",
                    borderRadius: "10px",
                    
                  }}
                >

                  {/* Master Tag div  */}
                  <div style={{marginTop:"20px"}}>
                  
                    <CustomTextField
                      setFunction={setCustom}
                      labelName={"Master Tag"}
                      value={key}
                      shrink={true}
                    />
                  </div>
                  <div
                    style={{
                      
                      paddingLeft: "10px",
                      fontSize: "22px",
                      color: "#0D0992",
                      marginTop:"20px"
                    }}
                  >
                    :
                  </div>
                  {/* Feed tag div   */}
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "20px",
                      border:"1px solid #C5C5C5",
                      width: "80%",
                      minHeight:"160px", 
                      gap:"20px",
                      flexWrap:"wrap",
                      borderRadius:"5px",
                      paddingTop:"20px"
                      
                     
                    }}
                  >
                    {tagContainer[key].map((value, i) => (
                     
                      <React.Fragment key={i}>
                      
                        <TextField
                          id="outlined-basic"
                          label="Feed Tag"
                          value={value}
                          onChange={(e) => {
                            handler(key, i, "change", e.target.value);
                          }}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          size="small"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={(e) => {
                                    handler(key, i, "delete", value);
                                  }}
                                >
                                  <RemoveIcon sx={{ color: "#999999" }} />
                                  
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            "& .MuiInputLabel-root": {
                              color: "#0D0992",
                              fontSize: "17px",
                              padding: 0,
                            }, //styles the label
                            "& .MuiOutlinedInput-root": {
                              "& > fieldset": {
                                borderColor: "none",
                                borderRadius: "5px",
                                boxShadow:
                                  "rgba(0, 0, 0, 0.06) 0px 5px 4px 0px",
                              },
                            },
                            "& .MuiOutlinedInput-root.Mui-focused": {
                              "& > fieldset": {
                                borderColor: "#0D0992",
                              },
                            },
                            // width: "200px",
                            flexBasis:"200px",
                            marginLeft: "40px",
                          }}
                        />
                      </React.Fragment>
                    ))}

                    <div>
                      {/* key, index, status,changed_value */}
                      {(user[0].status != "admin") ? <></> :
                      <IconButton
                        onClick={() => handler(key, "", "update", "")}
                        sx={{
                      height: "32px",
                      width: "32px",
                      border: "1px solid #C4C4C4",
                      borderRadius: "10%",
                      marginTop:"3px"
                      
                        }}
                      >
                        {/* <ControlPointIcon sx={{ color: "#0D0992" }} /> */}
                        <AddIcon sx={{ fontSize: "24px" }} />
                      </IconButton>
}
                    </div>
                  </div>
                </div>
              ))}

            <div style={{ marginTop: "40px", marginLeft: "17.1%",marginBottom:"40px" }}>
            {(user[0].status != "admin") ? <></> :
              <Button
                variant="contained"
                onClick={() => {
                  // addData(tagContainer, "xml_config").then(() => {
                  //   dispatch(tagMapThunk()).then(() => {

                  //     setRefresh(refresh + 1);
                  //   })
                  // });
                  dispatch(tagmapAddThunk(tagContainer)).then((res) => {
                    dispatch(tagMapThunk()).then(() => {

                      setRefresh(refresh + 1);
                    })
                  })
                }}
                sx={{
                  width: "130px",
                  backgroundColor: "#0D0992E0",
                }}
              >
                Save
              </Button>
}
            </div>
          </div>
        </>
      )}
    </>
  );
}


export default TagmapIndex;
