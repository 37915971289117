import axios from "axios";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  companies: [],
  companySearch:"",
  campaigns: [],
  campaignSearch:"",
  credentials: [],
  credentialSearch:"",
  publishers: [],
  publisherSearch:"",
  ftpConfig: [],
  ftpSearch:"",
  partners: [],
  partnerSearch:"",
  feeds:[],
  feedSearch:"",
  tagMap:[],
  dashboard:[],
  employee:[],
  employeeSearch:"",
  isLoading:""
};


//we want to add the thunk for adding and editing data 
//For now we will just add the thunk and will not 
//use extraReducers functionality for now
export const notificationThunk = createAsyncThunk(
  "notification/all",
  async (data, thunkAPI) => {
    let res = axios
      .get(`${process.env.REACT_APP_API_URL_DATA}notifications`, {
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})

    return res;
  }
);


export const campaignAddThunk = createAsyncThunk(
  "campaignAdd",
  async (data, thunkAPI) => {
    let res = axios
      .post(`${process.env.REACT_APP_API_URL_DATA}campaigns/`, data,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})

    return res;
  }
);


export const mastercampaignAddThunk = createAsyncThunk(
  "mastercampaignAdd",
  async (data, thunkAPI) => {
    let res = axios
      .post(`${process.env.REACT_APP_API_URL_DATA}mastercampaigns/`, data,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})

    return res;
  }
);

export const mastercampaignEditThunk = createAsyncThunk(
  "mastercampaignEdit",
  async (data, thunkAPI) => {
    let editData = data[0]
    let id = data[1]
    let res = axios
      .put(`${process.env.REACT_APP_API_URL_DATA}mastercampaigns/${id}`, editData,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})

    return res;
  }
);

export const masterCampaignDeleteThunk = createAsyncThunk(
  "mastercampaignDelete",
  async (id, thunkAPI) => {
  
    let res = axios
      .delete(`${process.env.REACT_APP_API_URL_DATA}mastercampaigns/${id}`, {
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})

    return res;
  }
);


export const campaignEditThunk = createAsyncThunk(
  "campaignEdit",
  async (data, thunkAPI) => {
    let editData = data[0]
    let id = data[1]
    
    let res = axios
    
      .put(`${process.env.REACT_APP_API_URL_DATA}campaigns/${id}/`, editData,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})

    return res;
  }
);

export const campaignDeleteThunk = createAsyncThunk(
  "campaignDelete",
  async (id, thunkAPI) => {
    
    
    let res = axios
    
      .delete(`${process.env.REACT_APP_API_URL_DATA}campaigns/${id}/`, {
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})

    return res;
  }
);

export const partnerAddThunk = createAsyncThunk(
  "partnerAdd",
  async (data, thunkAPI) => {
    let res = axios
      .post(`${process.env.REACT_APP_API_URL_DATA}partners/`, data,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})

    return res;
  }
);

export const partnerEditThunk = createAsyncThunk(
  "partnerEdit",
  async (data, thunkAPI) => {
    let editData = data[0]
    let id = data[1]
    
    let res = axios
    
      .put(`${process.env.REACT_APP_API_URL_DATA}partners/${id}/`, editData,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})

    return res;
  }
);

export const partnerDeleteThunk = createAsyncThunk(
  "partnerDelete",
  async (id, thunkAPI) => {
    
    
    let res = axios
    
      .delete(`${process.env.REACT_APP_API_URL_DATA}partners/${id}/`, {
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})

    return res;
  }
);


export const feedAddThunk = createAsyncThunk(
  "feedAdd",
  async (data, thunkAPI) => {
    let res = axios
      .post(`${process.env.REACT_APP_API_URL_DATA}feeds/`, data,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})

    return res;
  }
);

export const feedEditThunk = createAsyncThunk(
  "feedEdit",
  async (data, thunkAPI) => {
    let editData = data[0]
    let id = data[1]
    
    let res = axios
    
      .put(`${process.env.REACT_APP_API_URL_DATA}feeds/${id}/`, editData,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})

    return res;
  }
);

export const feedDeleteThunk = createAsyncThunk(
  "feedDelete",
  async (id, thunkAPI) => {
    
    
    let res = axios
    
      .delete(`${process.env.REACT_APP_API_URL_DATA}feeds/${id}/`,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const credentialAddThunk = createAsyncThunk(
  "credentialAdd",
  async (data, thunkAPI) => {
    let res = axios
      .post(`${process.env.REACT_APP_API_URL_DATA}credentials/`, data,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const credentialEditThunk = createAsyncThunk(
  "credentialEdit",
  async (data, thunkAPI) => {
    let editData = data[0]
    let id = data[1]
    
    let res = axios
    
      .put(`${process.env.REACT_APP_API_URL_DATA}credentials/${id}/`, editData,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const credentialDeleteThunk = createAsyncThunk(
  "credentialDelete",
  async (id, thunkAPI) => {
    
    
    let res = axios
    
      .delete(`${process.env.REACT_APP_API_URL_DATA}credentials/${id}/`, {
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const ftpconfigAddThunk = createAsyncThunk(
  "ftpconfigAdd",
  async (data, thunkAPI) => {
    let res = axios
      .post(`${process.env.REACT_APP_API_URL_DATA}ftpconfig/`, data,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const ftpconfigEditThunk = createAsyncThunk(
  "ftpconfigEdit",
  async (data, thunkAPI) => {
    let editData = data[0]
    let id = data[1]
    
    let res = axios
    
      .put(`${process.env.REACT_APP_API_URL_DATA}ftpconfig/${id}/`, editData,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const ftpconfigDeleteThunk = createAsyncThunk(
  "ftpconfigDelete",
  async (id, thunkAPI) => {
    
    
    let res = axios
    
      .delete(`${process.env.REACT_APP_API_URL_DATA}ftpconfig/${id}/`, {
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const employeeAddThunk = createAsyncThunk(
  "employeeAdd",
  async (data, thunkAPI) => {
    let res = axios
      .post(`${process.env.REACT_APP_API_URL_DATA}employees/`, data,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const userAddThunk = createAsyncThunk(
  "userAdd",
  async (data, thunkAPI) => {
    let res = axios
      .post(`${process.env.REACT_APP_API_URL_DATA}userscrud/`, data,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const usersThunk = createAsyncThunk(
  "usersThunk",
  async (data, thunkAPI) => {
    let res = axios
      .get(`${process.env.REACT_APP_API_URL_DATA}users/`, {
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const usersIdThunk = createAsyncThunk(
  "userByIdThunk",
  async (id, thunkAPI) => {
    let res = axios
      .get(`${process.env.REACT_APP_API_URL_DATA}users/${id}`, {
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const employeeEditThunk = createAsyncThunk(
  "employeeEdit",
  async (data, thunkAPI) => {
    let editData = data[0]
    let id = data[1]
    
    let res = axios
    
      .put(`${process.env.REACT_APP_API_URL_DATA}employees/${id}/`, editData,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const userEditThunk = createAsyncThunk(
  "userEdit",
  async (data, thunkAPI) => {
    let editData = data[0]
    let id = data[1]
    
    let res = axios
    
      .put(`${process.env.REACT_APP_API_URL_DATA}userscrud/${id}/`, editData,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const userDeleteThunk = createAsyncThunk(
  "userDelete",
  async (id, thunkAPI) => {
    
    
    
    let res = axios
    
      .delete(`${process.env.REACT_APP_API_URL_DATA}userscrud/${id}/`,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);


export const employeeDeleteThunk = createAsyncThunk(
  "employeeDelete",
  async (id, thunkAPI) => {
    
    
    let res = axios
    
      .delete(`${process.env.REACT_APP_API_URL_DATA}employees/${id}/`,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const companyAddThunk = createAsyncThunk(
  "companyAdd",
  async (data, thunkAPI) => {
    let res = axios
      .post(`${process.env.REACT_APP_API_URL_DATA}childcompanies/`, data,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const companyEditThunk = createAsyncThunk(
  "companyEdit",
  async (data, thunkAPI) => {
    let editData = data[0]
    let id = data[1]
    
    let res = axios
    
      .put(`${process.env.REACT_APP_API_URL_DATA}childcompanies/${id}/`, editData,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const companyDeleteThunk = createAsyncThunk(
  "companyDelete",
  async (id, thunkAPI) => {
    
    
    let res = axios
    
      .delete(`${process.env.REACT_APP_API_URL_DATA}childcompanies/${id}/`,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const tagmapAddThunk = createAsyncThunk(
  "tagmapAdd",
  async (data, thunkAPI) => {
    let res = axios
      .post(`${process.env.REACT_APP_API_URL_DATA}xml_config/`, data,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const tagmapEditThunk = createAsyncThunk(
  "tagmapEdit",
  async (data, thunkAPI) => {
    let editData = data[0]
    let id = data[1]
    
    let res = axios
    
      .put(`${process.env.REACT_APP_API_URL_DATA}xml_config/${id}/`, editData,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const tagmapDeleteThunk = createAsyncThunk(
  "tagmapDelete",
  async (id, thunkAPI) => {
    
    
    let res = axios
    
      .delete(`${process.env.REACT_APP_API_URL_DATA}xml_config/${id}/`,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const publisherAddThunk = createAsyncThunk(
  "publisherAdd",
  async (data, thunkAPI) => {
    let res = axios
      .post(`${process.env.REACT_APP_API_URL_DATA}publishers/`, data,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const publisherEditThunk = createAsyncThunk(
  "publisherEdit",
  async (data, thunkAPI) => {
    let editData = data[0]
    let id = data[1]
    
    let res = axios
    
      .put(`${process.env.REACT_APP_API_URL_DATA}publishers/${id}/`, editData,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const publisherDeleteThunk = createAsyncThunk(
  "publisherDelete",
  async (id, thunkAPI) => {
    
    
    let res = axios
    
      .delete(`${process.env.REACT_APP_API_URL_DATA}publishers/${id}/`,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const feedReportAddThunk = createAsyncThunk(
  "feedReportAdd",
  async (data, thunkAPI) => {
    let res = axios
      .post(`${process.env.REACT_APP_API_URL_DATA}feed_report/`, data,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const profileThunk = createAsyncThunk(
  "profile",
  async (data, thunkAPI) => {
    let res = axios
      .post(`${process.env.REACT_APP_API_URL_DATA}auth/users/set_password/`, data,{
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);





export const campaignThunk = createAsyncThunk(
  "campaign/all",
  async (data, thunkAPI) => {
    let res = axios
      .get(`${process.env.REACT_APP_API_URL_DATA}campaigns`, {
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        params:{
          theMonth:data || ''
        }
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data.map((el) => ({...el,loader:false}));
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const mastercampaignThunk = createAsyncThunk(
  "mastercampaign/all",
  async (data, thunkAPI) => {
    let res = axios
      .get(`${process.env.REACT_APP_API_URL_DATA}mastercampaigns`, {
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        params:{
          theMonth:data || ''
        }
      })
      .then((response) => {
        // console.log("The response evening in is:",response)
        return response.data.map((el) => ({...el,loader:false}));
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const companyThunk = createAsyncThunk(
  "company/all",
  async (data, thunkAPI) => {
    let res = axios
      .get(`${process.env.REACT_APP_API_URL_DATA}childcompanies`, {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const credentialThunk = createAsyncThunk(
  "credential/all",
  async (data, thunkAPI) => {
    let res = axios
      .get(`${process.env.REACT_APP_API_URL_DATA}credentials`, {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})
    return res;
  }
);

export const publisherThunk = createAsyncThunk(
  "publisher/all",
  async (data, thunkAPI) => {
    let res = axios
      .get(`${process.env.REACT_APP_API_URL_DATA}publishers`, {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})

    return res;
  }
);

export const publisherFeedsThunk = createAsyncThunk(
  "publisherFeeds/all",
  async (data, thunkAPI) => {
    let res = axios
      .get(`${process.env.REACT_APP_API_URL_DATA}publisher_feeds`, {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})

    return res;
  }
);

export const publisherMasterFeedsThunk = createAsyncThunk(
  "publisherMasterFeeds/all",
  async (data, thunkAPI) => {
    let res = axios
      .get(`${process.env.REACT_APP_API_URL_DATA}publisher_masterfeeds`, {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})

    return res;
  }
);

export const employeeThunk = createAsyncThunk(
  "employee/all",
  async (data, thunkAPI) => {
    let res = axios
      .get(`${process.env.REACT_APP_API_URL_DATA}employees`, {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})

    return res;
  }
);

export const ftpConfigThunk = createAsyncThunk(
  "ftpconfig/all",
  async (data, thunkAPI) => {
    let res = axios
      .get(`${process.env.REACT_APP_API_URL_DATA}ftpconfig`, {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})

    return res;
  }
);

export const partnerThunk = createAsyncThunk(
  "partner/all",
  async (data, thunkAPI) => {
    let res = axios
      .get(`${process.env.REACT_APP_API_URL_DATA}partners`, {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})

    return res;
  }
);

export const feedsThunk = createAsyncThunk(
  "feeds/all",
  async (data, thunkAPI) => {
    let res = axios
      .get(`${process.env.REACT_APP_API_URL_DATA}feeds`, {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})

    return res;
  }
);

export const tagMapThunk = createAsyncThunk(
  "tagmap/all",
  async (data, thunkAPI) => {
    let res = axios
      .get(`${process.env.REACT_APP_API_URL_DATA}xml_config`, {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})

    return res;
  }
);

export const dashboardThunk = createAsyncThunk(
  "dashboard/stats",
  async (data, thunkAPI) => {
    let res = axios
      .get(`${process.env.REACT_APP_API_URL_DATA}dashboard_stats`, {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
  console.log(error);
})

    return res;
  }
);

export const requestSlice = createSlice({
  name: "api_request",
  initialState,
  reducers: {
    campaignSearchUpdate(state,action){
      return {
        ...state,
        campaignSearch:action.payload
      }
    },
    companySearchUpdate(state,action){
      return {
        ...state,
        companySearch:action.payload
      }
    },
    publisherSearchUpdate(state,action){
      return {
        ...state,
        publisherSearch:action.payload
      }
    },
    employeeSearchUpdate(state,action){
      return {
        ...state,
        employeeSearch:action.payload
      }
    },
    partnerSearchUpdate(state,action){
      return {
        ...state,
        partnerSearch:action.payload
      }
    },
    ftpSearchUpdate(state,action){
      return {
        ...state,
        ftpSearch:action.payload
      }
    },
    credentialSearchUpdate(state,action){
      return {
        ...state,
        credentialSearch:action.payload
      }
    },
    feedSearchUpdate(state,action){
      return {
        ...state,feedSearch:action.payload
      }
    },
    clearCacheData(state,action){
      if (process.env.ENVIRONMENT === 'production') {
  
}

      return {
        ...state,
        companies: [],
        campaigns: [],
        credentials: [],
        publishers: [],
        ftpConfig: [],
        partners: [],
        feeds:[],
        tagMap:[],
        dashboard:[],
        employee:[],
  
  isLoading:""

      }
    }
  },
  extraReducers: {
    [companyThunk.pending]: (state, { payload }) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [companyThunk.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        companies: payload,
        isLoading:false
      };
    },
    [companyDeleteThunk.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        companies: [],
        
      };
    },

    [campaignThunk.pending]: (state, { payload }) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [campaignThunk.fulfilled]: (state, { payload }) => {
      
      return {
        ...state,
        campaigns: payload,
        isLoading:false,
      };
    },
    [campaignDeleteThunk.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        campaigns: [],
       
      };
    },

    [credentialThunk.pending]: (state, { payload }) => {
      return {
        ...state,
        isLoading:true
      };
    },
    [credentialThunk.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        credentials: payload,
        isLoading:false
      };
    },
    [credentialDeleteThunk.pending]: (state, { payload }) => {
      return {
        ...state,
        credentials: [],
      };
    },

    [publisherThunk.pending]: (state, { payload }) => {
      return {
        ...state,
        isLoading:true
      };
    },
    [publisherThunk.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        publishers: payload,
        isLoading:false
      };
    },
    [publisherDeleteThunk.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        publishers: [],
        
      };
    },

    [employeeThunk.pending]: (state, { payload }) => {
      return {
        ...state,
        isLoading:true
      };
    },
    [employeeThunk.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        employee: payload,
        isLoading:false
      };
    },
    [employeeDeleteThunk.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        employee: [],
        
      };
    },

    [ftpConfigThunk.pending]: (state, { payload }) => {
      return {
        ...state,
        isLoading:true
      };
    },
    [ftpConfigThunk.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        ftpConfig: payload,
        isLoading:false
      };
    },
    [ftpconfigDeleteThunk.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        ftpConfig: [],
        
      };
    },

    [partnerThunk.pending]: (state, { payload }) => {
      return {
        ...state,
        isLoading:true
      };
    },
    [partnerThunk.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        partners: payload,
        isLoading:false
      };
    },
    [partnerDeleteThunk.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        partners: [],
        
      };
    },

    [feedsThunk.pending]: (state, { payload }) => {
      return {
        ...state,
        isLoading:true
      };
    },
    [feedsThunk.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        feeds: payload,
        isLoading:false
      };
    },
    [feedDeleteThunk.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        feeds: [],
        
      };
    },

    [tagMapThunk.pending]: (state, { payload }) => {
      return {
        ...state,
        isLoading:true
      };
    },
    [tagMapThunk.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        tagMap: payload,
        isLoading:false
      };
    },
    [tagmapDeleteThunk.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        tagMap: [],
        
      };
    },

    [dashboardThunk.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        dashboard: payload,
      };
    },
  },
});

// export const Api = userSlice.actions;
export const {clearCacheData,campaignSearchUpdate,companySearchUpdate,publisherSearchUpdate,partnerSearchUpdate,ftpSearchUpdate,credentialSearchUpdate,feedSearchUpdate,employeeSearchUpdate} = requestSlice.actions

export default requestSlice.reducer;
