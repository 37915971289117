import React from 'react'
import HomeRobot from './HomeRobot'
import HomeServices from './HomeServices'
import HomeVision from './HomeVision'
import HomeSolution from './HomeSolution'

function HomeA() {
  return (
    <>
     <HomeRobot />
     <HomeServices />
     <HomeSolution />
     <HomeVision />
     </>
  )
}

export default HomeA