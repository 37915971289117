import React, { useState, useEffect } from "react";
import Input from "@mui/material/Input";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getData, addData, getDataId,campaignGetDataId } from "../helpers/helpers";
import TimePicker from "react-time-picker";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FormHelperText } from "@mui/material";
import "../Loader/Loader.css";
import Autocomplete from "@mui/material/Autocomplete";
import CustomTextField from "../CustomTextField";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import {currencies} from "currencies.json";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { campaignEditThunk, campaignThunk, credentialAddThunk, publisherAddThunk } from "../../reducers/request_api";
import { useDispatch, useSelector } from 'react-redux'
import { feedsThunk, ftpConfigThunk, publisherThunk } from "../../reducers/request_api";


const useStyles = makeStyles(theme => ({
  
  menuPaper: {
    maxHeight: 300,
    maxWidth:200
  }
}));

const hours = [
  "12 am  (00)",
  "1 am  (01)",
  "2 am  (02)",
  "3 am  (03)",
  "4 am  (04)",
  "5 am  (05)",
  "6 am  (06)",
  "7 am  (07)",
  "8 am  (08)",
  "9 am  (09)",
  "10 am  (10)",
  "11 am  (11)",
  "12 pm  (12)",
  "1 pm  (13)",
  "2 pm  (14)",
  "3 pm  (15)",
  "4 pm  (16)",
  "5 pm  (17)",
  "6 pm  (18)",
  "7 pm  (19)",
  "8 pm  (20)",
  "9 pm  (21)",
  "10 pm  (22)",
  "11 pm  (23)",
];

const authToken =
  "JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjgxODgxNDE4LCJqdGkiOiJkYTBjZTlkMmE2NjM0ZWE1OTcxNWIzZjg3OTNkMDdiZCIsInVzZXJfaWQiOjN9.m_EAPPaxp3g18XL4MH-9RKB8I06yvoM-jZkKCynPOnE";

const headers = {
  "content-type": "application/json",
  AUTHORIZATION: authToken,
};

function CampaignEditIndex() {
  const classes = useStyles();

  const [loader,setLoader] = useState(true);
  const [feeds, setFeeds] = useState([]);
  const [feedId, setFeedId] = useState(null);
  const [feedName, setFeedName] = useState("");
  const [timeContainer, setTimeContainer] = useState([]);
  const [feedContainer, setFeedContainer] = useState([]);
  const [campaignName, setCampaignName] = useState([]);
  const [globalCpc, setGlobalCpc] = useState("");
  const [time, setTime] = useState("empty");
  const [publisherContainer, setPublisherContainer] = useState([]);
  const [publisherId, setPublisherId] = useState("");
  const [refresh, setRefresh] = useState(0);
  const [publisher, setPublisher] = useState("");
  const [publisherModal, setPublisherModal] = useState(false);
  const [filterCpc, setFilterCpc] = useState("");

  const [budget,setBudget] = useState("");
  const [credContainer, setCredContainer] = useState([]);
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [details, setDetails] = useState("");
  const [credModal, setCredModal] = useState(false);
  const [newCreds, setNewCreds] = useState("");
  const [url,setUrl] = useState("");
  const [downloadurl,setDownloadUrl] = useState("");
  const [action, setAction] = useState("");
  const [tagModal, setTagModal] = useState(false);
  const [tagContainer, setTagContainer] = useState([]);
  const [checked,setChecked] = useState(false);
  const [isCpa,setisCpa] = useState(false);
  const [radio,setRadio] = useState('DECREMENT');

  //Error Handling States
  const [usernameErrorState,setUsernameError] = useState("");
  const [passwordErrorState,setPasswordError] = useState("");
  const [detailErrorState,setDetailError] = useState("");
  const [globalCpcErrorState, setGlobalCpcError] = useState("");
  const [trafficErrorState, setTrafficError] = useState("");
  const [campaignErrorState, setCampaignError] = useState("");
  const [feedErrorState, setFeedError] = useState("");
  const [timeErrorState, setTimeError] = useState("");
  const [filterCpcState,setFilterCpcError] = useState("")
  const [budgetError,setBudgetError] = useState("");
  const [uploadErrorState,setUploadError] = useState("");
  const [urlErrorState,setUrlError] = useState("");
  const [downloadErrorState,setDownloadError] = useState("");
  const [utmErrorState,setUtmError] = useState("");

  const [currency,setCurrency] = useState("");
  const [currencyError,setCurrencyError] = useState("");
  const [actionErrorState, setActionError] = useState("");

  const [budgetPause,setbudgetPause] = React.useState(false);
  

  const navigate = useNavigate();

  const params = useParams();
  const dispatch = useDispatch();

  useEffect( () => {
   
    
    // getData(setPublisherContainer, "publishers");
    // getData(setCredContainer, "ftpconfig");
    // getData(setFeedContainer, "feeds");

    dispatch(publisherThunk()).then((response) => {
      setPublisherContainer(response.payload)
    })
    dispatch(ftpConfigThunk()).then((response) => {
      setCredContainer(response.payload)
    })
    dispatch(feedsThunk()).then((response) => {
      setFeedContainer(response.payload)
    })
    

    const fetchData = async () => {
      
      await axios
      .get(
        `${process.env.REACT_APP_API_URL_DATA}campaigns/${params.id}`,
        {
          // .get("http://localhost:8000/api/v1/partners", {
          headers:  {
            "content-type": "application/json",
            AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
          },
        }
      )
        .then((response) => {
          //   setData(response.data);
        
          console.log("The response is :",response)
          
          if(response.data.Feed_Campaign.length != 0){
            for(let i=0;i<response.data.Feed_Campaign.length;i++){
              let b = response.data.Feed_Campaign[i]
              setFeeds((arr) => [...arr,{feed_id:b.feed,filter_cpc:b.filter_cpc,feed_name:b.feed_name}]);
              setFilterCpc(b.filter_cpc)
              
            }
          }
          if(response.data.CampaignSchedule.length != 0){
            for(let i=0;i<response.data.CampaignSchedule.length;i++){

              let b = response.data.CampaignSchedule[i].schedule_time
              setTimeContainer((arr) => [...arr,b]);
            }
            
          }

          if(response.data.cpc_status == 'DYNAMIC'){
            setChecked(true);
            setRadio(response.data.dynamic_status)
          }
          
          setGlobalCpc(response.data.global_cpc);
          setPublisher(response.data.publisher);
          setCampaignName(response.data.filename);
          setDownloadUrl(response.data.download_url);
          setNewCreds(response.data.ftpUploadCredentials);
          setAction(response.data.action);
          setUrl(response.data.ftp_url);
          setbudgetPause(response.data.budget_pause)
          setBudget(response.data.budget);
          
          setLoader(false);
          if(response.data.utm_tags.length == 1){
            let a = response.data.utm_tags[0]
            if(a.tag == "" || a.value == ''){
              setTagContainer([])
            }
          }
          // setTagContainer(response.data.utm_tags);
          setCurrency(response.data.currency)
          setisCpa(response.data.is_cpa)

          //   setCountry({code:response.data.country})
        })
        if (process.env.ENVIRONMENT === 'production') {
console.log()
}
  
}

   

    fetchData()
      
    
    
    
  }, [refresh]);

  return (
    <>
    {loader && <div style={{display:"flex",justifyContent:"center"}}><div className="wrapper">
    <div className="circle"></div>
    <div className="circle"></div>
    <div className="circle"></div>
    <div className="shadow"></div>
    <div className="shadow"></div>
    <div className="shadow"></div>
</div></div>}
{!loader && <>
      {/* The modal for adding publisher inside add campaign  */}
      <Modal
        open={publisherModal}
        onClose={() => setPublisherModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            height: 350,
            bgcolor: "white",
            border: "2px solid white",
            boxShadow: 24,

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
          }}
        >
          <TextField
            onChange={(e) => setPublisher(e.target.value)}
            id="outlined-basic"
            label="Publisher Name"
            variant="outlined"
            sx={{
              "& .MuiInputLabel-root": { color: "rgba(0, 0, 0, 0.5);" }, //styles the label
              "& .MuiOutlinedInput-root": {
                "& > fieldset": {
                  borderColor: "none",
                  borderRadius: "10px",
                  boxShadow: "rgba(0, 0, 0, 0.06) 0px 5px 4px 0px",
                },
              },
              "& .MuiOutlinedInput-root.Mui-focused": {
                "& > fieldset": {
                  borderColor: "#0D0992",
                },
              },
              width: "250px",
            }}
          />

          <Button
            variant="contained"
            onClick={() => {
              // addData({ name: publisher }, "publishers").then(() => {

              //   setPublisherModal(false);
              //   setPublisher("");
              //   getData(setPublisherContainer, "publishers");
              // });
              dispatch(publisherAddThunk({name:publisher})).then((res) => {
                dispatch(publisherThunk()).then((response) => {
                  setPublisherContainer(response)
                })
              })
              
            }}
            sx={{
              backgroundColor: "#0D0992",
              marginTop: "30px",
              width: "100px",
              borderRadius: "7px",
            }}
          >
            Add
          </Button>
        </Box>
      </Modal>


      <Modal
        open={credModal}
        onClose={() => setCredModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            height: 350,
            bgcolor: "white",
            border: "2px solid white",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              color: "#0D0992",
              fontWeight: 600,
              fontSize: "20px",
            }}
          >
            Enter Credentials
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "25%",
              height: "200px",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "350px",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <div style={{ marginTop: "30px" }}>
                <CustomTextField
                  setFunction={setUserName}
                  labelName={"Username"}
                  onChange={true}
                  setErrorFunction={setUsernameError}
                  shrink={true}
                />
                <FormHelperText sx={{color:"red",margin:0}}>{usernameErrorState}</FormHelperText>
              </div>
              <div style={{ marginTop: "10px" }}>
                <CustomTextField
                  setFunction={setPassword}
                  labelName={"Password"}
                  onChange={true}
                  setErrorFunction={setPasswordError}
                  shrink={true}
                  
                />
                <FormHelperText sx={{color:"red",margin:0}}>{passwordErrorState}</FormHelperText>
              </div>
              <div style={{ marginTop: "10px" }}>
                <CustomTextField
                  setFunction={setDetails}
                  labelName={"Details"}
                  onChange={true}
                  setErrorFunction={setDetailError}
                  shrink={true}
                />
                <FormHelperText sx={{color:"red",margin:0}}>{detailErrorState}</FormHelperText>
              </div>
            </div>
          </div>
          <Button
            variant="contained"
            onClick={() => {
              let decision = true;

              if(username == ""){
                setUsernameError("Username Field Can't Be Empty")
                decision = false;
              }
              if(password == ""){
                setPasswordError("Password Field Can't Be Empty")
                decision = false;
              }
              if(details == ""){
                setDetailError("Detail Field Can't Be Empty")
                decision = false;
              }

              const data = {
                user_name: username,
                password: password,
                details: details,
              };
              if(decision){
                // addData(data, "credentials").then(() => {
                //   setRefresh(refresh+1);
                //   setCredModal(false);
                // });

                dispatch(credentialAddThunk(data)).then((res) => {
                  setRefresh(refresh+1);
                  setCredModal(false);
                })
                
              }
              
            }}
            sx={{
              backgroundColor: "#0D0992",
              marginTop: "50px",
              width: "100px",
              borderRadius: "7px",
            }}
          >
            Save
          </Button>
        </Box>
      </Modal>

      {/* The Modal For Utm Tags  */}
      <Modal
        open={tagModal}
        onClose={() => setTagModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            height: 350,
            bgcolor: "white",
            border: "2px solid white",
            boxShadow: 24,
            p: 4,
            display: "flex",

            alignItems: "center",
            justifyContent: "center",
            borderRadius: "10px",
          }}
        >
          <div>
            <div style={{ overflowY: "auto", height: "200px" }}>
              {tagContainer.length > 0 &&
                tagContainer.map((data, i) => (
                  <>
                    
                    <div style={{ display: "flex", paddingTop: "20px" }}>
                      <TextField
                        onChange={(e) => {
                          setTagContainer(
                            [...tagContainer].map((object) => {
                              if (object.tag_id === data.tag_id) {
                                return {
                                  ...object,
                                  tag: e.target.value,
                                };
                              } else return object;
                            })
                          );
                        }}
                        id="outlined-basic"
                        // inputRef={ref}
                        InputLabelProps={{ shrink: true }}
                        value={data.tag}
                        label={"Utm Tag"}
                        size="small"
                        variant="outlined"
                        sx={{
                          "& .MuiInputLabel-root": {
                            color: "rgba(0, 0, 0, 0.5);",
                          }, //styles the label

                          "& .MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": {
                              borderColor: "#0D0992",
                            },
                          },
                          "& input": {
                            paddingLeft: "10px",
                            paddingTop: "10px",
                          },
                          marginRight: "10px",
                        }}
                      />
                      <TextField
                        onChange={(e) => {
                          setTagContainer(
                            [...tagContainer].map((object) => {
                              if (object.tag_id === data.tag_id) {
                                return {
                                  ...object,
                                  value: e.target.value,
                                };
                              } else return object;
                            })
                          );
                        }}
                        id="outlined-basic"
                        // inputRef={ref}
                        InputLabelProps={{ shrink: true }}
                        value={data.value}
                        label={"Value"}
                        variant="outlined"
                        size="small"
                        sx={{
                          "& .MuiInputLabel-root": {
                            color: "rgba(0, 0, 0, 0.5);",
                          }, //styles the label

                          "& .MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": {
                              borderColor: "#0D0992",
                              borderRadius: "8px",
                            },
                          },

                          "& input": {
                            paddingLeft: "10px",
                            paddingTop: "10px",
                          },
                        }}
                      />
                      <IconButton
                        onClick={(e) => {
                          let arr = [];

                          tagContainer.map((object) => {
                            if (object.tag_id != data.tag_id) {
                              arr.push(object);
                            }
                          });

                          setTagContainer(arr);
                        }}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </div>
                  </>
                ))}
            </div>
            <div>
            <FormHelperText sx={{ color: "red" }}>
                  {utmErrorState}
            </FormHelperText>
            </div>
            <div
              style={{
                border: "0.5px solid #C5C5C5",
                width: "40px",
                borderRadius: "8px",
              }}
            >
              
              <IconButton
                onClick={() => {
                  let new_id = 0
                  if(tagContainer.length == 0){
                    new_id = 1
                  }
                  else{
                    new_id = tagContainer[tagContainer.length - 1].tag_id + 1
                  }
                  setTagContainer([
                    ...tagContainer,
                    { tag_id: new_id, tag: "", value: "" },
                  ]);
                }}
              >
                <AddIcon sx={{}} />
              </IconButton>
              
            </div>
            <div>
              <Button
                variant="contained"
                onClick={() => {
                  let decision = true;
                  if(tagContainer.length > 0){
                    tagContainer.map((data,index) => {
                      if(data.tag == "" || data.value == ""){
                        decision = false;
                      }
                    })
                  }
                  if(decision){

                    setTagModal(false);
                  }
                  else{
                    setUtmError("Can't have empty value for utm tags!")
                  }
                }}
                sx={{
                  backgroundColor: "#0D0992",
                  marginTop: "50px",
                  width: "100px",
                  borderRadius: "7px",
                }}
              >
                Done
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Div for layout horizontal  */}
      <div
        style={{
          display: "flex",
          
          flexDirection: "column",
          width: "100%",
        }}
      >
        {/* Div For Input Fields  */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
          <IconButton sx={{backgroundColor:"white",border:"1px solid #C5C5C5",borderRadius:"8px"}} onClick={() => navigate("/campaigns")}>
  <ArrowBackIcon />
                </IconButton>
          </div>
          <div style={{display:"flex"}}>
          <div
            style={{
              fontSize: "20px",
              color: "#0D0992",
              fontWeight: 600,
              marginTop: "20px",
            }}
          >
           Edit Campaign
          </div>
          <div style={{marginLeft:"8%",marginTop:"15px"}}>
            <FormControlLabel control={<Checkbox checked={budgetPause} onChange={(e) => setbudgetPause(e.target.checked)}  />} label="Pause campaign when budget over" />
            </div>
          </div>
          
          <div style={{ display: "flex", marginTop: "60px" }}>
            <div
              style={{
                display: "flex",
              }}
            >
            
            </div>
          </div>
           {/* The Action Input */}
           <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",

                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "16px",
                  paddingTop: "5px",

                  color: "#0D0992",
                  marginLeft: "100px",
                  width: "160px",
                }}
              >
                Action:
              </div>
              <div style={{ marginLeft: "5px" }}>
                <FormControl size="small">
                  <InputLabel id="demo-simple-select">
                    Select Action
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    size="small"
                    MenuProps={{ classes: { paper: classes.menuPaper } }}
                    value={action}
                    sx={{
                      "& fieldset": {
                        borderColor: "#C5C5C5",
                        borderWidth: "0.5px",
                      },
                      width: "250px",
                      borderRadius: "10px",
                      backgroundColor: "white",
                    }}
                    label="FTP Upload Area"
                    onChange={(e) => {
                      setAction(e.target.value);
                      if (e.target.value == "") {
                        setActionError("Select Field");
                      } else {
                        setActionError("");
                      }
                    }}
                  >
                    <MenuItem value={"buying"} key={"buying"}>
                      Buying
                    </MenuItem>
                    <MenuItem value={"selling"} key={"selling"}>
                      Selling
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormHelperText sx={{ color: "red" }}>
                  {actionErrorState}
                </FormHelperText>
              </div>
            </div>
          </div>
          {!checked && (
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div
              style={{
                display: "flex",

                justifyContent: "space-around",
              }}
            >
              <div
                style={{
                  paddingLeft: "100px",
                  fontWeight: 600,
                  fontSize: "16px",
                  paddingTop: "5px",
                  color: "#0D0992",
                }}
              >
                Global CPC:
              </div>
              <div style={{ marginLeft: "80px" }}>
                <Input
                  placeholder="Enter Global CPC"
                  disableUnderline={true}
                  value={globalCpc}
                  onChange={(e) => {
                    if(e.target.value == "" || !isNaN(e.target.value)){

                      setGlobalCpc(e.target.value);
                    }
                    if (e.target.value == "") {
                      setGlobalCpcError("Field Is Empty");
                    } else {
                      setGlobalCpcError("");
                    }
                  }}
                  sx={{
                    border: "0.5px solid #C5C5C5",
                    backgroundColor: "white",
                    width: "250px",
                    borderRadius: "10px",
  
                    paddingLeft: "15px",
                    paddingBottom: "5px",
                    input: {
                      color: "black",
                      fontWeight: "400",
                      "&::placeholder": {
                        color: "#212121",
                        opacity: 0.8,
                      },
                    },
                  }}
                />
                <FormHelperText sx={{color:"red",margin:0}}>{globalCpcErrorState}</FormHelperText>
              </div>
            </div>
          </div>
          )}
          <div
            style={{
              display: "flex",

             
              marginTop: "40px",
            }}
          >
            <div
              style={{
                paddingLeft: "100px",
                fontWeight: 600,
                fontSize: "16px",
                paddingTop: "12px",
                color: "#0D0992",
              }}
            >
              Publisher:
            </div>
            <div style={{ marginLeft: "93px" }}>
              <div style={{ display: "flex" }}>
                <FormControl size="small">
                  <InputLabel id="partner-label">Select Publisher</InputLabel>
                  <Select
                    size="small"
                    labelId="partner-label"
                    id="demo-simple-select"
                    value={publisher}
                    onChange={(e) => {
                      if(e.target.value == ""){
                        setTrafficError("Select Field");
                      }
                      else{
                        setTrafficError("")
                      }
                      setPublisher(e.target.value);
                      
                    }}
                    sx={{
                      width: "250px",
                      borderRadius: "7px",
                      "& fieldset": {
                        borderColor: "#C5C5C5",
                        borderWidth: "0.5px",
                      },
                      backgroundColor: "white",
                    }}
                    label="Select partner"
                  >
                    {publisherContainer.map((value) => (
                      <MenuItem value={value.user_id} key={value.user_id}>{value.user_name}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText sx={{color:"red",margin:0}}>{trafficErrorState}</FormHelperText>
                </FormControl>

                {/* <div style={{ paddingTop: "5px", marginLeft: "10px" }}>
                  <IconButton onClick={() => setPublisherModal(true)}>
                    <AddIcon
                      sx={{
                        color: "#0D0992",
                        fontSize: "24px",
                        border: "1px solid #0D0992",
                        borderRadius: "50%",
                      }}
                    />
                  </IconButton>
                </div> */}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",

              marginTop: "40px",
            }}
          >
            <div
              style={{
                paddingLeft: "100px",
                fontWeight: 600,
                fontSize: "16px",
                paddingTop: "5px",

                color: "#0D0992",
              }}
            >
              Campaign Filename:
            </div>
            <div style={{ marginLeft: "20px" }}>
              <Input
                placeholder="Enter Filename"
                disableUnderline={true}
                disabled
                value={campaignName}
                onChange={(e) => {
                  setCampaignName(e.target.value);
                  if (e.target.value == "") {
                    setCampaignError("Field Is Empty");
                  } else {
                    setCampaignError("");
                  }}}
                  sx={{
                    border: "0.5px solid #C5C5C5",
                    backgroundColor: "white",
                    width: "250px",
                    borderRadius: "10px",
  
                    paddingLeft: "15px",
                    paddingBottom: "5px",
                    input: {
                      color: "black",
                      fontWeight: "400",
                      "&::placeholder": {
                        color: "#212121",
                        opacity: 0.8,
                      },
                    },
                  }}
              />
              <FormHelperText sx={{color:"red",margin:0}}>{campaignErrorState}</FormHelperText>
            </div>
          </div>
          <div
            style={{
              display: "flex",

              marginTop: "40px",
            }}
          >
            <div
              style={{
                paddingLeft: "100px",
                fontWeight: 600,
                fontSize: "16px",
                paddingTop: "5px",

                color: "#0D0992",
              }}
            >
              Budget:
            </div>
            <div style={{ marginLeft: "110px" }}>
              <Input
                placeholder="Enter Filename"
                disableUnderline={true}
                value={budget}
                onChange={(e) => {
                  if(e.target.value == "" || !isNaN(e.target.value)){

                    setBudget(e.target.value);
                  }
                  if (e.target.value == "") {
                    setBudgetError("Field Is Empty");
                  } else {
                    setBudgetError("");
                  }}}
                  sx={{
                    border: "0.5px solid #C5C5C5",
                    backgroundColor: "white",
                    width: "250px",
                    borderRadius: "10px",
  
                    paddingLeft: "15px",
                    paddingBottom: "5px",
                    input: {
                      color: "black",
                      fontWeight: "400",
                      "&::placeholder": {
                        color: "#212121",
                        opacity: 0.8,
                      },
                    },
                  }}
              />
              <FormHelperText sx={{color:"red",margin:0}}>{budgetError}</FormHelperText>
            </div>
          </div>


          <div
            style={{
              display: "flex",

              marginTop: "20px",
            }}
          >
            <div
              style={{
                paddingLeft: "100px",
                fontWeight: 600,
                fontSize: "16px",
                paddingTop: "5px",

                color: "#0D0992",
              }}
            >
              Currency
            </div>
            <div style={{ marginLeft: "103px" }}>
              {/* <Input
                placeholder="Select Currency"
                disableUnderline={true}
                value={currency}
                onChange={(e) => {
                  if (e.target.value == "" || !isNaN(e.target.value)) {
                    setCurrency(e.target.value);
                  }

                  if (e.target.value == "") {
                    setCurrencyError("Budget Is Empty");
                  } else {
                    setCurrencyError("");
                  }
                }}
                sx={{
                  border: "0.5px solid #C5C5C5",
                  backgroundColor: "white",
                  width: "250px",
                  borderRadius: "10px",

                  paddingLeft: "15px",
                  paddingBottom: "5px",
                  input: {
                    color: "black",
                    fontWeight: "400",
                    "&::placeholder": {
                      color: "#212121",
                      opacity: 0.8,
                    },
                  },
                }}
              /> */}
              <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      MenuProps={{ classes: { paper: classes.menuPaper } }}
                      
                      value={currency}
                      sx={{
                        "& fieldset": {
                          borderColor: "#C5C5C5",
                          borderWidth: "0.5px",
                        },
                        width: "250px",
                        borderRadius: "10px",
                        backgroundColor: "white",
                      }}
                      label="FTP Upload Area"
                      
                      onChange={(e) => {
                        setCurrency(e.target.value);
                        if (e.target.value == "") {
                          setCurrencyError("Select Field");
                        } else {
                          setCurrencyError("");
                        }
                      }}
                    >
                     
                      {currencies.map((value) => (
                        <MenuItem value={value.symbol} key={value.code}>
                          {value.code}-{value.name}
                        </MenuItem>
                      ))}
                     
                    </Select>
              <FormHelperText sx={{ color: "red" }}>
                {currencyError}
              </FormHelperText>
            </div>
          </div>



          <div
            style={{
              display: "flex",

              marginTop: "40px",
            }}
          >
            <div
              style={{
                paddingLeft: "100px",
                fontWeight: 600,
                fontSize: "16px",
                paddingTop: "13px",

                color: "#0D0992",
              }}
            >
              Upload Credentials:
            </div>
            <div style={{ marginLeft: "25px" }}>
            
            
                <div style={{ display: "flex" }}>
                  <div>
                    <FormControl size="small">
                      <InputLabel id="ftp">
                        FTP Upload Area
                      </InputLabel>
                      <Select
                        labelId="ftp"
                        id="demo-simple-select"
                        size="small"
                        value={newCreds}
                        sx={{
                          "& fieldset": {
                            borderColor: "#C5C5C5",
                            borderWidth: "0.5px",
                          },
                          width: "250px",
                          borderRadius: "10px",
                          backgroundColor: "white",
                        }}
                        label="FTP Upload Area"
                        onChange={(e) => {
                        
                            setNewCreds(e.target.value)
                          
                        if(e.target.value == ""){
                          setUploadError("Select Field")
                        }else{
                          setUploadError("")
                        }}}
                      >
                        {credContainer.map((value) => (
                          <MenuItem value={value.id} key={value.id}>{value.details}</MenuItem>
                        ))}
                      </Select>
                      <FormHelperText sx={{color:"red",margin:0}}>{uploadErrorState}</FormHelperText>
                    </FormControl>
                  </div>
                  <div>
                    {/* <IconButton
                      onClick={() => {
                        setCredModal(true);
                        setUsernameError("");
                        setPasswordError("");
                        setDetailError("")
                        
                      }}
                      sx={{
                        padding: "0px",
                        marginLeft: "20px",
                        marginTop: "12px",
                        height: "34px",
                        width: "35px",
                        border: "1px solid #C4C4C4",
                        borderRadius: "20%",
                        "&:hover": {
                          border: "1px solid #0D0992",
                          color: "#0D0992",
                          background: "white",
                        },
                      }}
                    >
                      <AddIcon
                        sx={{
                          fontSize: "24px",
                        }}
                      />
                    </IconButton> */}
                  </div>
               
              </div>
              
            </div>
          </div>
          {/* The Utm Tag Buttons */}
          <div
            style={{
              display: "flex",

              marginTop: "40px",
            }}
          >
            <div
              style={{
                paddingLeft: "100px",
                fontWeight: 600,
                fontSize: "16px",
                paddingTop: "2px",

                color: "#0D0992",
              }}
            >
              <button
                type="button"
                style={{
                  border: "0.5px solid #C5C5C5",
                  color: "#0D0992",
                  borderRadius: "8px",
                  backgroundColor: "white",
                  padding: "13px",
                  cursor: "pointer",
                }}
                onClick={() => setTagModal(true)}
              >
                Update Utm Tag
              </button>
              {/* The dynamic entry  */}
        <div
            style={{
              display: "flex",

              
            }}
          >
            <div >
            <FormGroup>
  <FormControlLabel control={<Checkbox checked={checked} onChange={(e) => {setChecked(e.target.checked);setRadio('DECREMENT')}} />} label="Dynamic" />
  
</FormGroup>
</div>
            
            {/*   */}
            <div>
              
            </div>
          </div>

         

          {/* The is cpc entry  */}
          <div
            style={{
              display: "flex",

            }}
          >
            <div >
            <FormGroup>
  <FormControlLabel control={<Checkbox checked={isCpa} onChange={(e) => {setisCpa(e.target.checked)}} />} label="Is_CPA" />
  
</FormGroup>
</div>
            
         
            <div>
              
            </div>
          </div>
            </div>
            
            {/* Utm Table  */}
            <div>
            <div style={{height:"100px",overflowY:"auto",borderRadius:"9px",border: "0.5px solid #C5C5C5",width:"245px",marginLeft:"46px"}}>
                <TableContainer component={Paper} sx={{width:"100%",height:"100%"}}>
                  <Table aria-label="simple table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Tags</TableCell>
                        <TableCell>value</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                    
                      {tagContainer.map((value,index) => (
                        <React.Fragment key={index}>
                        <TableRow>
                        
                        <TableCell>{value.tag}</TableCell>
                        <TableCell>{value.value}</TableCell>
                        </TableRow>
                        </React.Fragment>
                     ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                </div>
                <FormHelperText sx={{ color: "red",marginLeft:"65px" }}>
                    {utmErrorState}
                </FormHelperText>
            </div>
          </div>
        
        

           {/* The Radio Buttons  */}
           {checked && (

          
<div
  style={{
    display: "flex",

    marginTop: "10px",
  }}
>
  <div style={{marginLeft:"120px"}}>
  <FormControl>
{/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
<RadioGroup
row
aria-labelledby="demo-row-radio-buttons-group-label"
name="row-radio-buttons-group"
onChange={(e) => {setRadio(e.target.value)}}
value={radio}
>
<FormControlLabel value="DECREMENT" control={<Radio />} label="Decrement" />
<FormControlLabel value="INCREMENT" control={<Radio />} label="Increment" />

</RadioGroup>
</FormControl>
</div>
  
  {/* Utm Table  */}
  <div>
    
  </div>
</div>
)}

          


          
        </div>

       



        {/* Campaign Add Select Time Layout  */}
        <div
          style={{
            display: "flex",

            marginTop: "1%",
            marginBottom: "1%",
            marginLeft: "2%",
            border: "0.5px solid #C5C5C5",
            background: "#FFFFFF",
            width: "90%",
            paddingTop: "20px",
            paddingLeft: "20px",
            justifyContent: "center",
          }}
        >
          {/* Div for Select Field */}
          <div
            className="styleselect"
            style={{
              width: "50%",
            }}
          >
            <div>
              {/* <div
                style={{
                  fontWeight: 600,
                  fontSize: "16px",
                  paddingTop: "5px",
                  color: "#0D0992",
                  marginBottom: "10px",
                  paddingLeft: "1px",
                }}
              >
                Select Feeds
              </div> */}
              <FormControl size="small">
                <InputLabel id="comapny-label">Select Feeds </InputLabel>
                <Select
                  size="small"
                  labelId="comapny-label"
                  id="demo-simple-select"
                  sx={{
                    width: "250px",
                    borderRadius: "8px",
                    height: "45px",
                    "& fieldset": {
                      borderColor: "#C5C5C5",
                      borderWidth: "0.5px",
                    },
                    backgroundColor: "white",
                  }}
                  label="Select Feeds"
                  onChange={(e) => {
                    if(feeds.length === 0){
                      setFeedError("");
                      setFeeds((arr) => [
                        
                        ...arr,
                        { feed_id: e.target.value.id, filter_cpc: 0,feed_name:e.target.value.company_name+"_"+e.target.value.partner_name+"_"+ e.target.value.country},
                      ]);
                    }
                    else{
                      let c = feeds.find((p) => p.feed_id === e.target.value.id)
                      
                      if(typeof c === "undefined"){
                        setFeedError("");
                        setFeeds((arr) => [
                          ...arr,
                          { feed_id: e.target.value.id, filter_cpc: 0,feed_name:e.target.value.company_name+"_"+e.target.value.partner_name+"_"+ e.target.value.country },
                        ]);
                      }
                    }

                   
                    
                    // if (!feeds.includes(e.target.value.partner_name)) {
                    //   setFeedId(e.target.value.id);
                    //   setFeeds((arr) => [
                    //     ...arr,
                    //     { feed_id: e.target.value.id, filter_cpc: 0 },
                    //   ]);
                    // }
                  }}
                >
                  {feedContainer.map((value,index) => (
                    <MenuItem value={value} key={index}>{value.company_name.replace(/\s+/g, '')}_{value.partner_name.replace(/\s+/g, '')}_{value.country}</MenuItem>
                  ))}
                </Select>
                <FormHelperText sx={{color:"red",margin:0}}>{feedErrorState}</FormHelperText>
              </FormControl>
            </div>
            <div
              style={{
                marginTop: "10px",
                border: "1px solid #C5C5C5",
                width: "85%",
                height: "130px",
                overflowY: "auto",

                paddingLeft: "20px",
              }}
            >
              <List aria-label="basic-list">
                {feeds.map((value, index) => (
                  <div style={{ display: "flex" }} key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "white",
                        border: "0.5px solid #C5C5C5",
                        borderRadius: "10px",
                        minWidth: "300px",

                        marginTop: "15px",
                      }}
                    >
                      <div style={{ paddingLeft: "10px" }}>
                        <ListItem>{value.feed_name}</ListItem>
                      </div>
                      <div>
                        <IconButton
                          onClick={() =>
                            setFeeds(feeds.filter((a) => a != value))
                          }
                        >
                          <RemoveIcon sx={{ color: "red" }} />
                        </IconButton>
                      </div>
                    </div>
                    <div style={{ marginTop: "15px" }}>
                      {/* <Input
                        placeholder="Filter CPC"
                        disableUnderline={true}
                        label={"Filter"}
                        shrink={true}
                        value={value.filter_cpc}
                        onChange={(e) => {
                          
                          setFeeds(
                            [...feeds].map((object) => {
                              if (object.feed_id === value.feed_id) {
                                return {
                                  ...object,
                                  filter_cpc: e.target.value,
                                };
                              }
                              else {
                                return object
                              }
                            })
                          );
                        }}
                        sx={{
                          boxShadow: "0px 2px 2px #0D0992",
                          border: "1px solid rgba(13, 9, 146, 0.88)",
                          backgroundColor: "white",
                          width: "100px",
                          borderRadius: "10px",
                          marginLeft: "20px",
                          paddingLeft: "15px",
                          paddingBottom: "5px",
                          input: {
                            color: "black",
                            fontWeight: "400",
                            "&::placeholder": {
                              color: "#212121",
                              opacity: 0.8,
                            },
                          },
                        }}
                      /> */}
                      <div className="input-group">
                        <input
                          required=""
                          onChange={(e) => {
                            if (
                              e.target.value == "" ||
                              !isNaN(e.target.value)
                            ) {
                              setFilterCpc(e.target.value);
                              setFeeds(
                                [...feeds].map((object) => {
                                  if (object.feed_id === value.feed_id) {
                                    return {
                                      ...object,
                                      filter_cpc: e.target.value,
                                    };
                                  } else {
                                    return object;
                                  }
                                })
                              );
                            }
                          }}
                          type="text"
                          name="text"
                          value={value.filter_cpc}
                          autoComplete="off"
                          className="custominput"
                        />
                        {checked == true ? (
                          <>
                            <label className="user-label">{radio}</label>
                          </>
                        ) : (
                          <label className="user-label">Filter Cpc</label>
                        )}

                        {checked == true && <>%</>}
                      </div>
                      <FormHelperText sx={{ color: "red" }}>
                        {/* {filterCpcState} */}
                        {value.filter_cpc === "" && `${checked == true ? radio : "Filter CPC"} can't be empty`}
                      </FormHelperText>
                    </div>
                  </div>
                ))}

                <div></div>
              </List>
            </div>
          </div>

          {/* Div for Time Field  */}
          <div
            style={{
              width: "50%",
            }}
          >
            {/* <div
              style={{
                marginTop: "30px",
                fontWeight: 600,
                fontSize: "16px",
                paddingTop: "5px",
                color: "#0D0992",
              }}
            >
              Select Feed Download Time
            </div> */}

            <div
              style={{ display: "flex", marginTop: "10px", marginLeft: "50%" }}
            >
               <FormControl>
            <Autocomplete
                id="country-select-demo"
                size="small"
                sx={{
                  width: "250px",
                  borderRadius: "10px",
                  backgroundColor: "white",

                }}
                value={null}
                blurOnSelect={true}
                options={hours}
                autoHighlight
                onChange={(event, value) => {
                  if (!timeContainer.includes(value) ) {
                    setTimeError("")
                    setTimeContainer((arr) => [...arr, value]);
                  }
                }}
                getOptionLabel={(option) => option}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                    {...props}
                  >
                    {option}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Hours"
                    sx={{
                      "& fieldset": {
                        borderRadius: "10px",
                        border: "0.5px solid #C5C5C5",
                      },
                    }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: "new-password", // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <FormHelperText sx={{color:"red",margin:0}}>{timeErrorState}</FormHelperText>
              </FormControl>
            </div>
            {/* onClick={() => {
                    
                    if (!timeContainer.includes(time) && time != "empty") {
                      setTimeContainer((arr) => [...arr, time]);
                    }
                  }} */}

            <div
              style={{
                width: "650px",
                marginTop: "10px",
                border: "1px solid #C5C5C5",
                height: "130px",
                overflowY: "auto",
              }}
            >
              <List aria-label="basic-list">
                {timeContainer.map((value, index) => (
                  <React.Fragment key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "250px",
                        border: "0.5px solid #C5C5C5",
                        marginTop: "15px",
                        marginLeft: "20px",
                        borderRadius: "10px",
                        backgroundColor: "white",
                      }}
                    >
                      <div style={{ paddingLeft: "10px" }}>
                        <ListItem>{value}</ListItem>
                      </div>
                      <div>
                        <IconButton
                          onClick={() =>
                            setTimeContainer(
                              timeContainer.filter((a) => a != value)
                            )
                          }
                        >
                          <RemoveIcon sx={{ color: "red" }} />
                        </IconButton>
                      </div>
                    </div>
                  </React.Fragment>
                ))}

                <div></div>
              </List>
            </div>
          </div>
        </div>
            
        <div style={{ marginLeft: "680px", marginTop: "20px" }}>
            <Button
              variant="contained"
              onClick={async () => {
                let decision = true
                if(globalCpc == ""){
                  setGlobalCpcError("Cpc Should Not Be Empty")
                  decision = false;
                }
                if(publisher == ""){
                  setTrafficError("Select Field");
                  decision = false;
                }
                
                if(campaignName == ""){
                  setCampaignError("Filename Should Not Be Empty")
                  decision = false
                }
                if(feeds.length == 0){
                  setFeedError("Feeds Selected Cannot Be Empty")
                  decision = false
                }
                if(timeContainer.length == 0){
                  setTimeError("Time Should Be Selected")
                  decision = false
                }

                if(currency == ''){
                  setCurrencyError("Currency not selected")
                  decision = false
                }
                if(action == ''){
                  setActionError("Action not selected")
                  decision = false
                }
                // if(filterCpc == ""){
                //   setFilterCpcError("Filter Cpc Can't Be Empty")
                //   decision = false
                // }
                if(budget == ""){
                  setBudgetError("Budget Is Empty");
                  decision = false
                }

                if(tagContainer.length > 0){
                  tagContainer.map((data,index) => {
                    if(data.tag == "" || data.value == ""){
                      decision = false
                    }
                  })
                  if(!decision){
                    setUtmError("Empty value for utm tags")
                  }
                }

                // if(url == ""){
                //   setUrlError("Url Is Empty");
                //   decision = false;
                // }
                let cpc_status = null;
                let cpa;
                
                if(checked){
                  cpc_status = 'DYNAMIC';
                  setGlobalCpc("0")
                }
                else{
                  cpc_status = 'STATIC';
                  setRadio("");
                  if (globalCpc == "") {
                    setGlobalCpcError("Cpc Should Not Be Empty");
                    decision = false;
                  }
                }

                if(newCreds == ""){
                  setUploadError("This field should be Selected")
                  decision = false;
                }
                // if(downloadurl == ""){
                //   setDownloadError("Field Is Empty!")
                //   decision = false;
                // }

                if(isCpa == true){
                  cpa = 1
                }else{
                  cpa = 0
                }
                
                const data = {
                  global_cpc: globalCpc,
                  publisher: publisher,
                  filename: campaignName,
                  schedules: timeContainer,
                  feeds: feeds,
                  budget:budget,
                  currency:currency,
                  action:action,
                  ftpUploadCredentials: newCreds,
                  utm_tags:tagContainer,
                  dynamic_status:radio,
                  cpc_status:cpc_status,
                  is_cpa:cpa,
                  budget_pause:budgetPause,
                  
                };
                if(decision){
                  console.log("The data is ",data)
                 
                  // let d = await editData(data, "campaigns",params.id);
                  // if (d !== null) {
                  //   dispatch(campaignThunk()).then((res) => navigate("/campaigns"))
                    
                  // }
                  dispatch(campaignEditThunk([data,params.id])).then(() => {
                    dispatch(campaignThunk()).then((res) => navigate("/campaigns"))
                  })
                }
                
              }}
              sx={{
                borderRadius: "10px",
                backgroundColor: "#0D0992",
                width: "100px",
              }}
            >
              Update
            </Button>
          </div>


      </div>
</>}
    </>
  );
}

export default CampaignEditIndex;
