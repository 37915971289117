import "./datepicker.css"; // Create your custom styles file
import moment from 'moment';
import React from "react";
import { DatePicker, Space } from "antd";
import { useDispatch } from "react-redux";
function LastThreeMonths({ setFilterFunction,refresh,setRefresh}) {
  const dispatch = useDispatch();
  // const defaultDate = new Date(); // Replace this with your desired date

  // const formatSelectedDate = (date) => {
  //   const options = { year: '2-digit', month: 'long' };
  //   return date.toLocaleDateString('en-US', options);
  // };
  const defaultDate = moment(); // Set your default date as needed

  const formatSelectedDate = (date) => {
    return date.format('MMMM/YY');
  };
  console.log(formatSelectedDate(defaultDate))

  const handleChange = (date, dateString) => {
    // Adjust the month to be one-indexed
    const adjustedDate = { ...date, "$M": date.month() + 1 };
    console.log("Adjusted is ",adjustedDate)
    setFilterFunction(adjustedDate);
  };
  return (
    <div>
      <DatePicker   renderExtraFooter={() => formatSelectedDate(defaultDate)} placeholder="Filter by month" onChange={handleChange} format="MMMM/YY" picker="month" />
    </div>
  );
}

export default LastThreeMonths;
