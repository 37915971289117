import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
// import {getPublisher} from "../helpers/helpers";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Link } from "react-router-dom";
import { Input } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
// import { deleteData } from "../helpers/helpers";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TextField from "@mui/material/TextField";
import { useDispatch ,useSelector} from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { campaignDeleteThunk, campaignThunk, publisherFeedsThunk, publisherMasterFeedsThunk } from "../../reducers/request_api";
import Divider from '@mui/material/Divider';

function TableBox({setTotalBudget,setTotalRevenue,setTotalClicks,setTotalValid,setTotalInvalid}) {
  const navigate = useNavigate();
  // const [loader, setLoader] = useState(true);

  const [open, setOpen] = useState(true);
  const [publisherContainer, setPublisherContainer] = useState([]);
  const [masterpublisherContainer,setMasterPublisherContainer] = useState([]);
  const [refresh, setRefresh] = useState(false);
  
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});

  const [campaignUrl, setCampaignUrl] = useState("Campaign.com");

  const dispatch = useDispatch()
  var loader =  useSelector((state) => state.clickrequests.isLoading)

  useEffect(() => {
    // const displayLoader = async () => {
    //   await getPublisher(setPublisherContainer,setTotalBudget,setTotalRevenue,setTotalClicks,setTotalValid,setTotalInvalid, "publisher_feeds");
    // };
    // const check = async () => {
    //   await displayLoader();
    //   setLoader(false);
    // };
    // check();
    dispatch(publisherMasterFeedsThunk()).then((response) => {
      setMasterPublisherContainer(response.payload)
    })
    dispatch(publisherFeedsThunk()).then((response) => {
      
      var revenue = 0;
      var invalid = 0;
      var valid = 0;
      var budget = 0;
      var total = 0;

      setPublisherContainer((arr) => (arr = [...response.payload]));
      response.payload.map((value) => {
        
        revenue += value.CampaignStats[0].budget_spent;
        total += value.CampaignStats[0].total_clicks;
        invalid += value.CampaignStats[0].invalid_clicks;
        valid += value.CampaignStats[0].valid_clicks;
        budget += parseInt(value.CampaignStats[0].total_budget);        
      });
      setTotalBudget(budget);
      setTotalClicks(total);
      setTotalInvalid(invalid);
      setTotalValid(valid);
      setTotalRevenue(revenue.toFixed(2));
    })
    
   
  }, [refresh]);


  return (
    <>
      {loader && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="wrapper">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
          </div>
        </div>
      )}
      {!loader && (
        <>
        <Dialog
            open={deleteDialog}
            onClose={() => {
              setDeleteDialog(false);
              setDeleteItem({});
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm deletion"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure to delete <strong>{deleteItem.filename}</strong>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                variant="outlined"
                sx={{
                  border: "1px solid #0D0992",
                  color: "#0D0992",
                  borderRadius: "7px",
                  fontSize: "13px",
                }}
                onClick={() => setDeleteDialog(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#0D0992",
                  borderRadius: "7px",
                  fontSize: "13px",
                }}
                onClick={() => {
                  // setDeleteDialog(false);
                  // deleteData("campaigns", deleteItem.id).then(() => {
                  //   setRefresh(refresh + 1);
                  // });
                  dispatch(campaignDeleteThunk(deleteItem.id)).then(() => {
                    dispatch(campaignThunk()).then(() => {

                      setRefresh(refresh + 1);
                      setDeleteDialog(false);
                    })
                  })

                }}
                autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        <TableContainer
          component={Paper}
          sx={{
            width: "97%",
            marginLeft: "10px",
            backgroundColor: "#FFFFFF",
            borderRadius: "13px",
            boxShadow: 2,
          }}
        >
          <Table aria-label="simple table" size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  align="left"
                  sx={{
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    color: "grey",
                    paddingLeft: "60px",
                  }}
                ></TableCell>
                <TableCell
                  align="left"
                  sx={{ color: "grey", paddingLeft: "10px" }}
                >
                  <strong>Name</strong>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ color: "grey", paddingLeft: "60px" }}
                >
                  <strong>Revenue</strong>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ color: "grey", paddingLeft: "60px" }}
                >
                  <strong>CPC</strong>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ color: "grey", paddingLeft: "60px" }}
                >
                  <strong>Total Clicks</strong>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ color: "grey", paddingLeft: "60px" }}
                >
                  <strong>Valid Clicks</strong>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ color: "grey", paddingLeft: "60px" }}
                >
                  <strong>Invalid Clicks</strong>
                </TableCell>
                <TableCell align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
             
            {/* The campaign Container Mapping  */}
              {publisherContainer.map((value, index) => (
                <>
                  <TableRow
                    key="1"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      sx={{
                        width: "10%",
                        paddingTop: "40px",
                        paddingBottom: "30px",
                        paddingLeft: "40px",
                      }}
                    >
                      {/* <label class="switch">
                        <input type="checkbox" />
                        <span class="slider"></span>
                      </label> */}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{
                        width: "15%",
                        paddingTop: "30px",
                        paddingBottom: "30px",
                        paddingLeft: "20px",
                      }}
                    >
                      <strong>{value.filename}</strong>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ width: "15%", paddingLeft: "60px" }}
                    >
                      <Box>
                        {/* <strong>$500</strong> of 1000$ */}
                        <strong>{(value.CampaignStats[0].budget_spent).toFixed(2)}</strong>
                        {/* <LinearProgress
                          variant="determinate"
                          value={50}
                          sx={{
                            marginTop: "3px",
                            width: "60%",
                            borderRadius: "15px",
                            height: "5px",

                            "& .MuiLinearProgress-bar1Determinate": {
                              backgroundColor: "#0D0992",
                            },
                          }}
                        /> */}
                      </Box>
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ width: "10%", paddingLeft: "60px" }}
                    >
                      {value.global_cpc}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ width: "10%", paddingLeft: "60px" }}
                    >
                      {value.CampaignStats[0].total_clicks}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ width: "10%", paddingLeft: "60px" }}
                    >
                      {value.CampaignStats[0].valid_clicks}
                    </TableCell>
                    <TableCell
                      align="left"
                      sx={{ width: "10%", paddingLeft: "60px" }}
                    >
                      {value.CampaignStats[0].invalid_clicks}
                    </TableCell>
                    <TableCell align="left" sx={{ width: "15%" }}>
                      <Box sx={{display:"flex"}}>

                    
                      
                        <IconButton
                          aria-label="expand-row"
                          size="small"
                          sx={{ marginLeft: "5%" }}
                          onClick={() => {
                            navigate(`/publisher_analytics/${value.id}`);
                          }}
                        >
                          <AssessmentIcon size="small" />
                        </IconButton>
                      
                      {/* <IconButton
                        aria-label="expand-row"
                        size="small"
                        onClick={() => setOpen(open === index ? -1 : index)}
                        sx={{ marginLeft: "5%" }}
                      >
                        {open === index ? (
                          <KeyboardArrowUp />
                        ) : (
                          <KeyboardArrowDown />
                        )}
                      </IconButton> */}

                      
                      </Box>
                    </TableCell>
                  </TableRow>
               
    
                  <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={9}
                      >
                        <Collapse
                          in={open === index}
                          timeout="auto"
                          unmountOnExit
                        >
                          <div >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-around",
                                marginTop: "20px",
                                marginBottom: "20px",
                                flexDirection: "column",
                              }}
                            >
                              {/* The div for campaign url  */}
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    marginTop: "20px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  <Input
                                    placeholder="Enter Filename"
                                    disableUnderline={true}
                                    value={value.download_url}
                                    // onChange={(e) => {
                                    //   setCampaignName(e.target.value);
                                    //   if (e.target.value == "") {
                                    //     setCampaignError("Field Is Empty");
                                    //   } else {
                                    //     setCampaignError("");
                                    //   }
                                    // }}
                                    endAdornment={
                                      <CopyToClipboard
                                        text={value.download_url}
                                      >
                                        <IconButton
                                          style={{ marginRight: "30px" }}
                                        >
                                          <ContentCopyIcon />
                                        </IconButton>
                                      </CopyToClipboard>
                                    }
                                    sx={{
                                      // boxShadow: "0px 2px 2px #0D0992",
                                      border: "1px solid grey",
                                      backgroundColor: "white",
                                      width: "800px",
                                      borderRadius: "10px",
                                      height: "40px",
                                      paddingTop: "6px",
                                      paddingLeft: "15px",
                                      paddingBottom: "5px",
                                      input: {
                                        color: "#646363",
                                        fontWeight: "400",
                                        "&::placeholder": {
                                          color: "#212121",
                                          opacity: 0.8,
                                        },
                                      },
                                    }}
                                  />
                                  {/* <FormHelperText sx={{ color: "red" }}>
                {campaignErrorState}
              </FormHelperText> */}
                                </div>
                              </div>
                              {/* The Main Table */}
                              <div>
                                <TableContainer component={Paper}>
                                  <Table
                                    sx={{ minWidth: 700 }}
                                    aria-label="spanning table"
                                  >
                                    <TableHead>
                                      <TableRow
                                        sx={{
                                          "& th": {
                                            color: "rgba(96, 96, 96)",
                                            
                                          },
                                          borderTop: "none",
                                        }}
                                      >
                                        <TableCell
                                          align="left"
                                          sx={{
                                            fontSize: "18px",
                                            paddingLeft: "30px",
                                          }}
                                        >
                                          Publisher
                                        </TableCell>
                                        <TableCell
                                          width="400px"
                                          align="center"
                                          sx={{ fontSize: "18px" }}
                                        >
                                          Feeds
                                        </TableCell>
                                        <TableCell
                                          align="right"
                                          width="400px"
                                          sx={{ fontSize: "18px" }}
                                        >
                                          Schedule
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell
                                          width="400px"
                                          sx={{
                                            paddingLeft: "30px",
                                            paddingTop: "15px",
                                          }}
                                        >
                                          Publisher Name
                                        </TableCell>
                                        <TableCell
                                          align="center"
                                          width="450px"
                                          sx={{
                                            paddingLeft: "40px",
                                           
                                          }}
                                        >
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <div>Feed Name</div>
                                            <div
                                              style={{ paddingRight: "160px" }}
                                            >
                                              Cpc
                                            </div>
                                          </div>
                                        </TableCell>
                                        {/* <TableCell align="center" width="250px">
                                          CPC
                                        </TableCell> */}
                                        <TableCell
                                          align="right"
                                          sx={{ paddingRight: "70px" }}
                                        >
                                          Time
                                        </TableCell>
                                      </TableRow>
                                    </TableHead>
                                    <TableBody>
                                      <TableCell
                                        sx={{
                                          paddingLeft: "30px",
                                          borderRight: "1px solid #C5C5C5",
                                          color: "#646363",
                                          paddingTop:"0px"
                                        }}
                                      >
                                        {value.publisher_name}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          color: "#646363",
                                          borderRight:"1px solid #C5C5C5"
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            height:"70px",
                                            overflowY:"auto"
                                          }}
                                        >
                                          {/* {value.Feed_Campaign.map(
                                            (data, index) => (
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                }}
                                              >
                                                <div
                                                  style={{ marginTop: "10px" }}
                                                >
                                                  {data.feed_name}
                                                </div>
                                                <div
                                                  style={{
                                                    marginTop: "10px",
                                                    paddingRight:"170px"
                                                  }}
                                                >
                                                  {data.filter_cpc}
                                                </div>
                                              </div>
                                            )
                                          )} */}
                                        </div>
                                      </TableCell>
                                      {/* <TableCell
                                        align="center"
                                        sx={{
                                          borderRight: "1px solid #C5C5C5",
                                          color: "#646363",
                                        }}
                                      >
                                        {value.global_cpc}
                                      </TableCell> */}
                                      <TableCell
                                        align="right"
                                        width="400px"
                                        sx={{
                                          color: "#646363",
                                        }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            minHeight: "70px",
                                            overflowY: "auto",
                                            height: "70px",
                                            paddingRight: "50px",
                                          }}
                                        >
                                          {/* {value.CampaignSchedule.map(
                                            (data, index) => (
                                              <div
                                                style={{ marginTop:"10px"}}
                                              >
                                                {data.schedule_time}
                                              </div>
                                            )
                                          )} */}
                                        </div>
                                      </TableCell>
                                    
                                     
                                    </TableBody>
                                  </Table>
                                </TableContainer>
                              </div>

                              {/* <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  border: "1px solid grey",
                                  width: "300px",
                                  height: "100px",
                                  borderRadius: "10px",
                                }}
                              >
                                <div>
                                  <strong>Campaign Download URL</strong>
                                </div>
                                <div>
                                  <TextField
                                    id="input-with-icon-textfield"
                                    label="URL"
                                    value={value.download_url}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <CopyToClipboard
                                            text={value.download_url}
                                          >
                                            <IconButton>
                                              <ContentCopyIcon />
                                            </IconButton>
                                          </CopyToClipboard>
                                          
                                        </InputAdornment>
                                      ),
                                    }}
                                    variant="outlined"
                                  />
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  border: "1px solid grey",
                                  width: "150px",
                                  height: "100px",
                                  borderRadius: "10px",
                                }}
                              >
                                <div>
                                  <strong>Publisher</strong>
                                </div>
                                <div>{value.publisher_name}</div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  border: "1px solid grey",
                                  borderRadius: "10px",
                                }}
                              >
                                <Typography
                                  variant="h7"
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Feeds
                                </Typography>
                                <Table size="medium">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>
                                        <strong>Name</strong>
                                      </TableCell>
                                      <TableCell align="right">
                                        <strong>CPC</strong>
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {value.Feed_Campaign.map((data, index) => (
                                      <React.Fragment key={index}>
                                        <TableRow>
                                          <TableCell
                                            align="left"
                                            sx={{ width: "10%" }}
                                          >
                                            {data.feed_name}
                                          </TableCell>
                                          <TableCell align="right">
                                            {data.filter_cpc}
                                          </TableCell>
                                        </TableRow>
                                      </React.Fragment>
                                    ))}
                                  </TableBody>
                                </Table>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  border: "1px solid grey",
                                  width: "150px",
                                  borderRadius: "10px",
                                }}
                              >
                                <Table size="medium">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>
                                        <strong>Schedule</strong>
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {value.CampaignSchedule.map(
                                      (data, index) => (
                                        <React.Fragment key={index}>
                                          <TableRow>
                                            <TableCell
                                              align="left"
                                              sx={{ width: "10%" }}
                                            >
                                              {data.schedule_time}
                                            </TableCell>
                                          </TableRow>
                                        </React.Fragment>
                                      )
                                    )}
                                  </TableBody>
                                </Table>
                              </div> */}
                            </div>
                          </div>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                </>
              ))}

          
            </TableBody>
          </Table>
        </TableContainer>
        </>
      )}
    </>
  );
}

export default TableBox;
