import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";

import { Link } from "react-router-dom";

import TextField from "@mui/material/TextField";

import Paper from "@mui/material/Paper";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";

import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import GenericTableBox from "../GenericTableBox";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box } from "@mui/system";
import DeleteIcon from "@mui/icons-material/Delete";
// import {  deleteData } from "../helpers/helpers";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import CustomTextField from "../CustomTextField";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import {
  feedDeleteThunk,
  feedEditThunk,
  feedSearchUpdate,
  feedsThunk,
} from "../../reducers/request_api";

import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

function TableBox({}) {
  const feedsData = useSelector((state) => state.clickrequests.feeds);
  const feedSearchValue = useSelector(
    (state) => state.clickrequests.feedSearch
  );
  const user = useSelector((state) => state.clickmatrix.user);

  // const [loader, setLoader] = useState(true);
  const [container, setContainer] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [country, setCountry] = useState("");
  const [partner, setPartnerName] = useState("");
  const [company, setCompanyName] = useState("");
  const [partnerId, setPartnerId] = useState("");
  const [feedModal, setFeedModal] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("company_name");

  const [reduxloaded, setReduxloaded] = useState(false);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [feedBackupContainer, setFeedBackupContainer] = useState([]);
  const [searched, setSearched] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  var loader =  useSelector((state) => state.clickrequests.isLoading)

  useEffect(() => {
    if (reduxloaded) {
      requestSearch(feedSearchValue);
      setSearched(feedSearchValue);
    } else {
      const displayLoader = async () => {
        // await getDataTwo(setContainer, setFeedBackupContainer,"feeds");
        if (feedsData.length == 0) {
          dispatch(feedsThunk()).then((response) => {
            setContainer((arr) => (arr = [...response.payload]));
            setFeedBackupContainer((arr) => (arr = [...response.payload]));
            
            // setLoader(false);
          });
        } else {
          setContainer((arr) => (arr = [...feedsData]));
          setFeedBackupContainer((arr) => (arr = [...feedsData]));
          // setLoader(false);
          setReduxloaded(true);
        }
      };
      displayLoader();
    }

    requestSearch(feedSearchValue);
    setSearched(feedSearchValue);

    // setContainer((arr) => arr = [...abu]);
  }, [refresh, reduxloaded]);

  const requestSearch = (searchedVal) => {
    if (searchedVal == "") {
      setContainer(feedBackupContainer);
      dispatch(feedSearchUpdate(""));
    } else {
      const filteredRows = feedBackupContainer.filter((row) => {
        return row.partner_name
          .toLowerCase()
          .includes(searchedVal.toLowerCase());
      });
      setContainer(filteredRows);
      setPage(0);
      dispatch(feedSearchUpdate(searchedVal));
    }
  };

  const cancelSearch = () => {
    setContainer(feedBackupContainer);
    setSearched("");
    dispatch(feedSearchUpdate(""));
    // requestSearch(searched);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");

    setOrderBy(property);
  };

  function descendingComparator(obj1, obj2, orderBy) {
    if (orderBy == "id") {
      if (parseInt(obj2[orderBy]) < parseInt(obj1[orderBy])) {
        return -1;
      }
      if (parseInt(obj2[orderBy]) > parseInt(obj1[orderBy])) {
        return 1;
      }
      return 0;
    }

    return obj1[orderBy].localeCompare(obj2[orderBy]);
  }

  function getComparator(order, orderBy, obj1, obj2) {
    return order === "desc"
      ? descendingComparator(obj1, obj2, orderBy)
      : -descendingComparator(obj1, obj2, orderBy);
  }

  function stableSort(array, orderIs, orderBy) {
    const stabilizedThis = array.map((el, index) => {
      return [el, index];
    });
    stabilizedThis.sort((a, b) => {
      const order = getComparator(orderIs, orderBy, a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  return (
    <>
      {loader && (
        <Stack spacing={2} style={{ marginTop: "70px", marginLeft: "20px" }}>
          {/* For variant="text", adjust the height via font-size */}
          <div
            style={{
              display: "flex",
              width: "95%",
              justifyContent: "space-between",
            }}
          >
            <Skeleton variant="rounded" width={130} height={40} />
            <Skeleton variant="rounded" width={200} height={40} />
          </div>
          <Skeleton variant="rounded" width={1500} height={700} />
        </Stack>
      )}
      {!loader && (
        <>
          <Dialog
            open={deleteDialog}
            onClose={() => {
              setDeleteDialog(false);
              setDeleteItem({});
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Confirm deletion"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure to delete <strong>{deleteItem.filename}</strong>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                variant="outlined"
                sx={{
                  border: "1px solid #0D0992",
                  color: "#0D0992",
                  borderRadius: "7px",
                  fontSize: "13px",
                }}
                onClick={() => setDeleteDialog(false)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#0D0992",
                  borderRadius: "7px",
                  fontSize: "13px",
                }}
                onClick={() => {
                  // setDeleteDialog(false);
                  // deleteData("feeds", deleteItem.id).then(() => {
                  //   setRefresh(refresh + 1);
                  // });
                  dispatch(feedDeleteThunk(deleteItem.id)).then(() => {
                    

                    setDeleteDialog(false);
                      setRefresh(refresh + 1);
                   
                  })
                }}
                autoFocus
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>

          <Modal
            open={feedModal}
            onClose={() => setFeedModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 450,
                height: 350,
                bgcolor: "white",
                border: "2px solid white",
                boxShadow: 24,
                p: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "10px",
              }}
            >
              <div
                style={{
                  fontWeight: 600,
                  fontSize: "20px",
                }}
              >
                Enter Credentials
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "25%",
                  height: "200px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", marginTop: "20px" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ marginTop: "10px" }}>
                      <CustomTextField
                        setFunction={setCompanyName}
                        labelName={"Company Name"}
                        value={company}
                      />
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      <CustomTextField
                        setFunction={setPartnerName}
                        labelName={"Partner Name"}
                        value={partner}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div style={{ marginTop: "10px" }}>
                    <CustomTextField
                      setFunction={setCountry}
                      labelName={"Country Name"}
                      value={country}
                    />
                  </div>
                </div>
              </div>
              <Button
                variant="contained"
                onClick={() => {
                  const data = {
                    company_name: company,
                    partner_name: partner,
                    country: country,
                    partner: partnerId,
                  };

                  // editData(data, "feeds",editId);
                  // setRefresh(refresh+1)

                  // setFeedModal(false);
                  dispatch(feedEditThunk([data, editId])).then(() => {
                    setRefresh(refresh + 1);
                    setFeedModal(false);
                  });
                }}
                sx={{
                  backgroundColor: "#0D0992",
                  marginTop: "30px",
                  width: "100px",
                  borderRadius: "7px",
                }}
              >
                Save
              </Button>
            </Box>
          </Modal>

          <div
            style={{
              display: "flex",
              marginTop: "4%",
              justifyContent: "space-between",
            }}
          >
            <div style={{ marginLeft: "10px" }}>
              {user[0].status != "admin" ? (
                <></>
              ) : (
                <Link to="/feeds/new">
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "#0D0992",
                      fontWeight: 600,
                      paddingBottom: "10px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      borderRadius: "8px",
                    }}
                  >
                    Add Feed
                  </Button>
                </Link>
              )}
              {/* <Link to="/feeds/reports"><Button variant="contained" sx={{marginLeft:"10px",backgroundColor:"#0D0992",fontWeight:600,paddingBottom:"10px",paddingLeft:"20px",paddingRight:"20px",borderRadius:"8px",marginRight:"20px"}}>Reports</Button></Link>   */}
            </div>
            <div style={{ paddingRight: "40px" }}>
              <TextField
                id="outlined-basic"
                size="small"
                value={searched}
                inputProps={{
                  color: "rgba(0, 0, 0, 0.55)",
                }}
                label="Search"
                sx={{
                  width: "230px",
                  ".MuiOutlinedInput-root": {
                    borderRadius: "8px",
                    "&.Mui-focused fieldset": {
                      borderColor: "#C5C5C5",
                      borderWidth: "0.5px",
                      color: "rgba(0, 0, 0, 0.55)",
                    },
                  },
                  "& label.Mui-focused": {
                    color: "rgba(0, 0, 0, 0.55)",
                  },
                  backgroundColor: "white",
                  color: "rgba(0, 0, 0, 0.55)",
                }}
                variant="outlined"
                onChange={(e) => {
                  requestSearch(e.target.value);
                  setSearched(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {searched != "" && (
                        <IconButton onClick={() => cancelSearch()}>
                          <CloseIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>

          {/* <TableContainer
            component={Paper}
            sx={{
              width: "97%",
              marginLeft: "10px",
              marginTop: "10px",
              borderRadius: "10px",
              boxShadow: 2,
            }}
          >
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "rgba(0, 0, 0, 0.7)",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      borderBottom: "1px solid #E0E0E0",
                      fontSize: "16px",
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === "id"}
                      direction={orderBy === "id" ? order : "asc"}
                      onClick={() => handleRequestSort("id")}
                    >
                      {" "}
                      <strong>Id</strong>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "rgba(0, 0, 0, 0.7)",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                      borderBottom: "1px solid #E0E0E0",
                      fontSize: "16px",
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === "company_name"}
                      direction={orderBy === "company_name" ? order : "asc"}
                      onClick={() => handleRequestSort("company_name")}
                    >
                      {" "}
                      <strong>Company</strong>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontSize: "16px",
                      color: "rgba(0, 0, 0, 0.7)",
                      borderBottom: "1px solid #E0E0E0",
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === "partner_name"}
                      direction={orderBy === "partner_name" ? order : "asc"}
                      onClick={() => handleRequestSort("partner_name")}
                    >
                      {" "}
                      <strong>Partner</strong>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      fontSize: "16px",
                      color: "rgba(0, 0, 0, 0.7)",
                      borderBottom: "1px solid #E0E0E0",
                    }}
                  >
                    <TableSortLabel
                      active={orderBy === "country"}
                      direction={orderBy === "country" ? order : "asc"}
                      onClick={() => handleRequestSort("country")}
                    >
                      {" "}
                      <strong>Country</strong>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "rgba(0, 0, 0, 0.7)",
                      borderBottom: "1px solid #E0E0E0",
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {stableSort(container, order, orderBy)
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((value, index) => (
                    <React.Fragment key={index}>
                      <TableRow
                        key="1"
                        // sx={{ "&:last-child td, &:last-child th": { border: "1px solid grey" } }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          sx={{
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            color: "rgba(0, 0, 0, 0.55)",
                            fontSize: "16px",
                            borderBottom: "1px solid #E0E0E0",
                          }}
                        >
                          F-{value.id}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          sx={{
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            color: "rgba(0, 0, 0, 0.55)",
                            fontSize: "16px",
                            borderBottom: "1px solid #E0E0E0",
                          }}
                        >
                          {value.company_name}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            color: "rgba(0, 0, 0, 0.55)",
                            fontSize: "16px",
                            borderBottom: "1px solid #E0E0E0",
                          }}
                        >
                          {value.partner_name}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            paddingTop: "20px",
                            paddingBottom: "20px",
                            color: "rgba(0, 0, 0, 0.55)",
                            fontSize: "16px",
                            borderBottom: "1px solid #E0E0E0",
                          }}
                        >
                          {value.country}
                        </TableCell>

                        <TableCell
                          align="center"
                          sx={{ borderBottom: "1px solid #E0E0E0" }}
                        >
                         
                          {user[0].status != "admin" ? (
                            <></>
                          ) : (
                            <IconButton
                              aria-label="expand-row"
                              size="small"
                              onClick={() => {
                                navigate(`/feed/edit/${value.id}`);
                              }}
                            >
                              <BorderColorIcon size="small" />
                            </IconButton>
                          )}
                          {user[0].status != "admin" ? (
                            <></>
                          ) : (
                            <IconButton
                              aria-label="expand-row"
                              size="small"
                              sx={{ marginLeft: "5%" }}
                              onClick={() => {
                                setDeleteDialog(true);
                                setDeleteItem({
                                  id: value.id,
                                  filename: value.company_name,
                                });
                              }}
                            >
                              <DeleteIcon size="small" />
                            </IconButton>
                          )}
                        
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  {feedBackupContainer.length > 10 && (
                    <TablePagination
                      count={container.length}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      rowsPerPageOptions={[4, 10, 20, 30]}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  )}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer> */}
          <GenericTableBox
          tableHeaders={["id", "company_name",'partner_name','country']}
          editLink={"/feed/edit"}
          editStyle={"link"}
          deleteThunk={feedDeleteThunk}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          Container={container}
          SearchContainer={feedBackupContainer}
          />
        </>
      )}
    </>
  );
}

export default TableBox;
