import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import axios from "axios";



function QueryBoxes() {
  const [dataInCSV, setDataInCSV] = useState("");

  

  return (
    <>
      <Box
        sx={{
          display: "flex",
          marginLeft: "10px",
          borderRadius: "30px",
          height: "5%",
          width: "97%",
          marginBottom: 5,
          // border:"3px solid black"
        }}
      >
        <Link to="/campaign/new">
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#402DCC",
              borderRadius: "8px",
              width: "149px",
              height: "33px",
            }}
          >
            <strong>New Campaign</strong>
          </Button>
        </Link>

        {/* <Button
          variant="contained"
          sx={{
            backgroundColor: "#402DCC",
            borderRadius: "8px",
            width: "100px",
            height: "33px",

            // marginLeft:"70%",
            marginLeft: "auto",
            minWidth: "100px",
          }}
        >
          <strong>Filters</strong>
        </Button>

        <Button
          variant="contained"
          onClick={() => handleClick()}
          sx={{
            backgroundColor: "#402DCC",
            borderRadius: "8px",
            width: "130px",
            height: "33px",
            marginLeft: "1%",
            minWidth: "130px",
          }}
        >
          <strong>Export CSV</strong>
        </Button> */}
      </Box>
    </>
  );
}

export default QueryBoxes;
