import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
// import {getPublisher} from "../helpers/helpers";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Link } from "react-router-dom";
import { Input } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
// import { deleteData } from "../helpers/helpers";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TextField from "@mui/material/TextField";
import { useDispatch ,useSelector} from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { campaignDeleteThunk, campaignThunk, publisherFeedsThunk, publisherMasterFeedsThunk } from "../../reducers/request_api";
import Divider from '@mui/material/Divider';
import GenericTableBox from "../GenericTableBox";

function TableBox({setTotalBudget,setTotalRevenue,setTotalClicks,setTotalValid,setTotalInvalid}) {
  const navigate = useNavigate();
  // const [loader, setLoader] = useState(true);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("filename");
  const [open, setOpen] = useState(true);
  const [publisherContainer, setPublisherContainer] = useState([]);
  const [masterpublisherContainer,setMasterPublisherContainer] = useState([]);
  const [masterpublishersearchContainer,setMasterPublisherSearchContainer] = useState([]);
  const [refresh, setRefresh] = useState(false);
  
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});

  const [campaignUrl, setCampaignUrl] = useState("Campaign.com");

  const dispatch = useDispatch()
  var loader =  useSelector((state) => state.clickrequests.isLoading)

  useEffect(() => {
    // const displayLoader = async () => {
    //   await getPublisher(setPublisherContainer,setTotalBudget,setTotalRevenue,setTotalClicks,setTotalValid,setTotalInvalid, "publisher_feeds");
    // };
    // const check = async () => {
    //   await displayLoader();
    //   setLoader(false);
    // };
    // check();
    dispatch(publisherMasterFeedsThunk()).then((response) => {
        console.log(response.payload)
      setMasterPublisherContainer(response.payload)
    })
   
    
   
  }, [refresh]);


  return (
    <>
      {loader && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="wrapper">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
            <div className="shadow"></div>
          </div>
        </div>
      )}
      {!loader && (
        <>
       
       <div style={{marginTop:"100px"}}>

        <GenericTableBox
            tableHeaders={["filename","campaign_names","total_clicks","foreign_clicks","duplicate_clicks","bot_clicks","revenue"]}
            // editLink={"/user/edit"}
            // editLink={handleModal}
            // deleteThunk={campaignDeleteThunk}
            order={order}
            orderBy={orderBy}
            setOrder={setOrder}
            setOrderBy={setOrderBy}
            Container={masterpublisherContainer}
            SearchContainer={masterpublishersearchContainer}
          />
          </div>
        </>
      )}
    </>
  );
}

export default TableBox;
