import React,{useState,useEffect,useRef} from "react";
import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";

import VerticalNav from "../../Navbar/VerticalNav";
import FeedReportIndex from "../../Feeds/FeedReportIndex";


import ProfileMenu from "../../Profile/ProfileMenu";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import LoadingBar from 'react-top-loading-bar';

function Main({isAuthenticated}) {
  const ref = useRef(null);
  
  useEffect(() => {
    ref.current.continuousStart()
    ref.current.complete()
  })

  if(!isAuthenticated){
    
    return <Navigate to="/login" />;
  }

  
   
  return (
    
    <>
      
        
        <Grid
          container
         
          sx={{  height: "100vh",backgroundColor:"#F7F8FA" }}

        >
          <LoadingBar color="#0D0992" ref={ref} />
          <Grid item md={2} lg={2}  sx={{height:"100%"}}>
            <VerticalNav lineState={3} />
          </Grid>
          <Grid item md={10} lg={10}   sx={{ paddingLeft: 2, overflowY: "auto", height: "100%" }}>
            <ProfileMenu />
           
          <FeedReportIndex />
           
          </Grid>
        </Grid>
      
      
      
      
      
      
    </>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.clickmatrix.isAuthenticated,
});

export default connect(mapStateToProps)(Main);
