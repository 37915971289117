import React from "react";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "& label": {
      width: "100%",
      // paddingTop:"-50px",

      "&.Mui-focused": {
        transform: "translate(-6px, -2px) scale(0.8)",
        transformOrigin: "center",
        fontSize: "15px !important",
        color: "#0D0992",
        marginTop: "-10px",
        "&.MuiFormLabel-filled": {
          marginTop: "-10px",
        },
      },
      "&.MuiFormLabel-filled": {
        marginTop: "0px",
      },
      marginTop: "-10px",
    },
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        borderColor: "none",
        borderRadius: "10px",
        boxShadow: "rgba(0, 0, 0, 0.06) 0px 5px 4px 0px",
        height: "40px",
      },
    },
    // "& .MuiInputLabel-root": {

    //     marginTop:"-10px"

    // },
  },
  shrinked: {
    "& label": {
      width: "100%",
      // paddingTop:"-50px",

      "&.Mui-focused": {
        transform: "translate(-6px, -2px) scale(0.8)",
        transformOrigin: "center",
        fontSize: "15px !important",
        color: "#0D0992",
        marginTop: "-10px",
      },
    },
    "& .MuiOutlinedInput-root": {
      "& > fieldset": {
        borderColor: "none",
        borderRadius: "10px",
        boxShadow: "rgba(0, 0, 0, 0.06) 0px 5px 4px 0px",
        height: "40px",
      },
    },
    // "& .MuiInputLabel-root": {

    //     marginTop:"-10px"

    // },
  },
});

function CustomTextField({
  setFunction,
  setErrorFunction = null,
  labelName,
  value,
  refs,
  shrink = false,
  width,
  onChange = false,
}) {
  const classes = useStyles();
  if (onChange === true) {
    return (
      <>
        <TextField
          onChange={(e) => {
            setFunction(e.target.value);
            if (e.target.value == "") {
              setErrorFunction("Field is empty");
            } else {
              setErrorFunction("");
            }
          }}
          id="outlined-basic"
          className={classes.root}
          inputRef={refs}
          InputLabelProps={{
            // shrink: "true",
            style: {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              width: "100%",
            },
          }}
          value={value}
          label={labelName}
          variant="outlined"
          sx={{
            "& .MuiInputLabel-root": { color: "rgba(0, 0, 0, 0.5);" }, //styles the label

            "& .MuiOutlinedInput-root.Mui-focused": {
              "& > fieldset": {
                borderColor: "#0D0992",
              },
            },
            "& input": {
              paddingLeft: "10px",
              paddingTop: "10px",
            },
            width: width,
          }}
        />
      </>
    );
  }
  if (shrink === true) {
    return (
      <>
        <TextField
          onChange={(e) => setFunction(e.target.value)}
          id="outlined-basic"
          className={classes.shrinked}
          // inputRef={ref}
          InputLabelProps={{
            shrink: true,
            style: {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              width: "100%",
            },
          }}
          value={value}
          label={labelName}
          variant="outlined"
          sx={{
            "& .MuiInputLabel-root": { color: "#0D0992",fontSize:"17px" }, //styles the label

            "& .MuiOutlinedInput-root.Mui-focused": {
              "& > fieldset": {
                borderColor: "#0D0992",
              },
            },
            "& input": {
              paddingLeft: "10px",
              paddingTop: "10px",
            },
            width: width,
          }}
        />
      </>
    );
  } else {
    return (
      <>
        <TextField
          onChange={(e) => setFunction(e.target.value)}
          id="outlined-basic"
          className={classes.root}
          value={value}
          InputLabelProps={{
            style: {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              width: "100%",
              fontSize: "13px",
              top: "-4px",
            },
          }}
          label={labelName}
          variant="outlined"
          sx={{
            "& .MuiInputLabel-root": { color: "rgba(0, 0, 0, 0.5);" }, //styles the label

            "& .MuiOutlinedInput-root.Mui-focused": {
              "& > fieldset": {
                borderColor: "#0D0992",
              },
            },
            "& input": {
              paddingLeft: "10px",
            },
            width: "250px",
          }}
        />
      </>
    );
  }
}

export default CustomTextField;
