import React, { useEffect, useState,useRef } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import VerticalNav from "../../Navbar/VerticalNav";
import CampaignAnalyticsBox from "../../Campaigns/CampaignAnalyticsBox";
import CampaignAnalyticsProgressBox from "../../Campaigns/CampaignAnalyticsProgressBox";
import DoughnutHalf from "../../Charts/DoughnutHalf";
import Chart from "../../Charts/Chart";
import ProfileMenu from "../../Profile/ProfileMenu";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getDataId } from "../../helpers/helpers";
import Button from "@mui/material/Button";
import axios from "axios";
import BarChart from "../../Charts/Barchart";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { addDays, startOfDay } from "date-fns";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";
import { Collapse } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PublisherVerticalNav from "../../Navbar/PublisherVerticalNav";
import PublisherProfile from "../../Publisher App/PublisherProfile";
import LoadingBar from 'react-top-loading-bar';
const authToken = `JWT ${localStorage.getItem("access")}`;

const headers = {
  "content-type": "application/json",
  AUTHORIZATION: authToken,
};
const apiLink = process.env.REACT_APP_API_URL_DATA;

function CampaignAnalytics({ isAuthenticated }) {
  // const params = useParams();
  // const [publisher_stats,setPublisherStats] = useState([]);

  // useEffect( () => {
  //   getDataId(setPublisherStats,"campaign_stats",params.id)
  // },[])
  const params = useParams();
  const [open, setOpen] = useState(false);
  const [campaign_stats, setCampaignStats] = useState([]);
  const [redraw,setRedraw] = useState(false);

  const [labelContainer, setLabelContainer] = useState([]);
  const [foreignContainer, setForeignContainer] = useState([]);
  const [validContainer, setValidContainer] = useState([]);
  const [duplicateContainer, setDuplicateContainer] = useState([]);
  const [expiredContainer, setExpiredContainer] = useState([]);
  const [botContainer, setBotContainer] = useState([]);
  const [totalContainer, setTotalContainer] = useState([]);
  const [partnerContainer, setPartnerContainer] = useState([]);

 
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    },
  ]);
  const bar = useRef();
  const ref = useRef(null);


  useEffect(() => {
    // getDataId(setCampaignStats, "campaign_stats", params.id);
    ref.current.continuousStart()

    function getPreviousDay(date = new Date()) {
      const previous = new Date(date.getTime());
      previous.setDate(date.getDate() - 7);

      return previous;
    }
    const doit = async () => {
    // let past7 = getPreviousDay(new Date());
    let date = new Date()
    let past7 = new Date(date.getFullYear(),date.getMonth(),1);
    let current = new Date();

    setState([
      {
        startDate: past7,
        endDate: current,
        key: "selection",
      },
    ]);

    let ddata = {
      startDate: past7.getFullYear() + "-" + ("0"+(past7.getMonth()+1)).slice(-2) + "-" + ("0" + past7.getDate()).slice(-2),
      endDate: current.getFullYear() + "-" + ("0"+(current.getMonth()+1)).slice(-2) + "-" + ("0" + current.getDate()).slice(-2),
    };
    
      // console.log("The value of data is :",data)
      await axios({
        method: "post",
        url: `${apiLink}analytics/${params.id}/`,
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
        data: ddata,
      })
        .then((response) => {
          
          setCampaignStats(response.data);
        })
    

    };

    doit();
    ref.current.complete()
  }, []);

  const handleClick = async () => {
    await axios
      .get(`${apiLink}stats_csv/${params.id}`, {
        // .get("http://localhost:8000/api/v1/partners", {
        headers: {
          "content-type": "application/json",
          AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
        },
      })
      .then((response) => {
        const link = document.createElement("a");
        link.href = `data:text/csv;charset=utf-8,${escape(response.data)}`;
        link.download = `${campaign_stats.filename}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })

  
}

 

  const handleFilter = async (a) => {
    
    let startDate = a[0].startDate;
    let endDate = a[0].endDate;

    await axios({
      method: "post",
      url: `${apiLink}analytics/${params.id}/`,
      headers: {
        "content-type": "application/json",
        AUTHORIZATION: `JWT ${localStorage.getItem("access")}`,
      },
      data: {
        startDate: startDate.getFullYear() + "-" + ("0"+(startDate.getMonth()+1)).slice(-2) + "-" + ("0" + startDate.getDate()).slice(-2),
        endDate: endDate.getFullYear() + "-" + ("0"+(endDate.getMonth()+1)).slice(-2) + "-" + ("0" + endDate.getDate()).slice(-2),
      },
    })
      .then((response) => {
        
        
        setCampaignStats(response.data);
        setState([
          {
            startDate: startDate,
            endDate: endDate,
            key: "selection",
          },
        ]);
       
          
            setLabelContainer( []);
         
         
            setForeignContainer([]);
         
       
            setExpiredContainer([]);
         
         
            setDuplicateContainer([]);
      
          
            setBotContainer([]);
        
        
            setTotalContainer([]);
     
         
            setPartnerContainer([]);
        
         
            setValidContainer([]);
        
      
        
     
      })


  };

  const navigate = useNavigate();

  // if (!isAuthenticated) {
  //   return <Navigate to="/login" />;
  // }
  
  
  return (
    <>
      <Box sx={{ position: "absolute", width: "100%" }}>
        <Grid container sx={{ height: "100vh" }}>
        <LoadingBar color="#0D0992" ref={ref} />
          <Grid item md={2} lg={2} sx={{ marginTop: "30px" }}>
            <PublisherVerticalNav />
          </Grid>
          <Grid item md={10} lg={10} sx={{ paddingLeft: 2 }}>
          <PublisherProfile />
           
          <div style={{ marginLeft: "20px" }}>
            <div>
              <IconButton
                sx={{
                  backgroundColor: "white",
                  border: "1px solid #C5C5C5",
                  borderRadius: "8px",
                }}
                onClick={() => navigate("/")}
              >
                <ArrowBackIcon />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "92%",
            }}
          >
            <div>
              
            </div>
            {/* <div>
              <Button
                variant="contained"
                onClick={() => setOpen(!open)}
                sx={{
                  backgroundColor: "#402DCC",
                  borderRadius: "8px",
                  width: "170px",
                  height: "33px",
                  marginLeft: "1%",
                  minWidth: "130px",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <strong>Range Picker</strong>
              </Button>
            </div> */}
          </div>
          <div
            style={{
              marginLeft: "30px",
              fontWeight: 600,
              marginBottom: "10px",
              marginTop:"10px"
            }}
          >
            {campaign_stats.filename}
          </div>

          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "58%",
                marginLeft: "1%",
                border: "0.5px solid #C5C5C5 ",
                borderRadius: "13px",
                backgroundColor: "white",
              }}
            >
              <div style={{ paddingTop: "50px" }}>
                {(campaign_stats != null) && <DoughnutHalf
                  primaryColor={"#0878FF"}
                  secondaryColor={"#696969"}
                  title={"Budget Spent"}
                  datavalue={[
                    campaign_stats.budget_spent,
                    campaign_stats.total_budget - campaign_stats.budget_spent,
                  ]}
                  clicks={
                    campaign_stats.budget_spent
                  }
                  innerClick={campaign_stats.total_budget}
                  innerTitle={"Total Budget"}
                  labelvalue={["Budget Spent", "Remaining Budget"]}
                  type="budget"
                />
              }
              </div>
              <div style={{ paddingTop: "50px" }}>
              {(campaign_stats != null) &&<DoughnutHalf
                  primaryColor={"#EE2B1F"}
                  secondaryColor={"#888888"}
                  title={"Valid Clicks"}
                  datavalue={[
                    campaign_stats.valid_clicks,
                    campaign_stats.total_clicks - campaign_stats.valid_clicks,
                  ]}
                  clicks={campaign_stats.valid_clicks}
                  innerClick={campaign_stats.total_clicks}
                  labelvalue={["Valid Clicks", "Invalid Clicks"]}
                  innerTitle={"Total Clicks"}
                />
}
              </div>
            </div>
            <div
              style={{
                marginLeft: "1%",
                paddingLeft: "10px",
                paddingBottom: "10px",
                backgroundColor: "white",
                borderRadius: "10px",
                border: "0.5px solid #C5C5C5 ",
              }}
            >
              {/* <div
                style={{
                  marginLeft: "1%",
                  position: "relative",
                  top: "90%",
                  left: "10%",
                  width: "110px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{ width: "100px", height: "30px" }}
                  // onClick={() => handleFilter()}
                >
                  Filter
                </Button>
              </div> */}
              <DateRangePicker
                onChange={(item) => {
                  setState([item.selection]);
                  handleFilter([item.selection]);
                  
                  
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={1}
                ranges={state}
                direction="horizontal"
                customCloseIcon={<CloseIcon />}
              />
            </div>
          </div>
          <div style={{ display: "flex", marginTop: "1%" }}>
            <div
              style={{
                width: "55.5%",
                border: "0.5px solid #C5C5C5 ",
                borderRadius: "13px",
                backgroundColor: "white",
                height: "330px",
                paddingLeft: "40px",
                marginLeft: "1%",
                paddingTop: "50px",
              }}
            >
              {/* <Chart campaignId={params.id}/> */}
              {/* {console.log("The campaign stats . click chart is :",campaign_stats.click_chart)} */}
              {(campaign_stats.click_chart != null) ? (
                <BarChart  clickstats={campaign_stats.click_chart} globalCpc={campaign_stats.global_cpc} labelContainer={labelContainer} expiredContainer={expiredContainer} foreignContainer={foreignContainer} totalContainer={totalContainer} botContainer={botContainer} duplicateContainer={duplicateContainer} partnerContainer={partnerContainer} validContainer={validContainer} setlabelContainer={setLabelContainer} setexpiredContainer={setExpiredContainer} setforeignContainer={setForeignContainer} settotalContainer={setTotalContainer} setbotContainer={setBotContainer} setduplicateContainer={setDuplicateContainer} setpartnerContainer={setPartnerContainer} setvalidContainer={setValidContainer} />
              ) : (
                <div></div>
              )}
            </div>
            <div
              style={{
                border: "0.5px solid #C5C5C5 ",
                borderRadius: "13px",
                backgroundColor: "white",
                paddingLeft: "15px",
                marginLeft: "1%",
                width: "36%",
              }}
            >
              {Object.keys(campaign_stats).length != 0 && 
              <CampaignAnalyticsBox
                foreign={campaign_stats.foreign_clicks}
                duplicate={campaign_stats.duplicate_clicks}
                expire={campaign_stats.expired_clicks}
                botClicks={campaign_stats.bot_clicks}
                foreign_percent={campaign_stats.foreign_clicks_percent}
                duplicate_percent={campaign_stats.duplicate_clicks_percent}
                expire_percent={campaign_stats.expired_clicks_percent}
                botClicks_percent={campaign_stats.bot_clicks_percent}
              />
            }
              {/* <CampaignAnalyticsProgressBox totalclicks={campaign_stats.total_clicks} android={campaign_stats.android_clicks} linux={campaign_stats.linux_clicks} windows={campaign_stats.windows_clicks} mac={campaign_stats.mac_clicks} ios={campaign_stats.ios_clicks}/> */}
            </div>
            {/* <div
              style={{
                boxShadow: "rgba(0, 0, 0, 0.06) 0px 5px 4px 0px",
                paddingLeft: "15px",
              }}
            >
              <CampaignAnalyticsBox foreign={campaign_stats.foreign_clicks} duplicate={campaign_stats.duplicate_clicks} expire={campaign_stats.expired_clicks} botClicks={campaign_stats.bot_clicks} />
            </div> */}
          </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
          }

const mapStateToProps = (state) => ({
  isAuthenticated: state.clickmatrix.isAuthenticated,
});

export default connect(mapStateToProps)(CampaignAnalytics);
