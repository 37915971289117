import React from "react";
import report from "../../assets/report.png";
import optimize from "../../assets/optimize.png";
import personchart from "../../assets/person-chart.png";
import integrate from "../../assets/integrate.png";
import detect from "../../assets/detect.png";
import cmatrix2 from "../../assets/home2-cmatrix.png";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";

function Home2() {
  return (
    <>
      <div style={{ height: "100vh",width:"100%",overflow:"hidden"}}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
           
            
          }}
        >
          <div>
            <img src={cmatrix2} alt="we" />
          </div>
          <div style={{ marginRight: "30px" }}>
            <Link to="/login">
            <Button
              variant="outlined"
              sx={{
                border: "2px solid #0D0992",
                color: "#0D0992",
                borderRadius: "7px",
                width: "100px",
                fontWeight: 600,
              }}
            >
              Log In
            </Button>
            </Link>
          </div>
        </div>

        <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",height:"100%"}}>
            <div style={{fontSize:"30px"}}><span style={{color:"#0D0992"}}>Our</span>&nbsp;Matchless&nbsp;<span style={{color:"#0D0992"}}>services</span></div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
           
          }}
        >
           
          <div
            style={{
            
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height:"300px"
            }}
          >
            <div style={{ display: "flex"}}>
              <div>
                <img src={report} style={{ width: "50px" }} alt="reporting" />
              </div>
              <div style={{width:"100px",marginLeft:"10px",color:"#0D0992"}}>Explicit<br/>Reporting</div>
            </div>
            <div style={{ display: "flex",marginBottom:"50px" }}>
              <div>
                <img
                  src={optimize}
                  style={{ width: "50px" }}
                  alt="optimization"
                />
              </div>
              <div style={{width:"100px",marginLeft:"10px",color:"#0D0992"}}>Campaign<br/>Optimization</div>
            </div>
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <img src={personchart} alt="person-middle" />
          </div>
          <div style={{ display: "flex", flexDirection: "column" ,height:"300px",justifyContent:"space-between"}}>
            <div style={{ display: "flex" }}>
              <div>
                <img
                  src={integrate}
                  style={{ width: "50px" }}
                  alt="reporting"
                />
              </div>
              <div style={{width:"100px",marginLeft:"10px",color:"#0D0992"}}>Efforless<br/>Integration</div>
            </div>
            <div style={{ display: "flex",marginBottom:"50px" }}>
              <div>
                <img
                  src={detect}
                  style={{ width: "50px" }}
                  alt="optimization"
                />
              </div>
              <div style={{width:"100px",marginLeft:"10px",color:"#0D0992"}}>Bot<br/>Detection</div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
}

export default Home2;
